import React, { JSXElementConstructor, ReactElement } from "react";
import ReactDOM from "react-dom";

type Child = ReactElement<any, string | JSXElementConstructor<any>>;

/**
 * Portal class for rendering a component inside <body>
 */
class Portal extends React.Component<{ children: Child | Child[] }> {
    portalElement: Element | null = null;
    portalId = "portal-" + Math.floor(Math.random() * 1000);

    componentDidMount() {
        let p = this.portalId && document.getElementById(this.portalId);

        if (!p) {
            p = document.createElement("div");
            p.id = this.portalId;
            document.body.appendChild(p);
        }

        this.portalElement = p;
        this.componentDidUpdate();
    }

    componentWillUnmount() {
        if (this.portalElement) {
            document.body.removeChild(this.portalElement);
        }
    }

    componentDidUpdate() {
        if (this.portalElement && this.props.children) {
            const children = !Array.isArray(this.props.children) ? [this.props.children] : this.props.children as Child[];
            ReactDOM.render(children, this.portalElement);
        }
    }

    render() {
        return null;
    }
}

export default Portal;
