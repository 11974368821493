import { AnyAction }     from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchCompany }  from "@store/company/company-actions";
import apiRequest        from "@utils/cinio-api";
import ACTIONS           from "./user-action-types";
import { User }          from "@definitions/user";

/**
 * Fetch current user from Cinio api
 */
export const fetchUser = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: ACTIONS.LOG_IN_INIT });

        return apiRequest.get("/users/me")
            .then((response: any) => {
                dispatch({ type: ACTIONS.LOG_IN_SUCCESS, payload: response.data });
            })
            .catch((e) => {
                dispatch({ type: ACTIONS.LOG_IN_ERROR });
                throw new Error(e.message);
            });
    };
};

/**
 * Update user data
 * @param user - user data
 */
export const updateUser = (user: User) => ({
    type: ACTIONS.UPDATE_DATA,
    payload: user
});

/**
 * Log in a user
 * @param username
 * @param password
 */
export const logIn = (username: string, password: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: ACTIONS.LOG_IN_INIT });

        return apiRequest.post("/log-in", { username, password })
            .then((response) => {
                dispatch({ type: ACTIONS.LOG_IN_SUCCESS, payload: response.data });
                dispatch(fetchCompany());
            })
            .catch((error) => {
                dispatch({ type: ACTIONS.LOG_IN_ERROR, payload: error.response.data.message });
            });
    };
};
