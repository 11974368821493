import React from "react";
import ReactScrollBars, { ScrollbarProps } from "react-custom-scrollbars";

import "./scrollbars.scss";

interface State {
    top: number;
}

class Scrollbars extends React.Component<ScrollbarProps, State> {

    state = {
        top: 0
    };

    handleUpdate = (values: State) => {
        const { top } = values;
        this.setState({ top });
    }

    renderThumb = (props: any) => {
        return (
            <div
                className="Scrollbars__thumb"
                {...props}
            />
        );
    }

    renderTrack = (props: any) => {
        return (
            <div
                className="Scrollbars__track"
                {...props}
            />
        );
    }

    render() {
        return (
            <ReactScrollBars
                {...this.props}
                autoHide={true}
                renderThumbHorizontal={this.renderThumb}
                renderThumbVertical={this.renderThumb}
                renderTrackVertical={this.renderTrack}
                renderTrackHorizontal={this.renderTrack}
                onUpdate={this.handleUpdate}>
                {this.props.children}
            </ReactScrollBars>
        );
    }
}

export default Scrollbars;
