import socketIo          from "socket.io-client";
import { AnyAction }     from "redux";
import { ThunkDispatch } from "redux-thunk";
import A                 from "./notification-action-types";
import CinioAPI          from "@src/utils/cinio-api";
import { addAlert }      from "../app/app-actions";
import { AppState }      from "../reducers";
import { INotification } from "@src/definitions/notification";
import { addToastMessage } from "../toast-messages/toast-message-actions";
import { fetchCart } from "../cart/cart-actions";

/**
 * Fetch user's notifications
 */
export const fetchNotifications = () => {
    return async (dispatch: ThunkDispatch<any, {}, AnyAction>, getState: () => AppState) => {
        const companyType = getState().company.data.companyType;
        const canOrder    = companyType === "booker" || companyType === "cinema";

        dispatch({ type: A.FETCH_INIT });

        // First request will connect to web socket
        await CinioAPI.get("/notifications")
            .then((response) => dispatch({
                type: A.FETCH_SUCCESS,
                payload: response.data
            }))
            .catch((e) => dispatch(addAlert({
                type: "error",
                message: e.message
            })));

        // Connect to socket
        const socket = socketIo.connect(CINIO_API, {transports: ["websocket"]});

        // Listen for new notifications
        socket.on("notification", (newNotification: INotification) => {
            dispatch({ type: A.ADD_ONE, payload: newNotification });
            dispatch(addToastMessage(newNotification));

            // If the notification is about an updated booking request status, refresh cart
            if (newNotification.notificationType === "request" && canOrder) {
                dispatch(fetchCart());
            }
        });
    };
};

/**
 * Remove a notification
 * @param id - notification id
 */
export const removeNotification = (id: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) => {
        const originalNotifications = getState().notifications.items;

        // Remove from app state
        dispatch({ type: A.REMOVE_ONE, payload: id });

        // Display error and restore original state if removing fails
        return CinioAPI.delete(`/notifications/${id}`)
            .catch((e) => {
                dispatch(addAlert({
                    type: "error",
                    message: e.message
                }));
                dispatch({
                    type: A.FETCH_SUCCESS,
                    payload: originalNotifications
                });
            });
    };
};

/***
 * Mark a notification as seen
 * @param id - notification id
 */
export const toggleIsSeen = (id: string, isSeen?: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) => {
        const originalNotifications = getState().notifications.items;
        const notification = originalNotifications.find((notification) => notification.id === id);

        if (!notification) {
            return;
        }

        isSeen = isSeen === undefined ? !notification.isSeen : isSeen;

        // Remove from app state
        dispatch({ type: A.TOGGLE_IS_SEEN, payload: { id, isSeen } });

        // Display error and restore original state if updating fails
        return CinioAPI.patch(`/notifications/${id}`, { isSeen })
            .catch((e) => {
                dispatch(addAlert({
                    type: "error",
                    message: e.message
                }));
                dispatch({
                    type: A.FETCH_SUCCESS,
                    payload: originalNotifications
                });
            });
    };
};
