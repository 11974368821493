import * as R from "ramda";
import i18next from "i18next";
import CinipAPI from "@utils/cinio-api";
import { ICinema, ICinemaDeliveryFee } from "@definitions/cinema";
import { Option } from "@definitions/form";
import { Auditorium } from "@definitions/auditorium";
import { getDayCount } from "@utils/dates";
import { connectWords } from "@src/utils/strings";

import { Price, CurrencyCode } from "@definitions/price";
import { CartItemVersion, CartItem, BookingPeriodType } from "@definitions/cart";
import { getFormattedPriceString, getCurrencySymbolAndValue, createPriceFromValueAndCurrency, getPriceTypeName } from "@src/utils/prices";
import { IVenueType } from '@src/definitions/venue';
import { Translation } from "@src/definitions/translation";
import { PRICE_DEFAULT } from "@src/utils/defaults";

import {
    FilmModel,
    FilmType,
    FilmVersion,
    FilmVersionDistributor,
    LiveDate,
    IContentKind,
    IScreeningPrice,
    IScreeningPriceType,
    VersionPackageType
} from "@definitions/film";

/**
 * Common interface for calculating price for both films and cart items
 */
export interface PriceOptions {
    versions: CartItemVersion[];
    cinemas: ICinema[];
    lowestPrice: Price;
    filmType: FilmType;
    versionId: string;
    cinemaIds: string[];
    auditoriumIds: string[];
    includeFilmdepotMaterials?: boolean;
    startDate?: Date | null;
    endDate?: Date | null;
    bookingPeriodType: BookingPeriodType;
}

/**
 * Fetch film from Cinio API
 * @param id - _id or handle of the film
 * @param options - request options
 */
export const fetchFilm = async (id: string, options: GenericObject = {}) => {
    const response = await CinipAPI.get(`/films/${id}`, options);
    return response.data;
};

/**
 * Add or update film trough Cinio API
 * @param data - new film data
 */
export const saveFilm = async (data: FilmModel): Promise<FilmModel> => {
    const id       = data._id;
    const method   = id ? "patch" : "post";
    const uri      = id ? `/films/${id}` : "films";
    const response = await CinipAPI[method](uri, data);

    return response.data;
};

/**
 * Get formatted grand total
 * @param cinemas - available cinemas
 * @param cartItems - cart items
 */
export const getGrandTotal = (currency: CurrencyCode, cinemas: ICinema[], cartItems: CartItem[]) => {
    const total = cartItems.reduce((total, item) => {
        if (item.needsPermission && item.status !== "approved") {
            return total;
        }

        const subtotal = calculateSubtotal({ ...item, cinemas });

        return total + subtotal;
    }, 0);

    return getCurrencySymbolAndValue(currency, total);
};

/**
 * Get film cost calculation
 * @param priceOptions - price options
 * @param cinemas - available cinemas
 */
export const getFilmCostCalculation = (priceOptions: PriceOptions) => {
    const { startDate, endDate, cinemas, versions, versionId, bookingPeriodType } = priceOptions;

    const dayCount         = startDate && endDate ? getDayCount(startDate, endDate) : 1;
    const version          = getCartItemVersion(versions, versionId);
    const price            = getVersionPrice(version, bookingPeriodType);
    const venueType        = getVenueType(version, bookingPeriodType);
    const cinemaCount      = priceOptions.cinemaIds.length;
    const seatCount        = getSeatCount(cinemas, priceOptions.auditoriumIds);
    const displayPriceType = price?.priceType === "min-guarantee";
    const formattedPrice   = getFormattedPriceString(price, { displayOriginalPrice: true, displayPriceType });
    const duration         = getDuration(dayCount, price?.priceType);

    const venueCalculation = venueType === "cinema"
        ? `${cinemaCount} ${i18next.t("glossary::cinemas")}`
        : `${seatCount} ${i18next.t("glossary::seats")}`;

    if (priceOptions.filmType === "encore") {
        return "Fixed price";
    }
    if (priceOptions.filmType === "live-event") {
        return `${formattedPrice} x ${priceOptions.cinemaIds.length} ${i18next.t("glossary::cinemas")}`;
    }

    return `${formattedPrice} x ${duration} x ${venueCalculation}`;
};

/**
 * Get formatted subtotal
 * @param options - price options
 * @param getOriginalSubtotal - whether to get total in original currency
 */
export const getSubtotal = (options: PriceOptions, getOriginalSubtotal = false) => {
    const value = calculateSubtotal(options, getOriginalSubtotal);

    const currency = getOriginalSubtotal
        ? options.lowestPrice.originalCurrency
        : options.lowestPrice.currency;

    const price = createPriceFromValueAndCurrency(value, currency);

    return getFormattedPriceString(price, { displayOriginalPrice: true });
};

/**
 * Get formatted screening fee
 * @param options - price options
 */
export const getScreeningFee = (options: PriceOptions) => {
    const value = calculateScreeningFee(options);
    return getCurrencySymbolAndValue(options.lowestPrice.currency, value);
};

/**
 * Get formatted filmdepot fee
 * @param options
 */
export const getFilmdepotFee = (options: PriceOptions) => {
    const version = options.versions.find((version) => version.id === options.versionId);
    const { value = 0, currency = "EUR" } = version ? version.filmdepotMaterialsPrice : {};

    if (!value || !version) {
        return "";
    }

    return getCurrencySymbolAndValue(currency, value);
};

export const getFormattedDeliveryFee = (cinema: ICinema, contentKind: IContentKind) => {
    const deliveryFee = getDeliveryFee(cinema, contentKind);
    return getFormattedPriceString(deliveryFee, { displayOriginalPrice: true });
};

export const getContentKind = (filmType: FilmType, version: CartItemVersion | null): IContentKind => {
    if (filmType === "encore" || filmType === "live-event") {
        return filmType;
    }

    return version?.contentKind || "";
};

export const getDeliveryFeeName = (cinema: ICinema, contentKind: IContentKind) => {
    const deliveryFee = getDeliveryFee(cinema, contentKind);

    return deliveryFee.medium === "electronic"
        ? i18next.t("cart::electronic delivery")
        : i18next.t("cart::physical delivery");
};

/**
 * Get selected cinemas
 * @param venueType - venue type
 * @param cinemas - available cinemas
 * @param selectedVenues - ids of selected venues
 */
export const getSelectedCinemas = (venueType: IVenueType, cinemas: ICinema[], selectedVenues: string[]) => {
    return cinemas.filter((cinema) => {
        if (venueType === "cinema") {
            return selectedVenues.find((venueId) => cinema._id === venueId);
        }
        return cinema.auditoriums.find((auditorium) =>
            !!selectedVenues.find((venueId) => auditorium._id === venueId)
        );
    });
};

/**
 * Get total seat count
 * @param cinemas - available cinemas
 * @param selectedVenues - ids of selected venues
 */
export const getSeatCount = (cinemas: ICinema[], selectedVenues: string[]) => {
    return selectedVenues.reduce((seatCount, venueId) => {
        const auditorium = findAuditorium(cinemas, venueId);

        return auditorium
            ? auditorium.seatCount + seatCount
            : seatCount;
    }, 0);
};

export const getDuration = (dayCount: number, priceType: IScreeningPriceType | undefined) => {
    priceType = priceType || "fixed";

    if (dayCount % 7 === 0 && priceType.match("weekly")) {
        const weekCount = dayCount / 7;
        return `${weekCount} ${i18next.t("glossary::weeks")}`;
    }
    if (dayCount % 30 ===  0  && priceType.match("monthly")) {
        const monthCount = dayCount / 30;
        return `${monthCount} ${i18next.t("glossary::months")}`;
    }

    return `${dayCount} ${i18next.t("glossary::days")}`;
};

/**
 * Get version
 * @param versions - all available versions
 * @param versionId - selected version id
 */
export const getVersion = (versions: FilmVersion[], versionId: string) => {
    const version = versions.find((version) => version.id === versionId);

    if (!version && versions.length > 0) {
        return versions[0];
    }
    if (!version) {
        return null;
    }

    return version;
};

/**
 * Get cart item version
 * @param versions - all available versions
 * @param versionId - selected version id
 */
export const getCartItemVersion = (versions: CartItemVersion[], versionId: string) => {
    const version = versions.find((version) => version.id === versionId);

    if (!version && versions.length > 0) {
        return versions[0];
    }
    if (!version) {
        return null;
    }

    return version;
};

/**
 * Get address as formatted string
 * @param versions - all available versions
 * @param versionId - selected version id
 */
export const getAddress = (versions: FilmVersion[], versionId: string) => {
    const version = getVersion(versions, versionId);
    const address = version ? version.distributor.address : null;

    return address && address.street1
        ? `${address.street1}, ${address.zip} ${address.city}`
        : "No address";
};

/**
 * Get address as formatted string
 * @param versions - all available versions
 * @param versionId - selected version id
 */
export const getDistributor = (versions: FilmVersion[], versionId: string) => {
    const version = getVersion(versions, versionId);

    return version ? version.distributor : null;
};

/**
 * Get unique distributors from a title
 * @param versions - film versions
 */
export const getUniqueDistributors = (versions: FilmVersion[]) => {
    return versions.reduce((distributors: FilmVersionDistributor[], version) => {
        const exists = distributors.find((distributor) => distributor.id === version.distributor.id);

        return exists
            ? distributors
            : [...distributors, version.distributor];
    }, []);
};

/**
 * Get booking period type options
 * @param version - version or null
 */
export const getBookingPeriodOptions = (version: CartItemVersion | null) => {
    const prices = [version?.price, version?.weeklyPrice, version?.monthlyPrice]
        .filter((val) => val) as IScreeningPrice[];

    return prices.map((price) => ({
        label : getPriceTypeName(price.priceType, true),
        value : getBookingPeriodTypeFromPriceType(price.priceType)
    }));
};

/**
 * Check if a version has multiple booking period options
 * @param version - version or null
 */
export const getFixedPeriodDurationOptions = (bookingPeriodType: BookingPeriodType) => {
    const optionNumbers = new Array(6).fill(null).map((_, i) => i + 1);

    if (bookingPeriodType === "month") {
        return optionNumbers.map((value) => {
            const periodName = value > 1 ? i18next.t("glossary::months") : i18next.t("glossary::month");
            const label = String(value) + " " + periodName;
            return { label, value: String(value) };
        });
    }

    if (bookingPeriodType === "week") {
        return optionNumbers.map((value) => {
            const periodName = value > 1 ? i18next.t("glossary::weeks") : i18next.t("glossary::week");
            const label = String(value) + " " + periodName;

            return { label, value: String(value) };
        });
    }

    return [];
};

/**
 * Get options for version package type
 */
export const getPackageTypeOptions = (): Option[] => {
    const packageTypes: VersionPackageType[] = ["OV", "VF"];
    return packageTypes.map((type) => ({ value: type, label: type }));
};

/**
 * Calculate subtotal for a cart item
 * @param options - price options
 * @param getOriginalValue - get original currency value
 */
const calculateSubtotal = (options: PriceOptions, getOriginalValue = false) => {
    const screeningFee = calculateScreeningFee(options, getOriginalValue);
    const eTransferFee = calculateDeliveryFees(options, getOriginalValue);
    const filmdepotFee = getFilmdepotFeeValue(options);

    return screeningFee + eTransferFee + filmdepotFee;
};

/**
 * Get screening fee (excl. transportation cost)
 * @param options - price options
 * @param getOriginalValue - get original currency value
 */
const calculateScreeningFee = (options: PriceOptions, getOriginalValue = false) => {
    const {
        versions,
        cinemas,
        lowestPrice,
        versionId = "",
        cinemaIds = [],
        auditoriumIds = [],
        startDate = null,
        endDate = null,
        filmType,
        bookingPeriodType
    } = options;

    const dayCount     = getDayCount(startDate, endDate);
    const weekCount    = Math.floor(dayCount / 7);
    const monthCount   = Math.floor(dayCount / 30);
    const version      = getCartItemVersion(versions, versionId);
    const versionPrice = getVersionPrice(version, bookingPeriodType);
    const seatCount    = getSeatCount(cinemas, auditoriumIds);

    const value = getOriginalValue
        ? versionPrice ? versionPrice.originalValue : 0
        : versionPrice ? versionPrice.value : 0;

    if (filmType === "encore") {
        return 0;
    }

    if (!versionPrice) {
        return lowestPrice.value;
    }

    if (versionPrice.priceType === "fixed") {
        return value * cinemaIds.length * dayCount;
    }
    if (versionPrice.priceType === "fixed-weekly") {
        return value * cinemaIds.length * weekCount;
    }
    if (versionPrice.priceType === "fixed-monthly") {
        return value * cinemaIds.length * monthCount;
    }
    if (versionPrice.priceType === "per-seat") {
        return value * seatCount * dayCount;
    }
    if (versionPrice.priceType === "per-seat-weekly") {
        return value * seatCount * weekCount;
    }
    if (versionPrice.priceType === "per-seat-monthly") {
        return value * seatCount * monthCount;
    }

    return 0;
};

/**
 * Get price object for a film version
 * @param film - film object
 * @param versionId - id of version
 */
export const getVersionPrice = (version: CartItemVersion | null, bookingPeriodType: BookingPeriodType) => {
    if (bookingPeriodType === "day") {
        return version?.price || null;
    }
    if (bookingPeriodType === "week") {
        return version?.weeklyPrice || null;
    }
    if (bookingPeriodType === "month") {
        return version?.monthlyPrice || null;
    }

    return null;
};

/**
 * Calculate e-transfer fee for a cart item
 * @param options - price options
 * @param getOriginalValue - whether to get value in original currency
 */
const calculateDeliveryFees = (options: PriceOptions, getOriginalValue = false) => {
    const { filmType, cinemas, cinemaIds = [], auditoriumIds = [], versions, versionId, bookingPeriodType } = options;

    const version     = getCartItemVersion(versions, versionId);
    const venueType   = getVenueType(version, bookingPeriodType);
    const contentKind = getContentKind(filmType, version);

    if (venueType === "cinema") {
        return cinemas.reduce((totalCost: number, cinema) => {
            const isIncluded = cinemaIds.find((cinemaId) => cinema._id === cinemaId);
            const deliveryFee = getDeliveryFee(cinema, contentKind);

            const value = getOriginalValue
                ? deliveryFee.originalValue
                : deliveryFee.value;

            return isIncluded
                ? totalCost + value
                : totalCost;
        }, 0);
    }

    return cinemas.reduce((totalCost: number, cinema) => {
        const hasAuditorium = cinemaHasAuditorium(cinema, auditoriumIds);
        const deliveryFee = getDeliveryFee(cinema, contentKind);

        const value = getOriginalValue
            ? deliveryFee.originalValue
            : deliveryFee.value;

        return hasAuditorium
            ? totalCost + value
            : totalCost;
    }, 0);
};

const getDeliveryFee = (cinema: ICinema, contentKind: IContentKind): ICinemaDeliveryFee => {
    const deliveryFee = cinema.deliveryFees.find((fee) => fee.contentKind === contentKind);

    if (!deliveryFee) {
        return { ...PRICE_DEFAULT, medium: "electronic", contentKind };
    }

    return deliveryFee;
};

/**
 * Get value for Filmdepot promotion materials fee
 * @param options - price options
*/
const getFilmdepotFeeValue = (options: PriceOptions) => {
    const version = options.versions.find((version) => version.id === options.versionId);

    if (version && options.includeFilmdepotMaterials) {
        return version.filmdepotMaterialsPrice.value;
    }

    return 0;
};

/**
 * Check if cinema has any of the selected auditoriums
 * @param cinema - the cinema to check
 * @param selectedAuditoriums - selected auditorium ids
 */
const cinemaHasAuditorium = (cinema: ICinema, selectedAuditoriums: string[]) => {
    return !!cinema.auditoriums.find((auditorium) => R.contains(auditorium._id, selectedAuditoriums));
};

/**
 * Find an auditorium by id
 * @param cinemas - available cinemas
 * @param auditoriumId - auditorium id
 */
const findAuditorium = (cinemas: ICinema[], auditoriumId: string) => {
    return cinemas.reduce((targetAuditorium: Auditorium | null, cinema) => {
        if (targetAuditorium) {
            return targetAuditorium;
        }

        const auditorium = cinema.auditoriums.find((auditorium) => auditorium._id === auditoriumId);

        return auditorium || null;
    }, null);
};

/**
 * Get film version options
 * @param versions - film variants
 */
export const getVersionOptions = (versions: CartItemVersion[]) => {
    return versions.map((version) => {
        const dayPrice     = getFormattedPriceString(version.price, { displayPriceType: true });
        const weeklyPrice  = getFormattedPriceString(version.weeklyPrice, { displayPriceType: true });
        const monthlyPrice = getFormattedPriceString(version.monthlyPrice, { displayPriceType: true });

        const prices = [dayPrice, weeklyPrice, monthlyPrice].filter((val) => val).join(", ");
        const contentKindName = getContentKindName(version.contentKind);

        return {
            label: `${contentKindName} - ${version.name} (${prices})`,
            value: version.id
        };
    });
};

/**
 * Get venue type
 * @param versions - avaialble versions
 * @param versionId - id of selected version
 */
export const getVenueType = (version: CartItemVersion | null, bookingPeriodType: BookingPeriodType) => {
    const price = getVersionPrice(version, bookingPeriodType);

    if (!price) {
        return "cinema";
    }

    return getVenueTypeFromPriceType(price.priceType);
};

/**
 * Get booking period type from price type
 * @param priceType - price type
 */
export const getBookingPeriodTypeFromPriceType = (priceType: IScreeningPriceType): BookingPeriodType => {
    if (priceType === "fixed-weekly" || priceType === "per-seat-weekly") {
        return "week";
    }
    if (priceType === "fixed-monthly" || priceType === "per-seat-monthly") {
        return "month";
    }
    return "day";
};

/**
 * Get venue type from price type
 * @param priceType - price type
 */
export const getVenueTypeFromPriceType = (priceType: IScreeningPriceType) => {
    return !priceType.match("per-seat")
        ? "cinema"
        : "auditorium";
};

/**
 * Get venue options
 * @param cinemas - all available cinemas
 * @param versions - all available versions
 * @param versionId - the selected version
 */
export const getVenueOptions = (
    cinemas: ICinema[],
    versions: CartItemVersion[],
    versionId: string,
    bookingPeriodType: BookingPeriodType
) => {
    const version   = getCartItemVersion(versions, versionId);
    const venueType = getVenueType(version, bookingPeriodType);

    const validCinemas = version
        ? cinemas.filter((cinema) => cinemaIsValid(version, cinema))
        : [];

    if (venueType === "cinema") {
        return validCinemas.map((cinema) => ({
            value: cinema._id,
            label: cinema.name
        }));
    }

    if (!version) {
        return [];
    }

    return getAuditoriumOptions(validCinemas, version, bookingPeriodType);
};

/**
 * Check if a cinema is valid for a version
 * @param version - version
 * @param cinema - cinema
 */
export const cinemaIsValid = (version: CartItemVersion, cinema: ICinema) => {
    const isGloballyAvailable = version.countries.find((country) => country === "world");
    const country             = cinema.shippingAddress.country;
    const countryAndRegions   = [country, ...cinema.regions];
    const hasRegion           = (versionRegion: string) => countryAndRegions.some((cinemaRegion) => versionRegion === cinemaRegion);
    const hasRights           = isGloballyAvailable || version?.countries.some(hasRegion);
    const isExcluded          = version?.excludedCountries.some(hasRegion);

    return hasRights && !isExcluded;
};

/**
 * Check if an auditorium id is valid for a version
 * @param version - version
 * @param cinemas - available cinemas
 * @param auditoriumId - auditorium id
 */
export const auditoriumIdIsValid = (version: CartItemVersion, cinemas: ICinema[], auditoriumId: string) => {
    const cinema = cinemas.find((cinema) => cinema.auditoriums.some((a) => a._id === auditoriumId));
    return cinema ? cinemaIsValid(version, cinema) : false;
};

/**
 * Check if a cinema is valid for a version
 * @param version - version
 * @param cinemas - available cinemas
 * @param cinemaId - cinema id
 */
export const cinemaIdIsValid = (version: CartItemVersion, cinemas: ICinema[], cinemaId: string) => {
    const cinema = cinemas.find((c) => c._id === cinemaId);
    return cinema ? cinemaIsValid(version, cinema) : false;
};

/**
 * Get venue names
 * @param venueType - venue type
 * @param cinemas - available cinemas
 * @param selectedVenues - selected venues
 */
export const getVenueNames = (venueType: IVenueType, cinemas: ICinema[], cinemaIds: string[], auditoriumIds: string[]) => {
    if (venueType === "cinema") {
        const names = cinemas
            .filter((cinema) => R.contains(cinema._id, cinemaIds))
            .map((cinema) => cinema.name);
        return connectWords(names);
    }

    const names = cinemas.reduce((names: string[], cinema) => {
        const newAuditoriums = cinema.auditoriums.filter((auditorium) =>
            auditoriumIds.find((auditoriumId) => auditorium._id === auditoriumId
        ));

        const newNames = newAuditoriums.map((auditorium) =>
            cinema.name + " - " + auditorium.name
        );

        return [...names, ...newNames];
    }, []);

    return connectWords(names);
};

/**
 * Check if a live event is in the future
 * @param liveDate - live date object
 */
export const liveEventIsInFuture = (liveDate: LiveDate | null) => {
    const liveStartDate = liveDate?.startDate;

    return liveStartDate
        ? new Date(liveStartDate) > new Date()
        : false;
};

/**
 * Get auditorium options
 * @param cinemas - all available cinemas
 * @param version - the selected version
 */
const getAuditoriumOptions = (cinemas: ICinema[], version: CartItemVersion, bookingPeriodType: BookingPeriodType
) => {
    return cinemas.reduce((auditoriums: Option[], cinema) => {
        const isValid = cinemaIsValid(version, cinema);

        if (!isValid) {
            return auditoriums;
        }

        const newOptions = cinema.auditoriums.map((auditorium) => {
            const versionPrice = getVersionPrice(version, bookingPeriodType);
            const value        = (versionPrice?.value || 0) * auditorium.seatCount;
            const priceObj     = versionPrice ? { ...versionPrice, value } : null;
            const price        = getFormattedPriceString(priceObj, { displayPriceType: true });

            return {
                value: auditorium._id,
                label: cinema.name + " - " + auditorium.name + ` (${price})`
            };
        });

        return [...auditoriums, ...newOptions];
    }, []);
};

/**
 * Get translation from translation array
 * @param translations - translations
 * @param userLocale - current user's locale
 */
export const getTranslation = (translations: Translation[]) => {
    const userLocale = i18next.language;
    const userLocaleTranslation = translations.find((t) => t.locale === userLocale);
    const enTranslation = translations.find((t) => t.locale === "en");
    const translationObj = userLocaleTranslation || enTranslation;

    return translationObj?.value || "";
};

/**
 * Get content kind name for displaying
 * @param contentKind - content kind
 */
const getContentKindName = (contentKind: IContentKind) => {
    switch (contentKind) {
        case "35mm":
            return "35 mm";
        case "blu-ray":
            return "Blu-ray";
        case "encore":
            return "Encore";
        case "live-event":
            return i18next.t("films::Live event");
        case "short":
            return "Short";
        case "trailer":
            return "Trailer";
        case "feature":
            return "Feature";
        default:
            return "";
    }
};
