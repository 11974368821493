import React from "react";

import { IMediaItem, IMediaType, IMediaEntityType } from "@definitions/media";
import MediaUploader from "@components/media-uploader/MediaUploader";
import CinioAPI from "@src/utils/cinio-api";
import { MEDIA_ITEM_DEFAULT } from "@src/utils/defaults";

import FormMediaItem from "./MediaItem";

import "./media.scss";

export interface MediaProps {
    id?: string;
    value?: IMediaItem[] | IMediaItem;
    readOnly?: boolean;
    mediaTypes?: IMediaType[];
    entityType: IMediaEntityType;
    isSaved?: boolean;
    enableLocale?: boolean;
    onChange?(value: IMediaItem[] | IMediaItem): void;
    handleChange?(value: IMediaItem[] | IMediaItem): void;
}

interface State {
    mediaUploaderIsOpen: boolean;
    removedItems: string[];
}

/**
 * Component for displaying and upload media files
 */
class FormMedia extends React.Component<MediaProps, State> {

    reader = new FileReader();

    state = {
        mediaUploaderIsOpen: false,
        removedItems: []
    };

    componentDidUpdate(prevProps: MediaProps) {
        // If the data was updated on the server, delete the removed items from the server
        if (!prevProps.isSaved && this.props.isSaved) {
            this.deleteRemovedItems();
        }
    }

    /**
     * Handle new files after they have been loaded and converted to media items
     */
    handleNewFiles = async (mediaItems: IMediaItem[]) => {
        let newValue: IMediaItem | IMediaItem[] | null = null;

        if (!this.props.onChange) {
            return;
        }

        this.setState({ mediaUploaderIsOpen: false });

        if (!Array.isArray(this.props.value)) {
            this.props.value && this.addRemovedItem(this.props.value.src);
            newValue = mediaItems[0];
            this.props.onChange(mediaItems[0]);
            if (this.props.handleChange) {
                this.props.handleChange(mediaItems[0]);
            }
            return;
        } else {
            newValue = [...this.props.value, ...mediaItems];
        }

        this.props.onChange(newValue);
        if (this.props.handleChange) {
            this.props.handleChange(newValue);
        }
    }

    /**
     * Handle media item changes such as media type
     */
    handleMediaItemChange = async (index: number, updatedItem: IMediaItem) => {
        if (!Array.isArray(this.props.value)) {
            this.props.onChange && this.props.onChange(updatedItem);
            return;
        }

        const mediaItems = this.props.value.map((item, i) =>
            i === index
                ? updatedItem
                : item
        );

        this.props.onChange && this.props.onChange(mediaItems);
    }

    /**
     * Handle click on the remove item button
     */
    handleMediaItemRemove = async (index: number) => {
        if (this.props.readOnly) {
            return;
        }

        const confirmed = confirm("Are you sure you want to remove this media item?");
        const targetItem = Array.isArray(this.props.value) ? this.props.value[index] : this.props.value;

        if (!confirmed) {
            return;
        }

        // Add item to list of removed items
        if (targetItem) {
            this.addRemovedItem(targetItem.src);
        }

        // If it's a single media item, replace the deleted item with an empty item
        if (!Array.isArray(this.props.value)) {
            this.props.onChange && this.props.onChange(MEDIA_ITEM_DEFAULT);
            return;
        }

        const mediaItems = this.props.value.filter((_, i) =>
            i !== index
        );

        this.props.onChange && this.props.onChange(mediaItems);
    }

    /**
     * Add a media url to list of removed items
     */
    addRemovedItem = (url: string) => {
        if (!url) {
            return;
        }
        this.setState({ removedItems: [...this.state.removedItems, url] });
    }

    /**
     * Delete urls in removed items list
     */
    deleteRemovedItems = async () => {
        for (const url of this.state.removedItems) {
            await CinioAPI.delete("/media", { url })
            .then(() => console.log(`${url} was deleted.`))
            // Silent error handling because this is of no interest to the user
            .catch((e) => console.log(e));
        }
    }

    /**
     * Set media upload to open
     */
    openMediaUploader = async () => {
        if (this.props.readOnly) return;
        this.setState({ mediaUploaderIsOpen: true });
    }

    /**
     * Set media uploader to closed
     */
    closeMediaUploader = () => {
        this.setState({ mediaUploaderIsOpen: false });
    }

    getMediaItems = (): IMediaItem[] => {
        const items = Array.isArray(this.props.value)
            ? this.props.value
            : this.props.value && this.props.value.src ? [this.props.value] : [];

        // Display placeholder
        if (!Array.isArray(this.props.value) && !items.length && this.props.readOnly) {
            return [{
                src: "https://deconova.eu/wp-content/uploads/2016/02/default-placeholder.png",
                originalSrc: "https://deconova.eu/wp-content/uploads/2016/02/default-placeholder.png",
                height: 1500,
                width: 1500,
                mediaType: "image",
                locale: "en",
                isPlaceholder: true
            }];
        }

        return items;
    }

    shouldDisplayUploader() {
        if (this.props.readOnly) {
            return false;
        }
        // Don't display uploader if value is a single media item and it already exists
        if (!Array.isArray(this.props.value)) {
            return !this.props.value?.src;
        }
        return true;
    }

    render() {
        const mediaTypes = this.props.mediaTypes || ["video", "image"];
        const displayUploader = this.shouldDisplayUploader();
        const items = this.getMediaItems();

        return (
            <div className="FormMedia">
                <div className="FormMedia__items">
                    {items.map((item, i) =>
                        <FormMediaItem
                            key={item.src + i}
                            index={i}
                            item={item}
                            onChange={this.handleMediaItemChange}
                            onRemove={this.handleMediaItemRemove}
                            mediaTypes={mediaTypes}
                            readOnly={!!this.props.readOnly}
                            enableLocale={this.props.enableLocale || false}
                        />
                    )}
                    {displayUploader &&
                        <div className="FormMedia__upload" onClick={this.openMediaUploader}>
                            <i className={"cinicon-plus"}></i>
                            <i className={"cinicon-plus"}></i>
                        </div>
                    }
                    <MediaUploader
                        onCompletion={this.handleNewFiles}
                        onClose={this.closeMediaUploader}
                        isOpen={this.state.mediaUploaderIsOpen}
                        mediaTypes={mediaTypes}
                        entityType={this.props.entityType}
                    />
                </div>
            </div>
        );
    }
}

export default FormMedia;
