
import React from "react";
import ReactDatepicker, { ReactDatePickerProps } from "react-datepicker";
import i18next from "i18next";

export interface DateProps extends Omit<ReactDatePickerProps, "onChange" | "value"> {
    id?: string;
    value?: Date | null;

    startDate?: Date;
    endDate?: Date | null;
    isEndDate?: boolean;
    showTimeSelect?: boolean;
    dateFormat?: string;
    placeholder?: string;
    timeIntervals?: number;
    timeFormat?: string;
    readOnly?: boolean;

    onChange?(value: Date): void;
    handleBlur?(): void;
}

/**
 * Text input component
 */
const DateInput = (props: DateProps) => {
    const {
        id = "",
        startDate,
        endDate,
        onChange = () => {},
        showTimeSelect = false,
        isEndDate = false,
        readOnly = false
    } = props;

    const defaultPlaceholder = readOnly
        ? i18next.t("forms::No date selected")
        : i18next.t("forms::Select a date");

    const datePickerProps = {
        ...props,
        selectsStart    : !isEndDate,
        selectedEnd     : isEndDate,
        dateFormat      : props.dateFormat || "dd MMMM yyyy",
        selected        : props.value ? new Date(props.value) : null,
        placeholderText : props.placeholder || defaultPlaceholder,
        timeIntervals   : props.timeIntervals || 15,
        timeFormat      : props.timeFormat || "HH:mm",
        autoComplete    : "off",
        id,
        readOnly,
        onChange,
        startDate,
        endDate,
        showTimeSelect,
    };

    return (
        // @ts-ignore - React Datepicker doesn't accept Date as value, but strings don't format properly
        <ReactDatepicker {...datePickerProps} />
    );
};

export default DateInput;
