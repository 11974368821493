import * as R from "ramda";
import axios, { AxiosRequestConfig } from "axios";

import { ICompany, CompanyType } from "@definitions/company";
import { Currency } from "@definitions/price";
import { Locale } from "@definitions/translation";
import { ITag } from "@definitions/tags";
import { Country } from "@definitions/countries";
import { Film, FilmType, IFilmEventSubtype, IDecade } from "@definitions/film";
import { IClassification } from "@definitions/classification";
import { ICinema } from "@definitions/cinema";
import { User } from "@definitions/user";
import { IOrder, PaymentStatus } from "@definitions/order";
import { Category } from "@definitions/categories";
import { IStudioOffice } from "@definitions/studio-office";
import { IRequest, IRequestPostInput } from "@definitions/request";
import { IChat, IChatMessage } from "@definitions/chat";
import { NewChatInput } from "@src/definitions/chat";
import { ICrewMember } from "@src/definitions/crew-member";

interface CinioResponse<Data> {
    page: number;
    totalCount: number;
    data: Data;
}

type PaginationQuery = Partial<{
    page: number;
    limit: number;
}>;

type CinioSortOption<K extends string> = {
    [key in K]: "asc" | "desc";
};

export type CompanyQuery = PaginationQuery &
    Partial<{
        name: string;
        deleted: boolean;
        companyTypes: CompanyType[];
    }>;

export type CinemaQuery = PaginationQuery &
    Partial<{
        name: string;
        deleted: boolean;
    }>;

export type FilmQuery = PaginationQuery &
    Partial<{
        tags: string[];
        searchTerms: string[];
        categories: string[];
        published: boolean;
        deleted: boolean;
        filmType: FilmType;
        eventSubtype: IFilmEventSubtype;
        sort: FilmSortOptions;
        decades: string[];
    }>;

export type UserQuery = PaginationQuery &
    Partial<{
        name: string;
        deleted: boolean;
        companyType: CompanyType;
        companyId: string;
    }>;

export interface TagQuery {
    filmQuery?: FilmQuery;
    hideGofilexOnlyTags?: boolean;
}

export interface CategoryQuery {
    filmQuery?: FilmQuery;
}

export interface DecadeQuery {
    filmQuery?: FilmQuery;
}

export interface CountryQuery {
    excludeParentRegions?: boolean;
    excludeWorld?: boolean;
}

export type FilmSortOption = "publishDate" | "title" | "lastModified" | "creationDate" | "liveDate.startDate";
export type FilmSortOptions = Array<CinioSortOption<FilmSortOption>>;

axios.defaults.withCredentials = true;

const axiosRequest = axios.create({
    baseURL: CINIO_API,
});

/**
 * Error with message, stack and status
 */
class CinioApiError extends Error {
    status: number;

    constructor(message: string, stack: string, status: number) {
        super();

        this.message = message;
        this.stack = stack;
        this.status = status;
    }
}

/**
 * Handle error from cinio api
 * @param error - axios error
 */
const handleError = (error: Error): never => {
    const responseError = R.pathOr({}, ["response", "data"], error) as GenericObject;
    const message = responseError.message || error.message;
    const stack = responseError.stack || error.stack;
    const status = responseError.status || 500;

    throw new CinioApiError(message, stack, status);
};

/**
 * Cinio api helper
 */
const CinioAPI = {
    get: async (path: string, options: AxiosRequestConfig = {}) => {
        return axiosRequest
            .get(path, options)
            .then(response => response)
            .catch(handleError);
    },
    post: async (path: string, data: any = {}, options: AxiosRequestConfig = {}) => {
        return axiosRequest
            .post(path, data, options)
            .then(response => response)
            .catch(handleError);
    },
    put: async (path: string, data: any, options: AxiosRequestConfig = {}) => {
        return axiosRequest
            .put(path, data, options)
            .then(response => response)
            .catch(handleError);
    },
    patch: async (path: string, data: any, options: AxiosRequestConfig = {}) => {
        return axiosRequest
            .patch(path, data, options)
            .then(response => response)
            .catch(handleError);
    },
    delete: async (path: string, options: AxiosRequestConfig = {}) => {
        return axiosRequest
            .delete(path, options)
            .then(response => response)
            .catch(handleError);
    },
    getLocales: async (): Promise<Locale[]> => {
        const response = await CinioAPI.get("/locales");
        return response.data;
    },
    getCurrencies: async (): Promise<Currency[]> => {
        const response = await CinioAPI.get("/currencies");
        return response.data;
    },
    getCompanies: async (params: CompanyQuery = {}): Promise<CinioResponse<ICompany[]>> => {
        const response = await CinioAPI.get("/companies", { params });
        return response.data;
    },
    getTags: async (params: TagQuery = {}): Promise<ITag[]> => {
        const response = await CinioAPI.get("/tags", { params });
        return response.data;
    },
    getCategories: async (params: CategoryQuery = {}): Promise<Category[]> => {
        const response = await CinioAPI.get("/categories", { params });
        return response.data;
    },
    getCountries: async (params?: CountryQuery): Promise<Country[]> => {
        const response = await CinioAPI.get("/countries", { params });
        return response.data;
    },
    getFilms: async (params: FilmQuery = {}): Promise<CinioResponse<Film[]>> => {
        const response = await CinioAPI.get("/films", { params });
        return response.data;
    },
    getCinemas: async (params: CinemaQuery = {}): Promise<CinioResponse<ICinema[]>> => {
        const response = await CinioAPI.get("/cinemas", { params });
        return response.data;
    },
    getUsers: async (params: UserQuery = {}): Promise<CinioResponse<User[]>> => {
        const response = await CinioAPI.get("/users", { params });
        return response.data;
    },
    getOrders: async (params = {}): Promise<CinioResponse<IOrder[]>> => {
        const response = await CinioAPI.get("/orders", { params });
        return response.data;
    },
    updateOrder: async (orderId: string, data: { paymentStatus: PaymentStatus }): Promise<IOrder> => {
        const response = await CinioAPI.patch("/orders/" + orderId, data);
        return response.data;
    },
    getClassifications: async (params = {}): Promise<IClassification[]> => {
        const response = await CinioAPI.get("/classifications", { params });
        return response.data;
    },
    getDecades: async (params: DecadeQuery = {}): Promise<IDecade[]> => {
        const response = await CinioAPI.get("/films/decades", { params });
        return response.data;
    },
    getFilm: async (id: string, params = {}): Promise<Film> => {
        const response = await CinioAPI.get("/films/" + id, { params });
        return response.data;
    },
    getCompany: async (id: string): Promise<ICompany> => {
        const response = await CinioAPI.get("/companies/" + id);
        return response.data;
    },
    getCinema: async (id: string): Promise<ICinema> => {
        const response = await CinioAPI.get("/cinemas/" + id);
        return response.data;
    },
    getUser: async (id: string): Promise<User> => {
        const response = await CinioAPI.get("/users/" + id);
        return response.data;
    },
    getStudioOffices: async (params = {}): Promise<CinioResponse<IStudioOffice[]>> => {
        const response = await CinioAPI.get("/studio-offices", { params });
        return response.data;
    },
    getStudioOffice: async (id: string): Promise<IStudioOffice> => {
        const response = await CinioAPI.get("/studio-offices/" + id);
        return response.data;
    },
    postStudioOffice: async (office: Omit<IStudioOffice, "id">): Promise<IStudioOffice> => {
        const response = await CinioAPI.post("/studio-offices", office);
        return response.data;
    },
    patchStudioOffice: async (office: IStudioOffice): Promise<IStudioOffice> => {
        const response = await CinioAPI.patch("/studio-offices/" + office.id, office);
        return response.data;
    },
    postRequest: async (request: IRequestPostInput): Promise<IRequest> => {
        const response = await CinioAPI.post("/requests", request);
        return response.data;
    },
    postRequestComment: async (requestId: string, comment: string): Promise<IRequest> => {
        const response = await CinioAPI.post("/requests/" + requestId + "/comments", { comment });
        return response.data;
    },
    markRequestRead: async (requestId: string): Promise<IRequest> => {
        const response = await CinioAPI.post("/requests/" + requestId + "/mark-events-seen");
        return response.data;
    },
    getChats: async (params: PaginationQuery): Promise<CinioResponse<IChat[]>> => {
        const response = await CinioAPI.get("/chats", { params });
        return response.data;
    },
    postChat: async (input: NewChatInput): Promise<IChat> => {
        const response = await CinioAPI.post("/chats", input);
        return response.data;
    },
    postChatMessage: async (chatId: string, message: string): Promise<IChatMessage> => {
        const response = await CinioAPI.post("/chats/" + chatId + "/messages", { message });
        return response.data;
    },
    getChatMessages: async (query: PaginationQuery & { chatId: string }): Promise<CinioResponse<IChat[]>> => {
        const { chatId, ...params } = query;
        const response = await CinioAPI.get("/chats/" + chatId + "/messages", { params });
        return response.data;
    },
    getCrewMembers: async (params: PaginationQuery & { name?: string }): Promise<CinioResponse<ICrewMember[]>> => {
        const response = await CinioAPI.get("/crew-members", { params });
        return response.data;
    },
    getCrewMember: async (id: string): Promise<ICrewMember> => {
        const response = await CinioAPI.get("/crew-members/" + id);
        return response.data;
    },
    patchCrewMember: async (id: string, data: Partial<ICrewMember>): Promise<ICrewMember> => {
        const response = await CinioAPI.patch("/crew-members/" + id, data);
        return response.data;
    },
    postCrewMember: async (data: Partial<ICrewMember>): Promise<ICrewMember> => {
        const response = await CinioAPI.post("/crew-members", data);
        return response.data;
    },
    deleteCrewMember: async (id: string): Promise<boolean> => {
        await CinioAPI.delete("/crew-members/" + id);
        return true;
    },
};

export default CinioAPI;
