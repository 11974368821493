import React  from 'react';

interface Props {
    handleFileSelect(e: React.ChangeEvent<HTMLInputElement>): void;
}

/**
 * Component for handling local file uploads
 */
const MediaFileUploader = (props: Props) => {
    return (
        <div className="MediaFileUploader">
            <div className="MediaFileUploader__inner">
                Drop files here or click to browse
            </div>
            <input className="MediaFileUploader__input"
                type="file"
                multiple
                onChange={props.handleFileSelect}
            />
        </div>
    );
}

export default MediaFileUploader;
