
import React, { useRef } from "react";

export interface TextInputProps {
    id?: string;
    value?: string;
    type?: string;

    readOnly?: boolean;
    placeholder?: string;

    onChange?(value: string): void;
    handleBlur?(): void;
}

/**
 * Text input component
 */
const TextInput = (props: TextInputProps) => {
    const ref = useRef<HTMLInputElement>(null);

    const {
        id = "",
        value = "",
        type = "text",
        readOnly = false,
        placeholder = "",
        handleBlur = () => {/**/}
    } = props;

    const onChange = (value: string) => {
        if (props.onChange) {
            const formattedValue = type === "number" ? value.replace(/[^\d.-]/g, "") : value;
            props.onChange(formattedValue);
        }
    };

    return (
        <input
            ref={ref}
            id={id}
            value={value}
            type={type === "number" ? "text" : type}
            onChange={(e) => {
                const selectionStart = e.target.selectionStart === null
                    ? e.target.value.length
                    : e.target.selectionStart;

                onChange(e.target.value);

                // Prevent cursor from jumping to end of input when typing - IMPORTANT!
                setTimeout(() => {
                    if (ref.current) ref.current.setSelectionRange(selectionStart, selectionStart);
                }, 0);
            }}
            className="TextInput"
            readOnly={readOnly}
            placeholder={placeholder}
            onBlur={handleBlur}
        />
    );
};

export default TextInput;
