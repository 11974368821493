import ACTIONS  from "./tag-action-types";
import CinioAPI from "@src/utils/cinio-api";
import i18next  from "i18next";

import { addAlert } from "../app/app-actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

/**
 * Fetch tags from Cinio API
 */
export const fetchTags = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction >) => {
        try {
            dispatch({
                type: ACTIONS.FETCH_INIT
            });

            const tags = await CinioAPI.getTags();

            dispatch({
                type: ACTIONS.FETCH_SUCCESS,
                payload: tags
            });

        } catch (e) {
            dispatch(addAlert({
                type: "error",
                title: i18next.t("errors::Could not fetch tags"),
                message: e.message
            }));
        }
    };
};
