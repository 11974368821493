import A from "./notification-action-types";
import { INotification } from "@src/definitions/notification";
import { AnyAction } from "redux";
import { sortByDate } from "@src/utils/dates";

export interface NotificationState {
    items: INotification[];
    isFetching: boolean;
}

const defaultState = {
    items: [],
    isFetching: true
};

/**
 * Reducer for notifications
 * @param state - state
 * @param action - action
 */
const notificationReducer = (state: NotificationState = defaultState, action: AnyAction) => {
    switch (action.type) {

        case A.FETCH_INIT: {
            return { ...state, isFetching: true };
        }

        case A.FETCH_SUCCESS: {
            const items = sortByDate(action.payload, "date");
            return { ...state, isFetching: false, items };
        }

        case A.ADD_ONE: {
            const items = sortByDate([action.payload, ...state.items], "date");

            return { ...state, items };
        }

        case A.REMOVE_ONE: {
            const items = state.items.filter((notification) => notification.id !== action.payload);
            return { ...state, items };
        }

        case A.TOGGLE_IS_SEEN: {
            const { id, isSeen } = action.payload;

            const items = state.items.map((notification) =>
                notification.id === id
                    ? { ...notification, isSeen }
                    : notification
            );

            return { ...state, items };
        }

        default:
            return state;
    }
};

export default notificationReducer;
