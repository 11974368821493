import React from "react";
import i18next from "i18next";

import { deleteFromObject } from "@utils/objects";
import { useFormContext } from "../FormContext";

export interface DeleteButtonProps {
  path: string;

  id?: string;
  text?: string;
  readOnly?: boolean;
}

/**
 * Delete button component
 */
const DeleteButton = (props: DeleteButtonProps) => {
  const { data, onChange } = useFormContext();
  const { id = "", path, text = i18next.t("glossary::Delete"), readOnly = false } = props;

  const isDisabled = readOnly ? "is-disabled" : "";

  const handleDelete = () => {
    if (readOnly) return;

    const updatedData = deleteFromObject(data, path);

    if (confirm(i18next.t("forms::Are you sure you want to delete this?"))) {
        onChange(updatedData);
    }
  };

  return (
      <button
          id={id}
          className={`FormButton FormButton--delete ${isDisabled}`}
          onClick={handleDelete}>
          {text || "Delete"}
      </button>
  );
};

export default DeleteButton;
