import { AnyAction }     from "redux";
import { ThunkDispatch } from "redux-thunk";

import ACTIONS      from "./wish-list-action-types";
import CinioAPI     from "@src/utils/cinio-api";
import { addAlert } from "../app/app-actions";

import { NewWishListItem, WishListItem } from "@src/definitions/wish-list";
import { AppState } from "../reducers";

/**
 * Fetch wish list items from Cinio API
 */
export const fetchWishList = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: ACTIONS.FETCH_INIT });

        CinioAPI.get("/wish-list")
            .then((response) => dispatch({
                type: ACTIONS.FETCH_SUCCESS,
                payload: response.data
            }))
            .catch((e) => dispatch(addAlert({
                type: "error",
                message: e.message
            })));
    };
};

/**
 * Add an item to wish list through Cinio API
 * @param item - wish list item
 */
export const addWishListItem = (item: NewWishListItem): any => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        const payload = {
            ...item,
            _id: String(+new Date())
        };

        try {
            // Add wish list item with fake _id
            dispatch({ type: ACTIONS.ADD_ITEM, payload });

            const response = await CinioAPI.post("/wish-list/items", item);

            // Update the id of the newly added item
            dispatch({
                type: ACTIONS.FETCH_SUCCESS,
                payload: response.data
            });

        } catch (e) {
            // Remove the newly added item
            dispatch({
                type: ACTIONS.REMOVE_ITEM,
                payload: payload._id
            });
            // Display error alert
            dispatch(addAlert({
                type: "error",
                message: e.message
            }));
        }
    };
};

export const updateWishListItem = (wishListItem: WishListItem) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) => {
        const id = wishListItem._id;
        const originalItems = getState().wishList.items;

        dispatch({ type: ACTIONS.UPDATE_ITEM, payload: wishListItem });

        CinioAPI.patch(`/wish-list/items/${id}`, wishListItem)
            .catch((e) => {
                // Reset change in state
                dispatch({ type: ACTIONS.RESET_ITEMS, payload: originalItems });
                // Display error alert
                dispatch(addAlert({
                    type: "error",
                    title: "Could not update wish list item",
                    message: e.message
                }));
            });
    };
};

/**
 * Remove item from wish list via Cinio API
 * @param id - id of item to be removed
 */
export const removeWishListItem = (id: string): any => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) => {
        const originalItems = getState().wishList.items;

        dispatch({ type: ACTIONS.REMOVE_ITEM, payload: id });

        CinioAPI.delete(`/wish-list/items/${id}`)
            .catch((e) => {
                // Add deleted item
                dispatch({ type: ACTIONS.RESET_ITEMS, payload: originalItems });
                // Display error alert
                dispatch(addAlert({
                    type: "error",
                    title: "Could not remove wish list item",
                    message: e.message
                }));
            });
    };
};

/**
 * Select a wish list item to edit
 * @param wishListItemId - wish list item id
 */
export const editWishListItem = (wishListItemId: string) => ({
    type: ACTIONS.EDIT_ITEM,
    payload: wishListItemId
});

/**
 * Stop editing a wish list item
 * @param wishListItemId - wish list item id
 */
export const stopEditingWishListItem = (wishListItemId: string) => ({
    type: ACTIONS.STOP_EDITING_ITEM,
    payload: wishListItemId
});
