import ACTIONS       from "./wish-list-action-types";
import { AnyAction } from "redux";

import { WishListItem, ApiWishListItem, ReducerWishListItem } from "@definitions/wish-list";

export interface WishListState {
    items: ReducerWishListItem[];
    isFetching: boolean;
}

const defaultState = {
    items: [],
    isFetching: false
};

/**
 * Reducer for wish list
 */
const wishListReducer = (state: WishListState = defaultState, action: AnyAction): WishListState => {
    switch (action.type) {

        case ACTIONS.FETCH_INIT: {
            return state;
        }

        case ACTIONS.FETCH_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                items: action.payload.map((wishListItem: ApiWishListItem) => ({
                    ...wishListItem,
                    isBeingEdited: false
                }))
            };
        }

        case ACTIONS.RESET_ITEMS: {
            return {
                ...state,
                items: action.payload
            };
        }

        case ACTIONS.ADD_ITEM: {
            const newItem: WishListItem = action.payload;

            const itemExists = state.items.find((item) =>
                item.filmId === newItem.filmId
                && item.filmType === newItem.filmType
            );

            if (itemExists) {
                return state;
            }

            return {
                ...state,
                items: [...state.items, newItem]
            };
        }

        case ACTIONS.REMOVE_ITEM: {
            const id = action.payload;
            const items = state.items.filter((item) => item._id !== id);

            return { ...state, items };
        }

        case ACTIONS.EDIT_ITEM: {
            const items = state.items.map((wishListItem) =>
                wishListItem._id === action.payload
                    ? { ...wishListItem, isBeingEdited: true }
                    : wishListItem
            );

            return { ...state, items };
        }

        case ACTIONS.UPDATE_ITEM: {
            const updatedItem: WishListItem = action.payload;

            const items = state.items.map((wishListItem) =>
                wishListItem._id === updatedItem._id
                    ? { ...wishListItem, ...updatedItem, isBeingEdited: false }
                    : wishListItem
            );

            return { ...state, items };
        }

        case ACTIONS.STOP_EDITING_ITEM: {
            const items = state.items.map((wishListItem) =>
                wishListItem._id === action.payload
                    ? { ...wishListItem, isBeingEdited: false }
                    : wishListItem
            );

            return { ...state, items };
        }

        default:
            return state;
    }
};

export default wishListReducer;
