import React        from "react";
import i18next      from "i18next";
import { connect }  from "react-redux";
import { AppState } from "@store/reducers";
import CartItem     from "./CartItem";
import CartEmpty    from "./CartEmpty";

import { editCartItem, removeCartItem, updateCartItem, stopEditingCartItem } from "@store/cart/cart-actions";
import { getGrandTotal } from "@src/app/films/film-utils";
import { Link } from "react-router-dom";
import { ICinema } from "@src/definitions/cinema";
import { CurrencyCode } from "@src/definitions/price";
import { toggleSideBarCollapse } from "@store/app/app-actions";

import "./cart.scss";

interface Actions {
    editCartItem: typeof editCartItem;
    removeCartItem: typeof removeCartItem;
    updateCartItem: typeof updateCartItem;
    stopEditingCartItem: typeof stopEditingCartItem;
    toggleSideBarCollapse: typeof toggleSideBarCollapse;
}

interface ReduxProps {
    currency: CurrencyCode;
    cinemas: ICinema[];
}

type Props = ReduxProps & AppState["cart"] & Actions;

const Cart = (props: Props) => {
    const total = getGrandTotal(props.currency, props.cinemas, props.items);
    const validItems = props.items.filter((item) => item.status === "approved");

    const isEmpty = !props.items.length;
    const canCheckout = !!validItems.length;

    return (
        <div className="Cart">
            <h2 className="Cart__title">
                {i18next.t("glossary::Cart")}
            </h2>
            {!props.items.length && <CartEmpty />}
            <div className="Cart__items">
                {props.items.map((item) =>
                    <CartItem
                        key={item._id}
                        item={item}
                        removeCartItem={props.removeCartItem}
                        editCartItem={props.editCartItem}
                        updateCartItem={props.updateCartItem}
                        stopEditingCartItem={props.stopEditingCartItem}
                        toggleSideBarCollapse={props.toggleSideBarCollapse}
                        cinemas={props.cinemas}
                    />
                )}
            </div>
            {!isEmpty &&
                <div className="Cart__checkout">
                        <div className="Cart__total">
                            <div className="Cart__grandTotal">
                            {i18next.t("glossary::Total")}: {total}
                            </div>
                        </div>
                        <Link className={`Cart__checkoutButton ${canCheckout ? "" : "is-disabled"}`} to="/checkout"
                            onClick={() => canCheckout && props.toggleSideBarCollapse(true)}>
                            {i18next.t("glossary::Checkout")}
                        </Link>
                </div>
            }
        </div>
    );
};

export const mapStateToProps = (state: AppState) => ({
    ...state.cart,
    cinemas  : state.cinemas.data,
    currency : state.company.data.currency
});

export const actions = { editCartItem, removeCartItem, updateCartItem, stopEditingCartItem, toggleSideBarCollapse };

export default connect(mapStateToProps, actions)(Cart);
