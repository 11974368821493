import * as R      from "ramda";
import React       from "react";
import i18next     from "i18next";
import { connect } from "react-redux";
import { Link }    from "react-router-dom";

import { withRouter, RouteComponentProps } from "react-router";
import Form, { FormField, SubmitButton } from "@components/form/Form";

import apiRequest          from "@utils/cinio-api";
import { AppState }        from "@store/reducers";
import { updateUser }      from "@store/user/user-actions";
import { fetchCompany }    from "@store/company/company-actions";
import { fetchCinemas }    from "@src/store/cinemas/cinema-actions";
import { fetchWishList }   from "@store/wish-list/wish-list-actions";
import { fetchCart }       from "@store/cart/cart-actions";
import { User }            from "@definitions/user";

import "./login-screen.scss";

interface Actions {
    updateUser(user: User): void;
    fetchCompany(): void;
    fetchCart(): void;
    fetchCinemas(): void;
    fetchWishList(): void;
}

type StateProps = AppState["user"];

type Props = Actions & StateProps & RouteComponentProps;

interface State {
  username: string;
  password: string;
  error: string;
  isLoading: boolean;
}

/**
 * Login form
 */
class LoginForm extends React.Component<Props, State> {

    state: State = {
        username: "",
        password: "",
        error: "",
        isLoading: false
    };

    componentDidMount() {
        document.addEventListener("keyup", this.handleKeyup);
        window.history.pushState("", "", "/log-in");
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.handleKeyup);
    }

    handleKeyup = (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
            this.handleSubmit();
        }
    }

    handleChange = (updatedState: State) => {
      this.setState(updatedState);
    }

    handleSubmit = async () => {
        const { username, password } = this.state;

        if (!username || !password) {
            return false;
        }

        this.setState({ isLoading: true, error: "" });

        return apiRequest.post("/log-in", { username, password })
            .then(async () => {
                window.location.pathname = "/home";
                return true;
            })
            .catch((error) => {
                const message = R.pathOr(error.message, ["response", "data", "message"], error) as unknown as string;
                this.setState({ error: message, isLoading: false });
                return false;
            });
    }

    render() {
        const errorIsvisible = this.state.error ? "is-visible" : "";

        return (
            <Form
                data={this.state}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}>
                <h1>{i18next.t("auth::Sign in")}</h1>
                <div className={`LoginScreen__error ${errorIsvisible}`}>
                    <div className="LoginScreen__errorInner">
                        {this.state.error}
                    </div>
                </div>
                <FormField
                    className="FormField__input"
                    path="username"
                    label={i18next.t("users::Username")}
                    type="text"
                    placeholder={i18next.t("users::Username")}
                />
                <FormField
                    className="FormField__input"
                    path="password"
                    label={i18next.t("users::Password")}
                    type="password"
                    placeholder={i18next.t("users::Password")}
                />
                <div className="LoginScreen__formButtons">
                    <SubmitButton text={i18next.t("auth::Sign in")} />
                    <div className="LoginScreen__footer">
                        <div className="LoginScreen__forgotPassword">
                            <span>{i18next.t("auth::Forgot your password?")}</span>
                            <Link to="/password-recovery">{i18next.t("auth::Recover it here!")}</Link>
                        </div>
                        <div className="LoginScreen__requestAccount">
                            <span>{i18next.t("auth::Or request a new account at")}</span>
                            <a href="https://cinio.net">{i18next.t("cinio.net")}</a>
                        </div>

                    </div>
                </div>
            </Form>
        );
    }
}

export const mapStateToProps = (state: AppState) => state.user;
export const actions = { updateUser, fetchCompany, fetchCart, fetchCinemas, fetchWishList };

export default withRouter(connect(mapStateToProps, actions)(LoginForm));
