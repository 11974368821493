import { AnyAction } from "redux";
import ACTIONS from "./app-action-types";
import Alert from "@definitions/alert";

export interface AppAlert extends Alert {
    id: string;
}

export interface AppReducerState {
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
    alerts: AppAlert[];
    isMobile: boolean;
    hasTouch: boolean;
    sidebarCollapsed: boolean;
    activeSidebarComponent: string;
}

const defaultState = {
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
    alerts: [],
    isMobile: true,
    hasTouch: false,
    sidebarCollapsed: true,
    activeSidebarComponent: "",
};

/**
 * Reducer for app state
 * @param state
 * @param action
 */
const appReducer = (state: AppReducerState = defaultState, action: AnyAction): AppReducerState => {
    switch (action.type) {

        case ACTIONS.UPDATE_DIMENSIONS: {
            const { scrollHeight, clientHeight } = action.payload;

            return {
                ...state,
                scrollHeight,
                clientHeight
            };
        }

        case ACTIONS.UPDATE_SCROLL_TOP: {
            return {
                ...state,
                scrollTop: action.payload
            };
        }

        case ACTIONS.ADD_ALERT_MESSAGE: {
            const alertExists = state.alerts.find((alert) =>
                alert.message === action.payload.message
            );

            if (alertExists) {
                return state;
            }

            const alert = {
                id: String(Date.now()),
                ...action.payload
            };

            return {
                ...state,
                alerts: [...state.alerts, alert]
            };
        }

        case ACTIONS.REMOVE_ALERT_MESSAGE: {
            const alerts = state.alerts.filter((alert) =>
                alert.id !== action.payload
            );
            return {
                ...state,
                alerts
            };
        }

        case ACTIONS.CLEAR_ALERTS: {
            return {
                ...state,
                alerts: []
            };
        }

        case ACTIONS.TOGGLE_MOBILE_MODE: {
            return {
                ...state,
                isMobile: action.payload
            };
        }

        case ACTIONS.TOGGLE_TOUCH: {
            return {
                ...state,
                hasTouch: action.payload
            };
        }

        case ACTIONS.TOGGLE_SIDEBAR_COMPONENT: {
            const componentId = action.payload.id;

            const activeSidebarComponent = componentId === state.activeSidebarComponent
                ? ""
                : componentId;

            return {
                ...state,
                sidebarCollapsed: false,
                activeSidebarComponent
            };
        }

        case ACTIONS.TOGGLE_SIDEBAR_COLLAPSE: {
            return {
                ...state,
                sidebarCollapsed: action.payload,
                activeSidebarComponent: "",
            };
        }

        default:
            return state;

    }
};

export default appReducer;
