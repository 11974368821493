/**
 * Function for checking whether device is mobile
 */
export const isMobile = () => {
    const isMobileEl = document.querySelector("#is-mobile");

    if (!isMobileEl) {
        return false;
    }

    const style = window.getComputedStyle(isMobileEl);
    return style.display !== "none";
};

/**
 * Function for checking whether device has touch
 */
export const hasTouch = () => {
    try {
        document.createEvent("TouchEvent");
        return true;
      } catch (e) {
        return false;
      }
};
