import { AnyAction }     from "redux";
import { ThunkDispatch } from "redux-thunk";
import ACTIONS           from "./cart-action-types";
import CinioAPI          from "@src/utils/cinio-api";
import { addAlert }      from "../app/app-actions";

import { NewCartItem, CartItem } from "@src/definitions/cart";
import { AppState } from "../reducers";
import i18next from "i18next";
import { validateCartItem } from "@src/utils/cart-utils";

/**
 * Fetch cart items from Cinio API
 */
export const fetchCart = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: ACTIONS.FETCH_INIT });

        CinioAPI.get("cart")
            .then((response) => dispatch({
                type: ACTIONS.FETCH_SUCCESS,
                payload: response.data
            }))
            .catch((e) => dispatch(addAlert({
                type: "error",
                message: e.message
            })));
    };
};

/**
 * Add an item to cart through Cinio API
 * @param item - cart item
 */
export const addCartItem = (item: NewCartItem): any => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        const payload = {
            ...item,
            _id: String(+new Date())
        };

        try {
            // Add cart item with fake _id
            dispatch({ type: ACTIONS.ADD_ITEM, payload });

            const response = await CinioAPI.post("/cart/items", item);

            // Update items
            dispatch({
                type: ACTIONS.FETCH_SUCCESS,
                payload: response.data
            });

            return;
        } catch (e) {
            // Remove the newly added item
            dispatch({
                type: ACTIONS.REMOVE_ITEM,
                payload: payload._id
            });

            throw e;
        }
    };
};

export const updateCartItem = (cartItem: CartItem): any => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) => {
        const id = cartItem._id;
        const originalItems = getState().cart.items;

        dispatch({ type: ACTIONS.UPDATE_ITEM_LOCALLY, payload: cartItem });

        const updatedItem = getState().cart.items.find((item) => item._id === id);

        // If the cart item is not valid, do not send changes to backend
        if (!updatedItem || !validateCartItem(updatedItem)) {
            return;
        }

        try {
            await CinioAPI.patch(`/cart/items/${id}`, updatedItem);
            dispatch({ type: ACTIONS.MARK_ITEM_SAVED, payload: id });
        } catch (e) {
            // Reset original items to original state
            dispatch({ type: ACTIONS.RESET_ITEMS, payload: originalItems });
            throw e;
        }
    };
};

/**
 * Remove item from cart via Cinio API
 * @param id - id of item to be removed
 */
export const removeCartItem = (id: string): any => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) => {
        const originalItems = getState().cart.items;
        const item          = originalItems.find((cartItem) => cartItem._id === id);

        if (item && item.status === "pending") {
            const confirmed = confirm(i18next.t("cart::Are you sure? The request will be withdrawn."));
            if (!confirmed) { return; }
        }

        dispatch({ type: ACTIONS.REMOVE_ITEM, payload: id });

        CinioAPI.delete(`/cart/items/${id}`)
            .catch((e) => {
                // Reset items to original state
                dispatch({ type: ACTIONS.RESET_ITEMS, payload: originalItems });
                // Display error alert
                dispatch(addAlert({
                    type: "error",
                    title: "Could not remove cart item",
                    message: e.message
                }));
            });
    };
};

/**
 * Start editing a cart item
 * @param cartItemId - cart item id
 */
export const editCartItem = (cartItemId: string) => ({
    type: ACTIONS.START_EDITING_ITEM,
    payload: cartItemId
});

/**
 * Stop editing a cart item
 * @param cartItemId - cart item id
 */
export const stopEditingCartItem = (cartItemId: string) => ({
    type: ACTIONS.STOP_EDITING_ITEM,
    payload: cartItemId
});
