import { IMediaType } from "@src/definitions/media";
import { isVideoUrl } from '@src/components/video/video-utils';

interface IMediaTypeOption {
    label: string;
    value: IMediaType;
    forVideos?: boolean;
};

const mediaTypeOptions: IMediaTypeOption[] = [
    {  label: "Image", value: "image" },
    {  label: "Video", value: "video", forVideos: true },
    {  label: "Trailer", value: "trailer", forVideos: true },
    {  label: "Film page still", value: "film-page-hero" },
    {  label: "Home page hero", value: "home-page-hero" },
    {  label: "Home page slider image", value: "home-page-slider-image" },
    {  label: "Poster", value: "poster" },
    {  label: "Logo", value: "logo" },
];

/**
 * Get media type options according to media type and allowed types
 * @param src 
 * @param allowedTypes 
 */
const getMediaTypeOptions = (src: string, allowedTypes: IMediaType[]) => {
    const isVideo = isVideoUrl(src);

    return mediaTypeOptions.filter((option) => {
        const isAllowed     = allowedTypes.find((type) => option.value === type);
        const isCorrectType = isVideo ? option.forVideos : !option.forVideos;

        return isAllowed && isCorrectType;
    });
};

export default getMediaTypeOptions;
