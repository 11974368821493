import * as R from "ramda";
import { useState, useCallback, useRef, useEffect } from "react";
import { debounce } from "./functions";
import { getObjectFromQueryString, buildQueryString } from "./strings";
import CinioAPI, { CompanyQuery, TagQuery, CategoryQuery, DecadeQuery, UserQuery, CountryQuery } from "./cinio-api";
import { Option } from "@src/definitions/form";
import { User } from "@src/definitions/user";
import { CompanyType } from "@src/definitions/company";
import { IMediaItem } from "@src/definitions/media";

interface PaginationAndFiltersOptions<Data, Filters, Sort> {
    data: Data[];
    limit: number;
    filters?: Filters;
    sort?: Sort;
    fetch: (options: Filters & { page: number; limit: number; sort: Sort }) => Promise<FetchResponse | null>;
    disableUrlQuery?: boolean;
    infiniteScroll?: boolean;
    prependData?: boolean;
}

interface FetchResponse {
    data: GenericObject[];
    totalCount: number;
}

interface FetchOptionsQuery {
    limit: number;
    searchTerm: string;
}

type FetchOptions<AsyncOption extends Option> = (query: FetchOptionsQuery) => Promise<{ options: AsyncOption[]; totalCount: number } | null>;
type FetchMissingOptions<AsyncOption extends Option> = (ids: string[]) => Promise<AsyncOption[]>;

/**
 * Generic hook for dealing with filters and pagination
 * @param initialData - initial data
 * @param initialPagination - initial pagination
 * @param initialFilters - initial filters
 * @param externalFetch - function for fetching data
 */
export const userPaginationAndFilters = <Data, Filters extends GenericObject = GenericObject, Sort extends any[] = any[]>(
    options: PaginationAndFiltersOptions<Data, Filters, Sort>
) => {
    const [data, setData] = useState(options.data);
    const [isFetching, setIsFetching] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(options.limit);
    const [totalCount, setTotalCount] = useState(0);
    const [filters, setFilters] = useState(options.filters || ({} as Filters));
    const [sort, setSort] = useState(options.sort || (([] as unknown) as Sort));

    let lastRequest = 0;

    type Fetch = (newFilters: Filters, sort: Sort, page: number, limit: number) => void;

    const debouncedFetch: Fetch = useDebounce(async (newFilters: Filters, sort: Sort, page: number, limit: number) => {
        const requestTime = +new Date();
        lastRequest = requestTime;

        const response = await options.fetch({ ...newFilters, page, limit, sort });

        if (!response) {
            setIsFetching(false);
            return;
        }

        if (requestTime === lastRequest) {
            const newData = response.data as Data[];

            if (options.infiniteScroll && page !== 1) {
                if (options.prependData) {
                    newData.reverse().push(...data.reverse());
                } else {
                    newData.unshift(...data);
                }
            }

            setData(newData);
            setIsFetching(false);
            setTotalCount(response.totalCount);
            updateUrlParams(newFilters, sort, page, limit);
        }
    }, 200);

    // Get filters and pagination info form url on intial state
    useEffect(() => {
        const queryString = window.location.search;
        const queryObject = options.disableUrlQuery ? {} : getObjectFromQueryString(queryString);
        const newPage = queryObject.page ? Number(queryObject.page) : page;
        const newLimit = queryObject.limit ? Number(queryObject.limit) : limit;
        const filterProps = R.pick(Object.keys(filters), queryObject);
        const newFilters = { ...filters, ...filterProps };
        const newSort = queryObject.sort || sort;

        setFilters(newFilters);
        setPage(newPage);
        setLimit(newLimit);
        setSort(newSort);
        fetch(newFilters, newSort, newPage, newLimit);
    }, []);

    const pageCount = Math.ceil(totalCount / limit);

    const updatePage = (pageInput: number) => {
        if (isNaN(Number(pageInput))) return;

        const newPage = pageInput > pageCount ? pageCount : Number(pageInput);

        if (newPage === page) return;

        setPage(newPage);
        fetch(filters, sort, newPage, limit);
    };

    const updateLimit = (newLimit: number) => {
        if (newLimit === limit || isNaN(newLimit)) return;

        setLimit(newLimit);

        // Only fetch new data if the new limit is larger
        if (newLimit > limit) {
            fetch(filters, sort, page, newLimit);
        }
    };

    const updateFilters = (newFilters: Filters) => {
        if (R.equals(newFilters, filters)) return;

        setFilters(newFilters);
        setPage(1);
        fetch(newFilters, sort, 1, limit);
    };

    const updateSort = (newSort: Sort) => {
        if (R.equals(newSort, sort)) return;

        setSort(newSort);
        fetch(filters, newSort, page, limit);
    };

    const fetch = (filters: Filters, sort: Sort, page: number, limit: number) => {
        setIsFetching(true);
        debouncedFetch(filters, sort, page, limit);
    };

    const updateUrlParams = (filters: Filters, sort: Sort, page: number, limit: number) => {
        if (options.disableUrlQuery) return;

        const query = buildQueryString({ ...filters, sort, page, limit });
        const newurl = window.location.href.split("?")[0] + "?" + query;

        window.history.replaceState({ path: newurl }, "", newurl);
    };

    const updateData = (updatedData: Data[]) => {
        setData(updatedData);
    };

    return {
        data,
        filters,
        sort,
        isFetching,
        page,
        limit,
        pageCount,
        totalCount,
        fetch: () => fetch(filters, sort, page, limit),
        updateData,
        setData,
        updatePage,
        updateLimit,
        updateFilters,
        updateSort,
    };
};

/**
 * Hooks for managing asynchronous options
 * @param fetchOptions - function for fetching filtered options
 * @param fetchMissingOptions - function for fetching missing options by ids
 * @param fetchImmediately - whether to immediately fetch options
 */
const getAsyncOptions = <AsyncOption extends Option>(
    fetchOptions: FetchOptions<AsyncOption>,
    fetchMissingOptions: FetchMissingOptions<AsyncOption>,
    fetchImmediately = true
) => {
    const limit = 50;

    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState<AsyncOption[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isFetching, setIsFetching] = useState(true);
    const [maxTotalCount, setMaxTotalCount] = useState(limit + 1);

    const [fetchedMissingValues, setFetchedMissingValues] = useState<string[]>([]);

    let lastRequest = 0;

    useEffect(() => {
        if (fetchImmediately) {
            fetch(searchTerm, "");
        }

        // Get max total count
        fetchOptions({ searchTerm: "", limit: 1 }).then(response => response?.totalCount && setMaxTotalCount(response.totalCount));
    }, []);

    const fetch = async (newSearchTerm: string, currentValue: string | string[]) => {
        const requestTime = +new Date();

        lastRequest = requestTime;
        setIsFetching(true);

        const response = await fetchOptions({ searchTerm: newSearchTerm, limit });

        setIsFetching(false);

        // If response is not valid or the request is not the most recent, return
        if (!response?.options || requestTime !== lastRequest) {
            return;
        }

        const newOptions = response.options;
        setTotalCount(response.totalCount);

        // Convert value(s) to array and filter out empty values
        const currentValues = (Array.isArray(currentValue) ? currentValue : [currentValue]).filter(val => val);

        // Find values not present in the current selection
        const missingValues = currentValues.filter(value => !newOptions.some(option => option.value === value)) as string[];

        // If nothing is missing, set the options from the response
        if (!missingValues.length) {
            setOptions(newOptions);
        }

        // Handle missing values
        if (currentValues.length && missingValues.length) {
            // Try finding the missing options in previous selection
            const foundOptions = options.filter(option => missingValues.some(value => value === option.value));

            // Get list of values that are still missing after checking previous selection
            const currentMissingValues = missingValues.filter(value => !foundOptions.some(option => option.value === value));

            // If all values were found, add them to the present selection
            if (!currentMissingValues.length) {
                setOptions([...foundOptions, ...newOptions]);
                return;
            }

            // If the current values are still not found, fetch values that haven't been fetched before from API
            const nonFetchedMissingValues = currentMissingValues.filter(value => !fetchedMissingValues.some(fetched => fetched === value));

            // Fetch missing options
            const fetchedMissingOptions = await fetchMissingOptions(nonFetchedMissingValues);

            // Add to array of fetched missing values
            setFetchedMissingValues([...fetchedMissingValues, ...nonFetchedMissingValues]);

            // Add missing values to options
            setOptions([...fetchedMissingOptions, ...foundOptions, ...newOptions]);
        }
    };

    const updateSearchTerm = useDebounce(async (newSearchTerm: string, currentValue: string) => {
        const isMoreSpecific = !!newSearchTerm.match("^" + searchTerm);
        const noMoreSpecificOptionsOnServer = options.length === totalCount || limit >= totalCount;

        setSearchTerm(newSearchTerm);

        // Don't do anything if the search term is more specific and there are no more specfic options on the server
        if (isMoreSpecific && noMoreSpecificOptionsOnServer) {
            return;
        }
        // Don't do anything if the max selection is smaller than the limit
        if (limit > maxTotalCount) {
            return;
        }

        fetch(newSearchTerm, currentValue);
    }, 200);

    const updateOptions = useDebounce((currentValue?: string | string[]) => fetch(searchTerm, currentValue || ""), 200);

    return { options, isFetching, totalCount, updateSearchTerm, updateOptions };
};

type CompanyOption = Option & { companyType: CompanyType };

/**
 * Hook for managing company options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useCompanyOptions = (fetchImmediately = true, filters: CompanyQuery = {}) => {
    const getOptions = async (query: FetchOptionsQuery) => {
        return CinioAPI.getCompanies({ ...filters, name: query.searchTerm, limit: query.limit })
            .then(({ data, totalCount }) => ({
                totalCount,
                options: data.map(company => ({
                    label: company.name,
                    value: company._id,
                    companyType: company.companyType,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getMissingOptions = async (ids: string[]) => {
        // @todo - add 'ids' parameter to endpoint so only one request has to be made
        const result = await Promise.all(
            ids.map(async id => {
                return CinioAPI.getCompany(id)
                    .then(company => ({
                        value: company._id,
                        label: company.name,
                        companyType: company.companyType,
                    }))
                    .catch(e => {
                        console.error(e);
                        return null;
                    });
            })
        );

        return result.filter(company => company !== null) as CompanyOption[];
    };

    const { options, isFetching, updateSearchTerm } = getAsyncOptions<CompanyOption>(getOptions, getMissingOptions, fetchImmediately);

    return {
        companyOptions: options,
        isFetchingCompanies: isFetching,
        updateCompanySearchTerm: updateSearchTerm,
    };
};

/**
 * Hook for managing studio office options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useStudioOfficeOptions = (companyId: string, fetchImmediately = false) => {
    const getOptions = async () => {
        return CinioAPI.getStudioOffices({ companyId })
            .then(({ data, totalCount }) => ({
                totalCount,
                options: data.map(studioOffice => ({
                    label: studioOffice.name,
                    value: studioOffice.id,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getMissingOptions = async (ids: string[]) => {
        const result = await Promise.all(
            ids.map(async id => {
                return CinioAPI.getStudioOffice(id)
                    .then(studioOffice => ({
                        value: studioOffice.id,
                        label: studioOffice.name,
                    }))
                    .catch(e => {
                        console.error(e);
                        return null;
                    });
            })
        );

        return result.filter(studioOffice => studioOffice !== null) as Option[];
    };

    const { options, isFetching, updateSearchTerm, updateOptions } = getAsyncOptions<Option>(getOptions, getMissingOptions, fetchImmediately);

    useEffect(() => {
        updateOptions();
    }, [companyId]);

    return {
        studioOfficeOptions: options,
        isFetchingStudioOffices: isFetching,
        updateStudioOfficeSearchTerm: updateSearchTerm,
        updateStudioOfficeOptions: updateOptions,
    };
};

/**
 * Hook for managing company options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useUserOptions = (fetchImmediately = true, filters: UserQuery = {}) => {
    const getOptions = async (query: FetchOptionsQuery) => {
        return CinioAPI.getUsers({ ...filters, name: query.searchTerm, limit: query.limit })
            .then(({ data, totalCount }) => ({
                totalCount,
                options: data.map(getOption),
            }))
            .catch(e => {
                console.error(e);
                return null;
            });
    };

    const getMissingOptions = async (ids: string[]) => {
        // @todo - add 'ids' parameter to endpoint so only one request has to be made
        const result = await Promise.all(
            ids.map(id => {
                return CinioAPI.getUser(id)
                    .then(getOption)
                    .catch(e => {
                        console.error(e);
                        return null;
                    });
            })
        );

        return result.filter(company => company !== null) as Option[];
    };

    const getOption = (user: User) => {
        const companyName = filters.companyType === "booker" ? ` (${user.companyName})` : "";

        return {
            label: `${user.firstName} ${user.lastName}${companyName}`,
            value: user.id,
        };
    };

    const { options, isFetching, updateSearchTerm, updateOptions } = getAsyncOptions(getOptions, getMissingOptions, fetchImmediately);

    return {
        userOptions: options,
        isFetchingUsers: isFetching,
        updateUserSearchTerm: updateSearchTerm,
        updateUserOptions: updateOptions,
    };
};

/**
 * Hook for managing cinema options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useCinemaOptions = (fetchImmediately = true) => {
    const getOptions = async (query: FetchOptionsQuery) => {
        return CinioAPI.getCinemas({ name: query.searchTerm, limit: query.limit })
            .then(({ data, totalCount }) => ({
                totalCount,
                options: data.map(company => ({
                    label: company.name,
                    value: company._id,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getMissingOptions = async (ids: string[]) => {
        // @todo - add 'ids' parameter to endpoint so only one request has to be made
        const result = await Promise.all(
            ids.map(id => {
                return CinioAPI.getCinema(id)
                    .then(cinema => ({
                        value: cinema._id,
                        label: cinema.name,
                    }))
                    .catch(e => {
                        console.error(e);
                        return null;
                    });
            })
        );

        return result.filter(company => company !== null) as Option[];
    };

    const { options, isFetching, updateSearchTerm } = getAsyncOptions(getOptions, getMissingOptions, fetchImmediately);

    return {
        cinemaOptions: options,
        isFetchingCinemas: isFetching,
        updateCinemaSearchTerm: updateSearchTerm,
    };
};

/**
 * Hook for managing locale options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useLocaleOptions = (fetchImmediately = true) => {
    const getOptions = async (_: FetchOptionsQuery) => {
        return CinioAPI.getLocales()
            .then(data => ({
                totalCount: data.length,
                options: data.map(locale => ({
                    label: locale.name,
                    value: locale.code,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getSelectedOption = async (_: string[]) => {
        return [] as Option[];
    };

    const { options, isFetching } = getAsyncOptions(getOptions, getSelectedOption, fetchImmediately);

    return {
        localeOptions: options,
        isFetchingLocales: isFetching,
    };
};

/**
 * Hook for managing currency options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useCurrencyOptions = (fetchImmediately = true) => {
    const getOptions = async (_: FetchOptionsQuery) => {
        return CinioAPI.getCurrencies()
            .then(data => ({
                totalCount: data.length,
                options: data.map(currency => ({
                    label: currency.code + `(${currency.symbol})`,
                    value: currency.code,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getSelectedOption = async (_: string[]) => {
        return [] as Option[];
    };

    const { options, isFetching } = getAsyncOptions(getOptions, getSelectedOption, fetchImmediately);

    return {
        currencyOptions: options,
        isFetchingCurrencies: isFetching,
    };
};

/**
 * Hook for managing currency options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useTagOptions = (fetchImmediately = true, filters: TagQuery = {}) => {
    const getOptions = async (_: FetchOptionsQuery) => {
        return CinioAPI.getTags(filters)
            .then(data => ({
                totalCount: data.length,
                options: data.map(tag => ({
                    label: tag.name,
                    value: tag.key,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getSelectedOption = async (_: string[]) => {
        return [] as Option[];
    };

    const { options, isFetching } = getAsyncOptions(getOptions, getSelectedOption, fetchImmediately);

    return {
        tagOptions: options,
        isFetchingTags: isFetching,
    };
};

/**
 * Hook for managing category options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useCategoryOptions = (fetchImmediately = true, filters: CategoryQuery = {}) => {
    const getOptions = async (_: FetchOptionsQuery) => {
        return CinioAPI.getCategories(filters)
            .then(data => ({
                totalCount: data.length,
                options: data.map(category => ({
                    label: category.name,
                    value: category.key,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getSelectedOption = async (_: string[]) => {
        return [] as Option[];
    };

    const { options, isFetching } = getAsyncOptions(getOptions, getSelectedOption, fetchImmediately);

    return {
        categoryOptions: options,
        isFetchingCategories: isFetching,
    };
};

/**
 * Hook for managing decade options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 * @param filters - filters for selection
 */
export const useDecadeOptions = (fetchImmediately = true, filters: DecadeQuery = {}) => {
    const getOptions = async (_: FetchOptionsQuery) => {
        return CinioAPI.getDecades(filters)
            .then(data => ({
                totalCount: data.length,
                options: data.map(decade => ({
                    label: decade.name,
                    value: String(decade.value),
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getSelectedOption = async (_: string[]) => {
        return [] as Option[];
    };

    const { options, isFetching } = getAsyncOptions(getOptions, getSelectedOption, fetchImmediately);

    return {
        decadeOptions: options,
        isFetchingDecades: isFetching,
    };
};

/**
 * Hook for managing company options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useCountryOptions = (fetchImmediately = true, filters?: CountryQuery) => {
    const getOptions = async (_: FetchOptionsQuery) => {
        return CinioAPI.getCountries(filters)
            .then(data => ({
                totalCount: data.length,
                options: data.map(country => ({
                    label: country.name,
                    value: country.code,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getSelectedOption = async (_: string[]) => {
        return [] as Option[];
    };

    const { options, isFetching } = getAsyncOptions(getOptions, getSelectedOption, fetchImmediately);

    return {
        countryOptions: options,
        isFetchingCountries: isFetching,
    };
};

/**
 * Hook for managing classification options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useClassificationOptions = (fetchImmediately = true) => {
    const getOptions = async (_: FetchOptionsQuery) => {
        return CinioAPI.getClassifications()
            .then(data => ({
                totalCount: data.length,
                options: data.map(classification => ({
                    label: classification.name,
                    value: classification.key,
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getSelectedOption = async (_: string[]) => {
        return [] as Option[];
    };

    const { options, isFetching } = getAsyncOptions(getOptions, getSelectedOption, fetchImmediately);

    return {
        classificationOptions: options,
        isFetchingClassifications: isFetching,
    };
};

interface CrewMemberOption extends Option {
    image: IMediaItem;
}

/**
 * Hook for managing crew member options asynchronously
 * @param fetchImmediately - whether to immediately fetch options
 */
export const useCrewMemberOptions = (fetchImmediately = true) => {
    const getOptions = async (query: FetchOptionsQuery) => {
        return CinioAPI.getCrewMembers({ name: query.searchTerm, limit: query.limit })
            .then(({ data, totalCount }) => ({
                totalCount,
                options: data.map(crewMember => ({
                    label: crewMember.name,
                    value: crewMember._id,
                    image: crewMember.image
                })),
            }))
            .catch(e => {
                console.log(e);
                return null;
            });
    };

    const getMissingOptions = async (ids: string[]) => {
        const result = await Promise.all(
            ids.map(async id => {
                return CinioAPI.getCrewMember(id)
                    .then(crewMember => ({
                        value: crewMember._id,
                        label: crewMember.name,
                        image: crewMember.image
                    }))
                    .catch(e => {
                        console.error(e);
                        return null;
                    });
            })
        );

        return result.filter(crewMember => crewMember !== null) as CrewMemberOption[];
    };

    const { options, isFetching, updateSearchTerm } = getAsyncOptions<CrewMemberOption>(getOptions, getMissingOptions, fetchImmediately);

    return {
        crewMemberOptions: options,
        isFetchingCrewMembers: isFetching,
        updateCrewMembersSearchTerm: updateSearchTerm,
    };
};

/**
 * Hook which returns debounced function
 * @param callback - function to be debounced
 * @param delay - number of ms to wait
 */
const useDebounce = <T extends (...rest: any) => any>(callback: T, delay: number): T => {
    const callbackRef = useRef<any>();

    callbackRef.current = callback;

    return useCallback(
        debounce((...args) => callbackRef.current(...args), delay),
        []
    );
};
