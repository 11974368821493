/***
 * Action types for app reducer
 */
export default {
    UPDATE_DIMENSIONS        : "APP/UPDATE_DIMENSIONS",
    UPDATE_SCROLL_TOP        : "APP/UPDATE_SCROLL_TOP",
    ADD_ALERT_MESSAGE        : "APP/ADD_ALERT_MESSAGE",
    REMOVE_ALERT_MESSAGE     : "APP/REMOVE_ALERT_MESSAGE",
    CLEAR_ALERTS             : "APP/CLEAR_ALERTS",
    TOGGLE_MOBILE_MODE       : "APP/TOGGLE_MOBILE_MODE",
    TOGGLE_TOUCH             : "APP/TOGGLE_TOUCH",
    TOGGLE_SIDEBAR_COMPONENT : "APP/TOGGLE_SIDEBAR_COMPONENT",
    TOGGLE_SIDEBAR_COLLAPSE  : "APP/TOGGLE_SIDEBAR_COLLAPSE",
};
