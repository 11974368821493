import React from "react";
import { useFormContext } from "../FormContext";
import { addToObject } from "@utils/objects";

export interface AddButtonProps {
  path: string;
  id?: string;
  text?: string;
  disabled?: boolean;
  emptyValue: any;
}

/**
 * Add button component
 */
const AddButton = (props: AddButtonProps) => {
  const { data, onChange } = useFormContext();

  const { id = "", text = "Add", path, emptyValue } = props;

  const handleAdd = () => {
    const updatedData = addToObject(data, path, emptyValue);
    onChange(updatedData);
  };

  return (
      <button
          id={id}
          className="FormButton FormButton--add"
          disabled={props.disabled}
          onClick={handleAdd}>
          {text}
      </button>
  );
};

AddButton.defaultProps = {
    isAddButton: true
};

export default AddButton;
