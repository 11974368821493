import React from "react";
import ReactDatepicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import { getNextDay } from "@src/utils/dates";

import en from "date-fns/locale/en-GB";
import nl from "date-fns/locale/nl";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import fi from "date-fns/locale/fi";
import sv from "date-fns/locale/sv";

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.scss";

type ReactDatePickerPropsWithoutOnChange = Pick<ReactDatePickerProps, Exclude<keyof ReactDatePickerProps, "onChange">>;

interface Props extends ReactDatePickerPropsWithoutOnChange {
    id?: string;
    isRange?: boolean;
    readOnly?: boolean;

    onChange?: ReactDatePickerProps["onChange"];
    onStartDateChange?: ReactDatePickerProps["onChange"];
    onEndDateChange?: ReactDatePickerProps["onChange"];
}

registerLocale("en", en);
registerLocale("nl", nl);
registerLocale("de", de);
registerLocale("fr", fr);
registerLocale("fi", fi);
registerLocale("sv", sv);

const Datepicker = (props: Props) => {
    const { isRange, readOnly } = props;

    const defaultFormat = "MMMM d, yyyy";
    const onChangeDefault = ({}: Date, {}: React.SyntheticEvent<Event, any>) => {/**/};

    const propsWithDefaults = {
        dateFormat        : defaultFormat,
        onChange          : props.onChange || onChangeDefault,
        onStartDateChange : props.onStartDateChange || onChangeDefault,
        onEndDateChange   : props.onEndDateChange || onChangeDefault,
        minDate           : new Date(),
        disabled          : readOnly,
        locale            : window.__localeId__.split("-")[0],
        ...props
    };

    return (
        <div className="Datepicker">
            {isRange
                ? <DateRange
                    {...propsWithDefaults}
                />
                : <ReactDatepicker
                    {...propsWithDefaults}
                />
            }
        </div>
    );
};

interface DateRangeProps extends ReactDatePickerProps {
    onStartDateChange: ReactDatePickerProps["onChange"];
    onEndDateChange: ReactDatePickerProps["onChange"];
}

const DateRange = (props: DateRangeProps) => {
    const { startDate, endDate, onStartDateChange, onEndDateChange, minDate } = props;

    // Display start date correctly in end date cvomponent
    const earlyStartDate = new Date(startDate || minDate || getNextDay());

    return (
        <div className="Datepicker__range">
            <ReactDatepicker
                {...props}
                selected={startDate}
                selectsStart
                onChange={onStartDateChange}
                placeholderText="Select a start date"
            />
            <ReactDatepicker
                {...props}
                selected={endDate}
                selectsEnd
                startDate={earlyStartDate}
                onChange={onEndDateChange}
                placeholderText="Select an end date"
            />
        </div>
    );
};

export default Datepicker;
