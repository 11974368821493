import React          from "react";
import i18next        from "i18next";
import { AppState }   from "@src/store/reducers";
import CartItemEditor from "../cart-item-editor/CartItemEditor";
import { Link }       from "react-router-dom";

import { CartItem, ReducerCartItem } from "@src/definitions/cart";
import { getSubtotal, getVenueNames, getVenueType, getCartItemVersion } from "@src/app/films/film-utils";
import { getDateRange, getLiveDate } from "@src/utils/dates";
import Image from '@src/components/image/Image';
import { editCartItem, removeCartItem, updateCartItem, stopEditingCartItem } from "@src/store/cart/cart-actions";
import { toggleSideBarCollapse } from "@src/store/app/app-actions";

interface Props {
    item: ReducerCartItem;
    cinemas: AppState["cinemas"]["data"];
    editCartItem: typeof editCartItem;
    removeCartItem: typeof removeCartItem;
    updateCartItem: typeof updateCartItem;
    stopEditingCartItem: typeof stopEditingCartItem;
    toggleSideBarCollapse: typeof toggleSideBarCollapse;
}

const CartItem = (props: Props) => {
    const { item, cinemas } = props;
    const { versions, versionId, startDate, endDate } = item;

    const isEvent          = item.filmType === "live-event";
    const priceIsConverted = item.lowestPrice.currency !== item.lowestPrice.originalCurrency;
    const version          = getCartItemVersion(versions, versionId);
    const price            = getSubtotal({ ...item, cinemas });
    const originalPrice    = priceIsConverted ? `(${getSubtotal({ ...item, cinemas }, true)})` : "";
    const venueType        = getVenueType(version, item.bookingPeriodType);
    const venueNames       = getVenueNames(venueType, props.cinemas, item.cinemaIds, item.auditoriumIds);
    const isEditing        = item.isBeingEdited ? "is-editing" : "";
    const editCartItem     = () => props.editCartItem(item._id);
    const removeCartItem   = () => props.removeCartItem(item._id);
    const dates            = isEvent ? getLiveDate({ startDate, endDate }) : getDateRange(startDate, endDate);
    const encore           = item.filmType === "encore" ? " (encore)" : "";
    const versionName      = version ? version.name : "";

    const statusMessage  = item.status === "pending"
        ? i18next.t("cart::Warning: Awaiting distributor's permission. Do not remove item from cart, or the request will be withdrawn.")
        : item.status;

    const editor = isEditing
        ? <CartItemEditor
                cartItem={item}
                isInCart={true}
                updateItem={props.updateCartItem}
                stopEditing={props.stopEditingCartItem}
            />
        : null;

    return (
        <article className={`CartItem ${isEditing}`}>
            <div className="CartItem__content">
                <div className="CartItem__left">
                    <Link to={`/film/${item.handle}`} className="CartItem__poster"
                        onClick={() => props.toggleSideBarCollapse(true)}>
                        <Image mediaItem={item.poster} width={150} />
                    </Link>
                </div>
                <div className="CartItem__right">
                    <h3 className="CartItem__title">
                        {item.title}{encore}
                    </h3>
                    {item.needsPermission &&
                        <p className={`CartItem__status CartItem__status--${item.status}`}>
                            {statusMessage}
                        </p>
                    }
                    <p className="CartItem__version">{versionName}</p>
                    <p className="CartItem__auditorium">
                        {venueNames}
                    </p>
                    <p className="CartItem__date">
                        {dates}
                    </p>
                    <p className="CartItem__price">
                        {price} {originalPrice}
                    </p>
                    <div className="CartItem__actions">
                        <button className="CartItem__edit"
                            onClick={editCartItem}>
                            <i className="cinicon-pencil"></i>
                            <span>{i18next.t("glossary::Edit")}</span>
                        </button>
                        <button className="CartItem__remove"
                            onClick={removeCartItem}>
                            {i18next.t("glossary::Remove")}
                        </button>
                    </div>
                </div>
            </div>
            {editor}
        </article>
    );
};

export default CartItem;
