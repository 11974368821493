import React  from "react";
import Select from "../input/Select";

import { getPoster } from "@src/components/video/video-utils";
import { IMediaItem, IMediaType } from "@src/definitions/media";
import { LOCALES } from "@src/config";

import TextInput from "../input/TextInput";
import getMediaTypeOptions from "./media-type-options";

interface Props {
    index: number;
    item: IMediaItem;
    mediaTypes: IMediaType[];
    readOnly: boolean;
    enableLocale?: boolean;
    onChange(index: number, item: IMediaItem): void;
    onRemove(index: number): void;
}

/**
 * Component for form media item
 */
const MediaItem = (props: Props) => {
    const  { index, item } = props;

    const isVideo = item.mediaType === "video" || item.mediaType === "trailer";
    const src     = isVideo ? getPoster(item) : item.src;

    const handleMediaTypeChange = (mediaType: IMediaType) => {
        const updatedItem = { ...item, mediaType };
        props.onChange(index, updatedItem);
    };

    const handleRemove = () => props.onRemove(index);
    const handleLocaleChange = (locale: IMediaItem["locale"]) => props.onChange(index, { ...item, locale });

    const mediaTypeOptions = getMediaTypeOptions(item.src, props.mediaTypes);

    const localeOptions = ["", ...LOCALES].map((locale) => ({
        label: !locale ? "All locales" : locale,
        value: locale
    }));

    return (
        <div className="FormMediaItem">
            <div className="FormMediaItem__image">
                <img src={src} alt=""/>
            </div>
            {!item.isPlaceholder &&
                <div className="FormMediaItem__options">
                        <TextInput
                            id={item.src}
                            type="text"
                            value={item.src}
                            readOnly={true}
                        />
                    {props.enableLocale &&
                        <Select
                        id={`form-media-item-locale-${index}`}
                        options={localeOptions}
                        value={item.locale}
                        onChange={handleLocaleChange}
                        />
                    }
                    {props.mediaTypes.length > 1 &&
                        <Select
                        id={`form-media-item-type-${index}`}
                        value={item.mediaType}
                        options={mediaTypeOptions}
                        onChange={handleMediaTypeChange}
                        readOnly={props.readOnly}
                        />
                    }
                </div>
            }
            {!props.readOnly &&
                <i className="FormMediaItem__remove cinicon-cross"
                    onClick={handleRemove}>
                </i>
            }
        </div>
    );
};

export default MediaItem;
