import { AnyAction } from "redux";
import { ITag } from "@definitions/tags";
import ACTIONS from "./tag-action-types";

export interface ITagState {
    data: ITag[];
    isFetching: boolean;
}

const defaultState: ITagState = {
    data: [],
    isFetching: true
};

/**
 * Reducer for logged-in user's company
 * @param state
 * @param action
 */
const companyReducer = (state: ITagState = defaultState, action: AnyAction): ITagState => {
    switch (action.type) {

        case ACTIONS.FETCH_INIT: {
            return {
                ...state,
                isFetching: true
            };
        }

        case ACTIONS.FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                isFetching: false
            };
        }

        default:
            return state;
    }
};

export default companyReducer;
