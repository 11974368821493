import React, { useState } from "react";
import { IMediaItem } from '@src/definitions/media';

import "./image.scss";
import { canUseMediaHandler, getMediaHandlerUrl } from './image-utils';

interface Props extends IAwsMediaEdits {
    mediaItem: IMediaItem;
    className?: string;
    progressiveImageLoading?: boolean;
    onClick?(): any;
}

export interface IAwsMediaEdits {
    width?: number;
    height?: number;
    fit?: "cover" |  "contain" |  "fill" |  "inside" | "outside";
}

const Image = (props: Props) => {
    const {
        mediaItem,
        progressiveImageLoading = true,
        className = "",
        onClick = () => {},
        ...options
    } = props;

    const [imageLoaded, setImageLoaded] = useState(false);
    const [placeholderLoaded, setPlaceholderLoaded] = useState(false);

    const useMediaHandler    = canUseMediaHandler(mediaItem.src);
    const isReady            = imageLoaded ? " is-ready" : "";
    const placeholderIsReady = placeholderLoaded ? " is-ready" : "";
    const placeholderOptions = { ...options, width: 10 };

    // Use top padding to ensure that the Image component is at least the height of the image to be loaded
    const paddingTop = (mediaItem.height / mediaItem.width * 100) + "%";

    const src = useMediaHandler
        ? getMediaHandlerUrl(mediaItem.src, options)
        : mediaItem.src;

    const placeholderSrc = useMediaHandler && progressiveImageLoading
        ? getMediaHandlerUrl(mediaItem.src, placeholderOptions)
        : src;

    return (
        <div className={`Image ${className}`} onClick={onClick}>
            <div style={{ paddingTop }}></div>
            <img className={`Image__placeholder ${placeholderIsReady}`}
                src={placeholderSrc}
                onLoad={() => setPlaceholderLoaded(true)}
            />
            <img className={`Image__target ${isReady}`}
                src={src}
                onLoad={() => setImageLoaded(true)}
            />
        </div>
    );
};

export default Image;
