import React     from "react";
import i18next   from "i18next";
import { Trans } from "react-i18next";
import { Link }  from "react-router-dom";

import "./cart-empty.scss";

/**
 * Placeholder for empty cart
 */
const CartEmpty = () => {
    return (
        <div className="CartEmpty">
            <p>{i18next.t("cart::Your cart is empty")}</p>
            <Trans i18nKey="cart::Browse films or live events">
                Browse <Link to="/catalog">films</Link> or <Link to="/live-events">events</Link>.
            </Trans>
        </div>
    );
}

export default CartEmpty;
