import React, { useRef } from "react";
import i18next from "i18next";
import { INotification } from "@src/definitions/notification";
import { getDate } from "@utils/dates";
import { toggleSideBarCollapse } from "@src/store/app/app-actions";

import "./notification-item.scss";

interface Props {
    notification: INotification;
    toggleSideBarCollapse: typeof toggleSideBarCollapse;
    toggleIsSeen(id: string, isSeen?: boolean): void;
    removeNotification(id: string): void;
    navigate(path: string): void;
}

/**
 * Notification item
 */
const NotificationItem = (props: Props) => {
    const { notification, toggleIsSeen, removeNotification } = props;

    const isNew = !notification.isSeen ? "is-new" : "";

    const toggleSeenRef = useRef<HTMLButtonElement>(null);
    const deleteRef = useRef<HTMLButtonElement>(null);

    const toggleSeenText = notification.isSeen
        ? i18next.t("notifications::Mark as new")
        : i18next.t("notifications::Mark as seen");

    const handleClick = (e: { target: any }) => {
        if (toggleSeenRef.current?.contains(e.target)) {
            toggleIsSeen(notification.id, !notification.isSeen);
            return;
        }
        if (deleteRef.current?.contains(e.target)) {
            removeNotification(notification.id);
            return;
        }
        toggleIsSeen(notification.id, true);
        props.navigate(notification.link);
        props.toggleSideBarCollapse(true);
    };

    return (
        <div className={`NotificationItem ${isNew}`}
            onClick={handleClick}>
            <div className="NotificationItem__thumbnail"
                style={{ backgroundImage: `url("${notification.thumbnail}")` }}></div>
            <div className="NotificationItem__data">
                <p className="NotificationItem__message">{notification.message}</p>
                <p className="NotificationItem__date">{getDate(notification.date, true)}</p>
                <div className="NotificationItem__buttons">
                    <button className="NotificationItem__button NotificationItem__button--mark"
                        ref={toggleSeenRef}>
                        {toggleSeenText}
                    </button>
                    <button className="NotificationItem__button NotificationItem__button--delete"
                        ref={deleteRef}>
                        {i18next.t("glossary::Delete")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;
