import { AnyAction } from "redux";
import { IClassification } from "@definitions/classification";
import ACTIONS from "./classification-action-types";

export interface IClassificationState {
    data: IClassification[];
    isFetching: boolean;
}

const defaultState: IClassificationState = {
    data: [],
    isFetching: true
};

/**
 * Reducer for logged-in user's classification
 * @param state
 * @param action
 */
const classificationReducer = (state: IClassificationState = defaultState, action: AnyAction) => {
    switch (action.type) {

        case ACTIONS.FETCH_INIT: {
            return {
                ...state,
                isFetching: true,
                error: ""
            };
        }

        case ACTIONS.FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }

        default:
            return state;
    }
};

export default classificationReducer;
