import React                               from "react";
import i18next                             from "i18next";
import { connect }                         from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { AppState }                        from "@store/reducers";
import { removeAlert }                     from "@store/app/app-actions";
import { getIconClass }                    from "./alert-utils";
import "./alert.scss";

interface Props extends RouteComponentProps {
    alerts: AppState["app"]["alerts"];
    removeAlert(id: string): void;
}

const Alert = (props: Props) => {
    const alert = props.alerts[0];

    if (!alert) {
        return null;
    }

    const { type, id, title, message, onClose, onConfirm, goBackOnClose } = alert;
    const icon = getIconClass(type);

    const handleClose = () => {
        props.removeAlert(id);
        onClose && onClose();
        goBackOnClose && props.history.goBack();
    };

    const handleConfirm = () => {
        onConfirm && onConfirm();
        props.removeAlert(id);
        goBackOnClose && props.history.goBack();
    }

    return (
        <div className="Alert__container">
            <div className={`Alert Alert--${type}`}>
                <i className={`Alert__icon ${icon}`}></i>
                <h2 className="Alert__title">{title || type}</h2>
                <i className="Alert__close cinicon-cross" onClick={handleClose}></i>
                <p className="Alert__content">{message}</p>
                {type === "confirm" && (
                    <div className="Alert__buttons">
                        <div className="Alert__confirmButton" onClick={handleConfirm}>{i18next.t("Confirm")}</div>
                        <div className="Alert__cancelButton" onClick={handleClose}>{i18next.t("Cancel")}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export const mapStateToProps = (state: AppState) => ({ alerts: state.app.alerts });
export const actions = { removeAlert };

export default withRouter(connect(mapStateToProps, actions)(Alert));
