import * as R from "ramda";
import React from "react";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";
import { ValueType, InputActionMeta } from "react-select";

import { Option } from "@definitions/form";

import "./select.scss";

export type SelectProps<Value extends string> = SimpleSelectProps<Value> | MultiSelectProps<Value>;

type ExtendedOption = Option & { __isNew__: boolean };

interface CommonProps {
    id?: string;
    options?: Option[];
    hasError?: boolean;
    isHidden?: boolean;
    hideValuesNotInOptions?: boolean;

    readOnly?: boolean;
    isSearchable?: boolean;
    placeholder?: string;
    isLoading?: boolean;

    onInputChange?(input: string, selectedValue: string | string[]): any;
}

interface SimpleSelectProps<Value extends string> extends CommonProps {
    value?: Value;
    isMulti?: false;
    isCreatable?: boolean;
    splitAtCommas?: false;
    onChange?(value: Value): void;
    onCreate?(label: string): Promise<Option | null>;
}

interface MultiSelectProps<Value extends string> extends CommonProps {
    isMulti: true;
    value?: Value[];
    isCreatable?: boolean;
    splitAtCommas?: boolean;
    onChange?(value: Value[]): void;
    onCreate?(label: string): Promise<Option | null>;
}

/**
 * Select component
 */
class Select<Value extends string> extends React.Component<SelectProps<Value>> {
    static defaultProps = {
        readOnly: false,
    };

    reactSelect = React.createRef<any>();

    state = {
        didForceInputChange: false
    };

    constructor(props: SelectProps<Value>) {
        super(props);
    }

    componentDidMount() {
        // Force input change on mount to trigger fetching of missing options
        // Don't trigger if data or options are loading
        if (this.props.value && !this.props.isLoading) {
            this.forceInputChange();
            console.log("On mount change", this.props.isLoading);
        }
    }

    componentDidUpdate(prevProps: SelectProps<Value>) {
        const { didForceInputChange } = this.state;
        const { isLoading, value } = this.props;

        // Force input change when value first changes
        if (!isLoading && !prevProps.value && prevProps.value !== value) {
            console.log("Value change");
            this.forceInputChange();
        }
        // Force input change if data or options stopped loading
        if (prevProps.isLoading && !isLoading && !didForceInputChange) {
            console.log("Load change");
            this.forceInputChange();
        }

    }

    forceInputChange = () => {
        const inputValue = this.reactSelect.current?.state?.inputValue || "";
        this.setState({ didForceInputChange: true });
        this.onInputChange(inputValue);
    };

    getSelectValue = () => {
        const { isMulti = false, value = "" } = this.props;

        const options = this.getOptions();

        if (isMulti && Array.isArray(value)) {
            return options.filter((option) => R.contains(option.value, value));
        }

        const singleValue = options.find((option) => option.value === String(value));
        return singleValue ? { ...singleValue, value: String(singleValue.value) } : undefined;
    };

    handleChange = async (selectValue: ValueType<Option, any>) => {
        if (!this.props.onChange) {
            return;
        }

        // Handle multi select values
        if (this.props.isMulti && Array.isArray(selectValue)) {
            const selectedOptions = selectValue as ValueType<Option, true>;

            const values = selectedOptions.map((option) =>
                this.props.splitAtCommas ? option.value.split(",").map((str) => str.trim()) : option.value.trim(),
            );

            const flattenedUniqueValues = R.uniq(R.flatten(values));
            let nonEmptyValues = flattenedUniqueValues.filter((val) => val) as Value[];

            const newValues = nonEmptyValues.filter((value) => {
                const exists = this.props.options?.find((option) => option.value === value);
                return !exists;
            });

            if (newValues.length && this.props.onCreate) {
                const { onCreate } = this.props;
                const newOptions = await Promise.all(newValues.map((value) => onCreate(value)));

                nonEmptyValues = nonEmptyValues.map((value) => {
                    const newOption = newOptions.find((option) => (option ? option.label === value : false));
                    if (newOption) {
                        return newOption.value;
                    }
                    return value;
                }) as Value[];
            }

            return this.props.onChange(nonEmptyValues);
        }

        // Handle single select values
        if (!this.props.isMulti && !Array.isArray(selectValue) && selectValue) {
            let selectedOption = selectValue as ValueType<ExtendedOption, false>;
            const isNew = selectedOption?.__isNew__;

            if (isNew && this.props.isCreatable && this.props.onCreate && selectedOption) {
                const newOption = await this.props.onCreate(selectedOption?.label);
                if (newOption) selectedOption = { ...selectedOption, ...newOption };
            }

            if (selectedOption) {
                this.props.onChange(selectedOption.value as Value);
            }
        }
    };

    getOptions = () => {
        const options = this.props.options || [];
        let valuesNotInOptions: string[] = [];

        if (this.props.hideValuesNotInOptions) {
            return options;
        }

        if (Array.isArray(this.props.value)) {
            valuesNotInOptions = this.props.value.filter((value) => !options.some((option) => option.value === value));
        } else {
            valuesNotInOptions = this.props.value && !options.some(({ value }) => value === this.props.value) ? [this.props.value] : [];
        }

        const nonEmptyOptions =  options.filter((option) => option.value);
        const newOptions = valuesNotInOptions.map((value) => ({ label: value, value }));

        return [...nonEmptyOptions, ...newOptions];
    };

    getCommonProps = () => {
        const { placeholder } = this.props;

        const options = this.getOptions();
        const hasError = this.props.hasError === true ? " has-error" : "";

        const value = this.getSelectValue();

        // If multiselect, don't close menu if there are more options left
        const closeMenuOnSelect =
            this.props.isMulti && Array.isArray(value) ? options.length - value.length <= 1 : true;

        return {
            id: this.props.id,
            value: this.getSelectValue(),
            className: "Select" + hasError,
            classNamePrefix: "Select",
            isMulti: this.props.isMulti,
            isDisabled: this.props.readOnly,
            isLoading: this.props.isLoading,
            onChange: this.handleChange,
            onInputChange: this.onInputChange,
            placeholder,
            options,
            closeMenuOnSelect,
        };
    };

    onInputChange = (newValue: string, _?: InputActionMeta) => {
        if (this.props.onInputChange) {
            this.props.onInputChange(newValue, this.props.value || "");
        }
    };

    render() {
        const props = this.getCommonProps();

        if (this.props.isHidden) {
            return null;
        }

        return this.props.isCreatable ? (
            <Creatable ref={this.reactSelect} {...props} />
        ) : (
            <ReactSelect
                ref={this.reactSelect}
                {...props}
                isSearchable={this.props.isSearchable}
                components={{
                    MultiValueContainer(props) {
                        const theme = props.data.theme || "";
                        const className = theme
                            ? props.innerProps.className + " Select__multi-value--" + theme
                            : props.innerProps.className;

                        return (
                            <div {...props.innerProps} className={className}>
                                {props.children}
                            </div>
                        );
                    },
                }}
            />
        );
    }
}

export default Select;
