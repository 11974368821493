import React         from "react";
import i18next       from "i18next";
import { connect }   from "react-redux";
import WishListItem  from "./WishListItem";
import WishListEmpty from "./WishListEmpty";
import { AppState }  from "@store/reducers";

import { editWishListItem, stopEditingWishListItem, removeWishListItem } from "@store/wish-list/wish-list-actions";
import { addCartItem } from "@src/store/cart/cart-actions";
import { addAlert, toggleSideBarCollapse } from '@src/store/app/app-actions';
import { NewCartItem } from '@src/definitions/cart';

import "./wish-list.scss";

interface Actions {
    addAlert: typeof addAlert;
    addCartItem: typeof addCartItem;
    editWishListItem: typeof editWishListItem;
    stopEditingWishListItem: typeof stopEditingWishListItem;
    removeWishListItem: typeof removeWishListItem;
    toggleSideBarCollapse: typeof toggleSideBarCollapse;
}

interface AuditoriumProps {
    cinemas: AppState["cinemas"]["data"];
}

type Props = AppState["wishList"] & AuditoriumProps & Actions;

/**
 * Component for sidebar wish list
 */
const WishList = (props: Props) => {
    const { items, cinemas, editWishListItem, stopEditingWishListItem, removeWishListItem } = props;

    const addCartItem = async (item: NewCartItem) => {
        try {
            await props.addCartItem(item);
            props.addAlert({
                type: "success",
                title: i18next.t("glossary::Success"),
                message: i18next.t("cart::The item was added to your cart.")
            });
        } catch (e) {
            props.addAlert({
                type: "error",
                title: i18next.t("errors::Could not add film to cart"),
                message: e.message
            });
        }
    };

    return (
        <div className="WishList">
            <h2 className="WishList__title">
                {i18next.t("glossary::Wish list")}
            </h2>
            {!items.length && <WishListEmpty />}
            {!!items.length && items.map((item) =>
                <WishListItem
                    key={item._id}
                    item={item}
                    cinemas={cinemas}
                    removeWishListItem={removeWishListItem}
                    editWishListItem={editWishListItem}
                    addCartItem={addCartItem}
                    stopEditingWishListItem={stopEditingWishListItem}
                    toggleSideBarCollapse={props.toggleSideBarCollapse}
                />
            )}
        </div>
    );
};

export const mapStateToProps = (state: AppState) => ({
    ...state.wishList,
    cinemas: state.cinemas.data
});

export const actions = {
    editWishListItem,
    addCartItem,
    addAlert,
    stopEditingWishListItem,
    removeWishListItem,
    toggleSideBarCollapse
};

export default connect(mapStateToProps, actions)(WishList);
