import * as R from "ramda";
import { IAwsMediaEdits } from './Image';

/**
 * Check if a url belongs to Cinio's AWS bucket
 * @param url 
 */
export const canUseMediaHandler = (url: string) => {
    return !!url.match(`/${AWS_BUCKET_NAME}/`);
};

/**
 * Get media url pointing to AWS ServerlessMediaHandler
 * @param src
 * @param edits
 */
export const getMediaHandlerUrl = (src: string, edits: IAwsMediaEdits) => {
    const hasEdits = edits.height || edits.width || edits.fit;
    
    if (!hasEdits) {
        return src;
    }

    const options =  {
        bucket : AWS_BUCKET_NAME,
        key    : getKeyFromUrl(src),
        edits  : { resize: R.pick(["width", "height", "resize"], edits) } 
    };

    return AWS_MEDIA_HANDLER_API + "/" + btoa(JSON.stringify((options)));
};

/**
 * Get AWS bucket key from url
 * @param url
 */
const getKeyFromUrl = (url: string): string => {
    const splitUrl = url.split(`/${AWS_BUCKET_NAME}/`);
    const key = R.last(splitUrl) as string;
    return key;
};
