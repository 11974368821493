import * as R from "ramda";

/**
 * Replace an existing value in object
 * @param obj - the object to be updated
 * @param pathString - path to the property to be updated, separated by dots (.)
 * @param value - the new value
 * @return - the updated object
 */
export const updateObject = (obj: object, pathString: string, value: any) => {
    const pathArray = pathString.split(".");

    const lenses = pathArray.map((val) => {
        const int = parseInt(val, 10);
        return isNaN(int)
            ? R.lensProp<any, any>(val)
            : R.lensIndex<any>(int);
    }) as any;

    const lensPath = R.apply(R.compose, lenses) as any;

    return R.set(lensPath, value, obj);
};

/**
 * Delete a value from the object
 * @param obj - the object to be updated
 * @param pathString - path to the value to be deleted, separated by dots (.)
 * @return the updated object
 */
export const deleteFromObject = (obj: object, pathString: string) => {
    const pathArray = pathString.split(".");
    const pathToArray = pathArray.slice(0, -1);
    const index = parseInt(R.last(pathArray) || "", 10);
    const array = R.pathOr([], pathToArray, obj) as string[];
    const updatedArray = array.filter(({ }, i: number) => i !== index);

    return updateObject(obj, pathToArray.join("."), updatedArray);
};

/**
 * Add a value to the object
 * @param obj - the object to be updated
 * @param pathString - path to where the value should be added, separated by dots (.)
 * @param newValue - the value to be added
 * @return the updated object
 */
export const addToObject = (obj: object, pathString: string, newValue: any) => {
    const pathToArray = pathString.split(".");
    const array = R.pathOr([], pathToArray, obj) as any[];
    const updatedArray = [...array, newValue];

    return updateObject(obj, pathString, updatedArray);
};
