/**
 * Action types for cart reducer
 */
export default {
    FETCH_INIT          : "CART/FETCH_INIT",
    FETCH_SUCCESS       : "CART/FETCH_SUCCESS",
    ADD_ITEM            : "CART/ADD_ITEM",
    UPDATE_ITEM_LOCALLY : "CART/UPDATE_ITEM_LOCALLY",
    MARK_ITEM_SAVED     : "CART/MARK_ITEM_SAVED",
    REMOVE_ITEM         : "CART/REMOVE_ITEM",
    START_EDITING_ITEM  : "CART/START_EDITING_ITEM",
    STOP_EDITING_ITEM   : "CART/STOP_EDITING_ITEM",
    RESET_ITEMS         : "CART/RESET_ITEMS"
};
