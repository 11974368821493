import React from "react";
import Swipe from "react-easy-swipe";

// @todo dynamic import
import WishList from "./wish-list/WishList";
import Cart from "./cart/Cart";
import Notifications from "./notifications/Notifications";
import Chat from "./chat/ChatList";

interface Props {
    componentId: string;
    closeComponent(): void;
    closeNavigation(): void;
}

class NavigationComponent extends React.Component<Props> {

    getComponent = () => {
        switch (this.props.componentId) {
            case "wish-list":
                return <WishList/>;

            case "cart":
                return <Cart/>;

            case "notifications":
                return <Notifications />;

            case "chat":
                return <Chat />;

            default:
                return null;
        }
    }

    render() {
        return (
            <Swipe className="Navigation__component"
                onSwipeLeft={this.props.closeNavigation}
                onSwipeRight={this.props.closeComponent}>
                {this.getComponent()}
            </Swipe>
        );
    }
}

export default NavigationComponent;
