import React            from "react";
import i18next          from "i18next";
import { Link }         from "react-router-dom";
import CartItemEditor   from "../cart-item-editor/CartItemEditor";
import { WishListItem, ReducerWishListItem } from "@definitions/wish-list";
import { ICinema }      from "@definitions/cinema";
import { getSubtotal }  from "@src/app/films/film-utils";

import Image from '@src/components/image/Image';
import { addCartItem } from "@src/store/cart/cart-actions";
import { editWishListItem, stopEditingWishListItem, removeWishListItem } from "@src/store/wish-list/wish-list-actions";

import "./wish-list-item.scss";
import { toggleSideBarCollapse } from "@src/store/app/app-actions";

interface Props {
    item: ReducerWishListItem;
    cinemas: ICinema[];
    addCartItem: typeof addCartItem;
    editWishListItem: typeof editWishListItem;
    stopEditingWishListItem: typeof stopEditingWishListItem;
    removeWishListItem: typeof removeWishListItem;
    toggleSideBarCollapse: typeof toggleSideBarCollapse;
}

/**
 * Component for wish list item
 */
const WishListItem = (props: Props) => {
    const { item, addCartItem, stopEditingWishListItem, cinemas } = props;

    const priceOptions     = { ...item, cinemas, versionId: "", venues: [] };
    const editItem         = () => props.editWishListItem(item._id);
    const removeItem       = () => props.removeWishListItem(item._id);
    const isEditing        = item.isBeingEdited ? "is-editing" : "";
    const priceIsTBD       = item.lowestPrice.priceType === "to-be-determined";
    const priceIsConverted = !priceIsTBD && item.lowestPrice.currency !== item.lowestPrice.originalCurrency;
    const priceSuffix      = priceIsConverted ? "*" : priceIsTBD ? ` (${i18next.t("films::T.B.D.")})` : "";
    const price            = getSubtotal(priceOptions) + priceSuffix;
    const originalPrice    = priceIsConverted ? `(${getSubtotal(priceOptions, true)})` : "";
    const encore           = item.filmType === "encore" ? " (encore)" : "";

    const editor = isEditing
        ? <CartItemEditor
                cartItem={item}
                isInCart={false}
                updateItem={addCartItem}
                stopEditing={stopEditingWishListItem}
            />
        : null;

    return (
        <article className={`WishListItem ${isEditing}`}>
            <div className="WishListItem__content">
                <div className="WishListItem__left">
                    <Link to={`/film/${item.handle}`} className="WishListItem__image"
                        onClick={() => props.toggleSideBarCollapse(true)}>
                        <Image mediaItem={item.poster} width={150} />
                    </Link>
                </div>
                <div className="WishListItem__right">
                    <h3 className="WishListItem__title">
                        {item.title}{encore}
                    </h3>
                    <p className="WishListItem__price">
                        {price} {originalPrice}
                    </p>
                    <div className="WishListItem__actions">
                        <button className="WishListItem__add"
                            onClick={editItem}>
                            <i className="cinicon-cart">
                                <i className="cinicon-plus"></i>
                            </i>
                            {i18next.t("cart::Add to cart")}
                        </button>
                        <button className="WishListItem__remove"
                            onClick={removeItem}>
                            {i18next.t("glossary::Remove")}
                        </button>
                    </div>
                </div>
            </div>
            {editor}
        </article>
    );
};

export default WishListItem;
