import i18next from "i18next";

import { CompanyType } from "@definitions/company";

import { getSupportLink } from "@src/utils/strings";
import apiRequest from "@utils/cinio-api";

const logOut = async () => {
    return apiRequest.post("/log-out")
        .then(() => {
            window.location.pathname = "/log-in";
        });
};

export const getUserItems = () => [
    { id: "log-out", onClick: logOut, title: i18next.t("glossary::Log out"), icon: "cinicon-off" }
];

export const getFooterItems = () => [
    { id: "blog", path: "/blog", title: i18next.t("glossary::Blog"), icon: "cinicon-news" },
    { id: "help", title: i18next.t("glossary::Help"), icon: "cinicon-help", onClick: () => window.open(getSupportLink(), "_blank") },
    { id: "management", path: "/management", title: i18next.t("glossary::Management"), icon: "cinicon-cog" }
];

export const getMenuItems = (companyType: CompanyType) => {
    const commonItems = [
        { id: "home", path: "/home", title: i18next.t("glossary::Home"), icon: "cinicon-home" },
        // { id: "chat", title: i18next.t("glossary::Chat"), icon: "cinicon-chat", component: "chat" },
    ];

    if (companyType === "super") {
        return [
            ...commonItems,
            { id: "film-catalog", path: "/film-catalog", title: i18next.t("glossary::Catalog"), icon: "cinicon-film", },
            { id: "event-catalog", path: "/event-catalog", title: i18next.t("glossary::Events"), icon: "cinicon-live", },
            { id: "orders", path: "/orders", title: i18next.t("glossary::Orders"), icon: "cinicon-order" }
        ];
    }

    if (companyType === "cinema" || companyType === "booker") {
        return [
            ...commonItems,
            { id: "film-catalog", path: "/film-catalog", title: i18next.t("glossary::Catalog"), icon: "cinicon-film", },
            { id: "event-catalog", path: "/event-catalog", title: i18next.t("glossary::Events"), icon: "cinicon-live", },
            { id: "wish-list", component: "wish-list", title: i18next.t("glossary::Wish list"), icon: "cinicon-heart" },
            { id: "cart", component: "cart", title: i18next.t("glossary::Cart"), icon: "cinicon-cart" },
            { id: "orders", path: "/orders", title: i18next.t("glossary::Orders"), icon: "cinicon-order" }
        ];
    }

    if (companyType === "vendor" || companyType === "studio") {
        return [
            ...commonItems,
            { id: "films", path: "/management/films", title: i18next.t("glossary::Films"), icon: "cinicon-film", },
            { id: "orders", path: "/orders", title: i18next.t("glossary::Orders"), icon: "cinicon-order" },
            { id: "requests", path: "/requests", title: i18next.t("glossary::Requests"), icon: "cinicon-question-circle" },
        ];
    }

    return [];
};
