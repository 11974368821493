import React, { useEffect, useRef, useState } from "react";

// import Infinity from "@assets/images/infinity.svg";
import Scrollbars from "@components/scrollbars/Scrollbars";
import { User } from "@src/definitions/user";
import { getDate } from "@src/utils/dates";
import { IChat, IChatMessage, IChatUser } from "@src/definitions/chat";
import CinioAPI from "@src/utils/cinio-api";
import { userPaginationAndFilters } from "@src/utils/hooks";

import ChatMessageComposer from "./ChatMessageComposer";

import "./chat.scss";

interface Props {
    chat: IChat;
    user: User;
    goBack(): void;
}

const Chat = (props: Props) => {
    const { chat, user } = props;

    const otherUsers = chat.users.filter(chatUser => chatUser.id !== user.id);
    const messagesEnd = useRef<HTMLDivElement>(null);
    const messagesRef = useRef<HTMLDivElement>(null);

    const [didScrollDown, setDidScrollDown] = useState(false);

    const { data: messages, isFetching, page, updateData, updatePage } = userPaginationAndFilters<IChatMessage, { chatId: string }>({
        data: chat.latestMessages,
        limit: 20,
        filters: { chatId: chat.id },
        fetch: CinioAPI.getChatMessages,
        disableUrlQuery: true,
        infiniteScroll: true,
        prependData: true,
    });

    useEffect(() => {
        if (!isFetching && !didScrollDown) {
            // @ts-ignore - the 'instant' option is functional, but typing is missing
            messagesEnd.current?.scrollIntoView({ behavior: "instant", block: "end" });
            setDidScrollDown(true);
        }
    }, [messages.length, isFetching]);

    const postMessage = async (message: string) => {
        const newMessageId = Math.random().toString();

        const messageJson: IChatMessage = {
            id: newMessageId,
            userId: user.id,
            content: message,
            messageType: "user-message",
            seenBy: [],
            date: new Date().toString(),
        };

        const updatedMessages = [...messages, messageJson];

        updateData(updatedMessages);
        // Scroll down to see the new message. Use 'setTimeout' so that state will be updated before calculating scroll distance
        setTimeout(scrollToBottom, 0);

        return CinioAPI.postChatMessage(chat.id, message)
            .then(result => {
                console.log("Message sent", result);
                // @todo handle websocket updates
                updateData(updatedMessages.map(chatMessage => (chatMessage.id === newMessageId ? result : chatMessage)));
                return true;
            })
            .catch(e => {
                console.error("Failed to sent message", e);
                return false;
            });
    };

    const scrollToBottom = () => {
        console.log("scroll");
        messagesEnd.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    };

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        console.log("scroll");
        if (e.currentTarget.scrollTop < 100 && !isFetching) {
            updatePage(page + 1);
        }
    };

    return (
        <Scrollbars>
            <div className="Chat">
                <div className="Chat__back" onClick={props.goBack}>
                    <i className="cinicon-angle-left"></i>
                    Back to all chats
                </div>
                <div className="Chat__title">{otherUsers.map(user => user.firstName).join(", ")}</div>
                {/* {chat.requestName && (
          <span className="Chat__request-name">{chat.requestName}</span>
        )} */}
                <div className="Chat__messages" onScroll={handleScroll} ref={messagesRef}>
                    <div className="Chat__messages-inner">
                        {/* {isFetching && <Infinity />} */}
                        {messages.map(message => {
                            return <Comment key={message.id} message={message} user={user} users={chat.users} />;
                        })}
                    </div>
                    <div ref={messagesEnd} />
                </div>
                <ChatMessageComposer postMessage={postMessage} />
            </div>
        </Scrollbars>
    );
};

interface CommentProps {
    message: IChatMessage;
    user: User;
    users: IChatUser[];
}

/**
 * Component for request commment
 */
const Comment = (props: CommentProps) => {
    const { user, message } = props;

    const messageUser = props.users.find(chatUser => chatUser.id === message.userId);

    const isSelf = user.id === message.userId ? " is-self" : "";
    const isAdmin = messageUser?.userType !== "user" ? " is-admin" : "";

    return (
        <div className={"ChatMessage" + isSelf + isAdmin}>
            {!isSelf && <span className="ChatMessage__user">{messageUser?.firstName}</span>}
            <div className="ChatMessage__content">{message.content}</div>
            <span className="ChatMessage__date">{getDate(message.date, true, true)}</span>
        </div>
    );
};

export default Chat;
