import React from "react";

import RichTextEditor, { RichTextEditorProps } from "./input/rich-text-editor/RichTextEditor";
import Select, { SelectProps } from "./input/Select";
import Checkbox, { CheckboxProps } from "./input/Checkbox";
import Textarea, { TextareaProps } from "./input/Textarea";
import RadioGroup, { RadioGroupProps } from "./input/RadioGroup";
import Input, { TextInputProps } from "./input/TextInput";
import Media, { MediaProps } from "./media/Media";
import DateInput, { DateProps } from "./input/DateInput";

type CommonProps = { path: string; handleBlur?(): void; };

type CheckboxFormProps       = CheckboxProps & CommonProps & { type: "checkbox" };
type DateFormProps           = DateProps & CommonProps & { type: "date" };
type SelectFormProps         = SelectProps<string> & CommonProps & { type: "select" };
type TextareaFormProps       = TextareaProps & CommonProps & { type: "textarea" };
type RadioGroupFormProps     = RadioGroupProps & CommonProps & { type: "radio" };
type MediaFormProps          = MediaProps & CommonProps & { type: "media" };
type RichTextEditorFormProps = RichTextEditorProps & CommonProps & { type: "rich-text-editor" };
type TextInputFormProps      = TextInputProps & CommonProps & { type: "text" | "password" | "number" | "email" };

export type FormInputProps = CheckboxFormProps
    | DateFormProps
    | SelectFormProps
    | TextareaFormProps
    | RadioGroupFormProps
    | MediaFormProps
    | RichTextEditorFormProps
    | TextInputFormProps;

/**
 * Form input component
 */
const FormInput = (props: FormInputProps) => {
    switch (props.type) {
        case "checkbox":
            return <Checkbox {...props} />;

        case "select":
            return <Select {...props} />;

        case "textarea":
            return <Textarea {...props} />;

        case "radio":
            return <RadioGroup {...props} />;

        case "media":
            return <Media {...props} />;

        case "date":
            return <DateInput {...props} />;

        case "rich-text-editor":
            return <RichTextEditor {...props} />;

        case "text":
        case "number":
        case "password":
        case "email":
            return <Input {...props} />;

        default:
            // @ts-ignore
            throw new Error(`${props.type} is not a valid form field type.`);
    }
};

export default FormInput;
