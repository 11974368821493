import React from "react";
import TextInput from '../form/input/TextInput';

interface Props {
    link: string;
    onChange(link: string): void;
    onSubmit(): void;
}

/**
 * Component for uploading links to media service
 */
const MediaLinkUploader = (props: Props) => {
    const { link, onChange, onSubmit } = props;

    return (
        <div className="MediaLinkUploader">
            Upload video from link
            <div className="MediaLinkUploader__inputRow">
                <TextInput
                    id="link"
                    value={link}
                    onChange={onChange}
                    type="text"
                />
                <button
                    className={""}
                    onClick={onSubmit}>
                    Submit
                </button>
            </div>
            <i className="cinicon-link"></i>
        </div>
    );
};

// /**
//  * Helper function for getting button text
//  * @param {boolean} isValid
//  * @param {boolean} isValidating
//  * @param {boolean} isValidated
//  * @return {string}
//  */
// const getButtonText = (isValid, isValidating, isValidated) => {
// 	switch(true) {
// 		case isValid:
// 			return "Upload";
// 		case isValidating:
// 			return "Validating";
// 		case isValidated:
// 			return "Not valid";
// 		default:
// 			return "Check";
// 	}
// };

// /**
//  * Helper function for getting button class
//  * @param {boolean} isValid
//  * @param {boolean} isValidating
//  * @param {boolean} isValidated
//  * @return {string}
//  */
// const getButtonClass = (isValid, isValidating, isValidated) => {
// 	switch(true) {
// 		case isValid:
// 			return "is-valid";
// 		case isValidating:
// 			return "is-loading";
// 		case isValidated:
// 			return "has-error";
// 		default:
// 			return "";
// 	}
// };

// export default connect(
// 	state => ({ link: state.media.editor.link }),
// 	{validateLink, updateLink, uploadLink}
// )(MediaLinkUploader);

export default MediaLinkUploader;
