import { AnyAction  }    from "redux";
import { ThunkDispatch } from "redux-thunk";
import CinioAPI          from "@utils/cinio-api";
import ACTIONS           from "./cinema-action-types";
import { addAlert }      from "@store/app/app-actions";

/**
 * Fetch the logged-in user's cinemas
 */
export const fetchCinemas = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction >) => {
        dispatch({ type: ACTIONS.FETCH_INIT });

        CinioAPI.getCinemas({ limit: 100 })
            .then((response) => {
                dispatch({ type: ACTIONS.FETCH_SUCCESS, payload: response.data });
            })
            .catch((e) => {
                dispatch(addAlert({
                    type: "error",
                    message: e.message
                }));
            });
    };
};
