import { AnyAction } from "redux";

import ACTIONS from "./cart-action-types";
import { CartItem, ApiCartItem, ReducerCartItem }  from "@definitions/cart";

export interface CartState {
    items: ReducerCartItem[];
    isFetching: boolean;
}

const defaultState = {
    items: [],
    isFetching: true
};

/**
 * Reducer for cart
 * @param state
 * @param action
 */
const cartReducer = (state: CartState = defaultState, action: AnyAction): CartState => {
    switch (action.type) {

        case ACTIONS.FETCH_INIT: {
            return { ...state, isFetching: true };
        }

        case ACTIONS.FETCH_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                items: action.payload.map((cartItem: ApiCartItem) => ({
                    ...cartItem,
                    isBeingEdited: false
                }))
            };
        }

        case ACTIONS.RESET_ITEMS: {
            return {
                ...state,
                items: action.payload
            };
        }

        case ACTIONS.ADD_ITEM: {
            const newItem = action.payload as CartItem;

            const reducerCartItem: ReducerCartItem = {
                ...newItem,
                isBeingEdited: false,
                isSaving: false
            };

            // @todo loop through cinemas/auditoriums
            const itemExists = state.items.find((item) =>
                item.filmId === reducerCartItem.filmId &&
                item.versionId === reducerCartItem.versionId &&
                item.startDate === reducerCartItem.startDate &&
                item.endDate === reducerCartItem.endDate
            );

            if (itemExists) {
                return state;
            }

            return {
                ...state,
                items: [...state.items, reducerCartItem]
            };
        }

        case ACTIONS.UPDATE_ITEM_LOCALLY: {
            const updatedItem: CartItem = action.payload;

            const items = state.items.map((cartItem) =>
                cartItem._id === updatedItem._id
                    ? { ...cartItem, ...updatedItem, isBeingEdited: false, isSaving: true }
                    : cartItem
            );

            return { ...state, items };
        }

        case ACTIONS.MARK_ITEM_SAVED: {
            const items = state.items.map((cartItem) =>
                cartItem._id === action.payload
                    ? { ...cartItem, isSaving: false }
                    : cartItem
            );

            return { ...state, items };
        }

        case ACTIONS.REMOVE_ITEM: {
            const id = action.payload;
            const items = state.items.filter((item) => item._id !== id);

            return { ...state, items };
        }

        case ACTIONS.START_EDITING_ITEM: {
            const items = state.items.map((cartItem) =>
                cartItem._id === action.payload
                    ? { ...cartItem, isBeingEdited: true }
                    : cartItem
            );

            return { ...state, items };
        }

        case ACTIONS.STOP_EDITING_ITEM: {
            const items = state.items.map((cartItem) =>
                cartItem._id === action.payload
                    ? { ...cartItem, isBeingEdited: false }
                    : cartItem
            );

            return { ...state, items };
        }

        default:
            return state;
    }
};

export default cartReducer;
