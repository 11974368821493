/**
 * Action types for user reducer
 */
export default {
    LOG_IN_INIT: "LOG_IN_INIT",
    LOG_IN_SUCCESS: "LOG_IN_SUCCESS",
    LOG_IN_ERROR: "LOG_IN_ERROR",
    FETCH_ERROR: "FETCH_ERROR",
    UPDATE_DATA: "UPDATE_DATA"
};
