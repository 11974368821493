import A                      from "./toast-message-action-types";
import { ThunkDispatch }      from "redux-thunk";
import { AnyAction }          from "redux";
import { INotification }      from "@src/definitions/notification";
import { removeNotification } from "../notifications/notification-actions";
import { IToastMessage }      from "@src/definitions/toast-message";
import { AppState } from "../reducers";

/**
 * Create toast message from notification
 * @param notification - notificaiton
 */
export const addToastMessage = (notification: INotification) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) => {
        const toastMessage: IToastMessage = { ...notification, isOpen: false };
        let timeout: number;

        // Add toast message
        dispatch({ type: A.ADD_ONE, payload: toastMessage });

        // Remove after 5 seconds
        timeout = Number(setTimeout(() => {
            const currentToast = getState().toastMessages.find((toast) => toast.id === notification.id);

            if (currentToast && !currentToast.isOpen) {
                dispatch({ type: A.REMOVE_ONE, payload: notification.id });
            }

            clearTimeout(timeout);
        }, 5000));
    };
};

/**
 * Remove toast mesasge
 * @param id - notification id
 */
export const removeToastMessage = (id: string) => ({
    type: A.REMOVE_ONE,
    payload: id
});

/**
 * Open a toast mesasge
 * @param id - notification id
 */
export const openToastMessage = (id: string) => ({
    type: A.OPEN_ONE,
    payload: id
});

/**
 * Remove toast message and delete notifications
 * @param id - notificaiton id
 */
export const deleteNotification = (id: string) => {
    return (dispatch: ThunkDispatch<any, {}, AnyAction>) => {
        dispatch({ type: A.REMOVE_ONE, payload: id });
        dispatch(removeNotification(id));
    };
};

/**
 * Remove toast message and mark notification as seen
 * @param id - notification id
 */
export const toggleIsSeen = (id: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: A.REMOVE_ONE, payload: id });
        dispatch(toggleIsSeen(id));
    };
};
