import { IAlertType } from "@src/definitions/alert";

/**
 * Get icon class name by alert time
 * @param type - alert type
 */
export const getIconClass = (type: IAlertType) => {
    switch(type) {
        case "error":
            return "cinicon-error";
        case "success":
            return "cinicon-check";
        case "confirm":
            return "cinicon-question-circle";
    }
};
