import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";

import i18next from "i18next";
import i18nextBackend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

import App from "./app/App";
import reducers from "@store/reducers";
import { LOCALES } from "./config";

const store = createStore(
    reducers,
    applyMiddleware(thunk)
);

// Initialize i18next internationalization
i18next
.use(initReactI18next)
.use(i18nextBackend)
.init({
    lng: "en",
    whitelist: LOCALES,
    load: "all",
    nonExplicitWhitelist: true,
    fallbackLng: {
        "sv-SE": ["sv-FI"],
        "sv-FI": ["sv-SE"],
        "default": ["en"]
    },
    returnEmptyString: false,
    saveMissing: true,
    interpolation: {
        escapeValue: false
    },
    nsSeparator: "::",
    keySeparator: false,
    backend: {
        projectId: LOCIZE_PROJECT_ID,
        apiKey: LOCIZE_API_KEY,
        referenceLng: LOCIZE_REFERENCE_LANGUAGE,
        allowedAddOrUpdateHosts: ["localhost", "stage.cinio.app"],
    },
    ns: [
        "auth",
        "blog",
        "cart",
        "checkout",
        "cookies",
        "companies",
        "errors",
        "films",
        "forms",
        "glossary",
        "home",
        "notifications",
        "orders",
        "translation",
        "users",
        "vendor-dashboard",
        "wish-list"
    ]
});

ReactDOM.render(
    React.createElement(Provider, { store },
        React.createElement(BrowserRouter, null,
            React.createElement(App)
        )
    ),
    document.getElementById("root")
);
