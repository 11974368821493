import { combineReducers } from "redux";

import app,             { AppReducerState }      from "./app/app-reducer";
import cart,            { CartState }            from "./cart/cart-reducer";
import company,         { CompanyState }         from "./company/company-reducer";
import notifications,   { NotificationState }    from "./notifications/notification-reducer";
import user,            { UserState }            from "./user/user-reducer";
import wishList,        { WishListState }        from "./wish-list/wish-list-reducer";
import cinemas,         { ICinemaState }         from "./cinemas/cinema-reducer";
import toastMessages,   { ToastMessageState }    from "./toast-messages/toast-message-reducer";
import classifications, { IClassificationState } from "./classifications/classification-reducer";
import tags,            { ITagState }            from "./tags/tag-reducer";

export interface AppState {
    // Common
    app: AppReducerState;
    company: CompanyState;
    user: UserState;
    notifications: NotificationState;
    toastMessages: ToastMessageState;
    tags: ITagState;

    // For cinemas and bookers only
    cinemas: ICinemaState;
    cart: CartState;
    wishList: WishListState;
    classifications: IClassificationState;
}

const reducers = combineReducers({
    app,
    company,
    user,
    notifications,
    toastMessages,
    tags,
    cinemas,
    cart,
    wishList,
    classifications
});

export default reducers;
