import A from "./toast-message-action-types";
import { IToastMessage } from "@src/definitions/toast-message";
import { AnyAction } from "redux";

export type ToastMessageState = IToastMessage[];

const defaultState: ToastMessageState = [];

/**
 * Reducer for toast messages
 * @param state - state
 * @param action -action
 */
const toastMessageReducer = (state: ToastMessageState = defaultState, action: AnyAction): ToastMessageState => {
    switch (action.type) {

        case A.ADD_ONE: {
            return [action.payload, ...state];
        }

        case A.REMOVE_ONE: {
            return state.filter((message) => message.id !== action.payload);
        }

        case A.OPEN_ONE: {
            return state.map((message) => message.id === action.payload
                ? { ...message, isOpen: true }
                : message
            );
        }

        default:
            return state;
    }
};

export default toastMessageReducer;
