import { format } from "date-fns";
import { enGB, nl, de, fr, fi, sv } from "date-fns/locale";
import { capitalize } from "./strings";

const FORMAT_DAY       = "d MMMM yyyy";
const FORMAT_DAY_SHORT = "d MMM";
const FORMAT_TIME      = "HH:mm";
const CET_TIME_ZONE    = "Europe/Berlin";

const locales = { en: enGB, nl, de, fr, fi, sv };

interface DateObject {
    startDate: Date;
    endDate: Date;
}

/**
 * Check if a value is a valid date
 * @param value - the value to check
 */
export const isValidDate = (value: any) => {
    return value instanceof Date && !isNaN(+value);
};

/**
 * Get date for tomrrow
 */
export const getNextDay = (date?: Date) => {
    const tomorrow = date ? new Date(date) : new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
};

/**
 * Get day count (Monday - Monday = 1 day, Monday - Tuesday = 2 days)
 * @param startDate - start date
 * @param endDate - end date
 */
export const getDayCount = (startDate: Date | null, endDate: Date | string | null) => {
    if (!startDate || !endDate) {
        return 1;
    }

    startDate = getDateWithoutTime(startDate);
    endDate = getDateWithoutTime(endDate);

    const difference = Number(endDate) - Number(startDate);
    const oneDay     = 1000 * 60 * 60 * 24;
    const result     =  Math.round(difference / oneDay) + 1;

    return result <= 0 || isNaN(result)
        ? 1
        : result;
};

/**
 * Get a single date
 * @param date - date
 * @param getTime - whether to also get the time
 */
export const getDate = (date: Date | string, getTime = false, shortFormat = false) => {
    const dayFormat = shortFormat ? FORMAT_DAY_SHORT : FORMAT_DAY;
    const dateFormat = getTime ? dayFormat + " " + FORMAT_TIME : dayFormat;
    const dateObj = new Date(date);

    if (!date) {
        return "No date";
    }

    return format(dateObj, dateFormat, getLocale());
};

export const getDayName = (date: Date | string) => {
    const dateObj = new Date(date);

    if (!date) {
        return "";
    }

    return capitalize(format(dateObj, "iiii", getLocale()));
};

/**
 * Get time from date
 * @param date - date as date object or string
 */
export const getTime = (date: Date) => {
    const dateObj = new Date(date);

    if (!dateObj) {
        return "00:00";
    }

    return format(dateObj, FORMAT_TIME, getLocale());
};

/**
 * Get date converted to CET string, e.g. 12:00 CET or 12:00 CEST
 * @param date - date to convert
 */
export const getCetTime = (date: Date) => {
    const cetTimeString = new Date(date).toLocaleString("en-US", { timeZone: CET_TIME_ZONE });
    const cetTime       = new Date(cetTimeString);

    return format(cetTime, FORMAT_TIME, getLocale());
};

/**
 * Get date range
 * @param startDate - start date
 * @param endDate - end date
 */
export const getDateRange = (startDate: Date | null, endDate: Date | null, getTime = false) => {
    if (!startDate || !endDate) {
        return "No date";
    }

    const startDateWithoutTime = getDateWithoutTime(startDate);
    const endDateWithoutTime = getDateWithoutTime(endDate);

    if (Number(startDateWithoutTime) === Number(endDateWithoutTime) && !getTime) {
        return getDate(startDate, getTime);
    }

    return getDate(startDate, getTime) + " - " + getDate(endDate, getTime);
};

/**
 * Get cloest previous Monday
 * @param date - date
 */
export const getPreviousMonday = (date: Date) => {
    const dateCopy   = new Date(date);
    const dayOfMonth = dateCopy.getDate() - (dateCopy.getDay() + 6) % 7;

    if (date.getDay() === 1) {
        return date;
    }

    dateCopy.setDate(dayOfMonth);

    return dateCopy;
};

/**
 * Get closest next sunday
 * @param date - date
 */
export const getNextSunday = (date: Date) => {
    const dateCopy = new Date(date);
    const dayOfMonth = dateCopy.getDate() + (7 - dateCopy.getDay()) % 7;

    dateCopy.setDate(dayOfMonth);

    return dateCopy;
};

/**
 * Get live date
 * @param liveDate - live date object
 */
export const getLiveDate = (liveDate?: DateObject) => {
    if (!liveDate) {
        return "Invalid date";
    }

    const date = capitalize(format(new Date(liveDate.startDate), "iiii d MMM yyyy"));
    const cetTime = getLiveDateTime(liveDate);

    return `${date}, ${cetTime}`;
};

/**
 * Get live date time in CET
 * @param liveDate - live date object
 */
export const getLiveDateTime = (liveDate: DateObject) => {
    const startTime = getCetTime(liveDate.startDate);
    const endTime   = getCetTime(liveDate.endDate);
    const timeZone  = getCetTimeZoneName(liveDate.startDate);

    return `${startTime} - ${endTime} ${timeZone}`;
};

/**
 * Get min date for live event
 * @param liveDate - live date object
 */
export const getMinDate = (liveDate: DateObject) => {
    const endDate  = getDateWithoutTime(liveDate.endDate);
    const tomorrow = getDateWithoutTime(getNextDay(new Date()));

    return endDate > tomorrow
        ? getDateAfterDays(endDate, 1)
        : tomorrow;
};

/**
 * Get date with time set to 00:00:00:00
 */
export const getDateWithoutTime = (date: Date | string) => {
    const dateCopy = new Date(date);
    return new Date(dateCopy.setHours(0, 0, 0, 0));
};

/**
 * Sort an array of objects by date, newest first by default
 * @param objects - objects to be sorted
 * @param dateProperty - property where date is stored
 * @param oldestFirst - whether to sort by oldest first
 */
export const sortByDate = (objects: any[], dateProperty: string, oldestFirst = false) => {
    const newestFirst = objects.sort((a, b) => {
        const dateA = +new Date(a[dateProperty]);
        const dateB = +new Date(b[dateProperty]);

        return dateB - dateA;
    });

    return oldestFirst
        ? newestFirst.reverse()
        : newestFirst;
};

/**
 * Get date after x number of days
 * @param startDate - date to count from
 * @param daysAfter - number of days to add
 */
export const getDateAfterDays = (startDate: Date | string, daysAfter: number) => {
    const endDate = startDate ? new Date(startDate) : new Date();
    endDate.setDate(endDate.getDate() + daysAfter);
    return endDate;
};

/**
 * Get date after x number of days
 * @param startDate - date to count from
 * @param daysAfter - number of days to add
 */
export const getDateDaysBefore = (startDate: Date | string, daysBefore: number) => {
    const endDate = startDate ? new Date(startDate) : new Date();
    endDate.setDate(endDate.getDate() - daysBefore);
    return endDate;
};

/**
 * Get name of CET timezone, depending on if daylight saving time is in effect
 * @param cetDate - date in CET
 */
const getCetTimeZoneName = (date: Date) => {
    const cetTimeString = new Date(date).toLocaleString("en-US", { timeZone: CET_TIME_ZONE });
    const cetDate       = new Date(cetTimeString);

    return dstIsInEffect(cetDate) ? "CEST" : "CET";
};

/**
 * Check if daylight savings time is in effect for a given date
 * See https://stackoverflow.com/a/11888430 for explanation of the code
 * @param date - the date to be checked
 */
const dstIsInEffect = (date: Date) => {
    const jan = new Date(date.getFullYear(), 0, 1);
    const jul = new Date(date.getFullYear(), 6, 1);
    const standardTimeOffset =  Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    return date.getTimezoneOffset() < standardTimeOffset;
};

/**
 * Get dateFns locale object
 */
const getLocale = () => {
    const localeId = window.__localeId__ || "en";
    const localeSimplified = localeId.split("-")[0];
    const locale = locales[localeSimplified] || locales.en;

    return { locale };
};
