import React from "react";

interface Props {
    label?: string;
    children: React.ReactNode;
}

const FormGroup = (props: Props) => {
    return (
        <div className="FormGroup">
            <div className="FormGroup__left">
                <label htmlFor="">{props.label}</label>
            </div>
            <div className="FormGroup__right">
                {props.children}
            </div>
        </div>
    );
};

export default FormGroup;
