import React from "react";
import i18next from "i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";

import { AppState } from "@src/store/reducers";
import { toggleIsSeen, removeNotification } from "@src/store/notifications/notification-actions";
import { toggleSideBarCollapse } from "@src/store/app/app-actions";
import { INotification } from "@src/definitions/notification";

import Scrollbars from "@components/scrollbars/Scrollbars";
import NotificationItem  from "./NotificationItem";

import "./notifications.scss";

interface Props extends RouteComponentProps {
    notifications: INotification[];
    toggleSideBarCollapse: typeof toggleSideBarCollapse;
    toggleIsSeen(id: string): void;
    removeNotification(id: string): void;
}

const Notifications = (props: Props) => {
    const { notifications, ...actions } = props;

    return (
        <Scrollbars>
            <div className="Notifications">
                <h2 className="Notifications__title">
                    {i18next.t("glossary::Notifications")}
                </h2>
                <div className="Notifications__list">
                    {props.notifications.map((notification) =>
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            navigate={props.history.push}
                            {...actions}
                        />
                    )}
                </div>
            </div>
        </Scrollbars>
    );
};

const mapStateToProps = (state: AppState) => ({
    notifications: state.notifications.items
});

const actions = { toggleIsSeen, removeNotification, toggleSideBarCollapse };

export default withRouter(connect(mapStateToProps, actions)(Notifications));
