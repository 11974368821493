import * as R      from "ramda";
import React       from "react";
import { connect } from "react-redux";
import i18next     from "i18next";
import CinioAPI    from "@src/utils/cinio-api";

import { fetchUser } from "@store/user/user-actions";
import { withRouter, RouteComponentProps } from "react-router";
import { addAlert, AddAlert } from "@store/app/app-actions";
import Form, { FormField, SubmitButton } from "@components/form/Form";

import "./password-recovery.scss";

interface Props extends RouteComponentProps {
    addAlert: AddAlert;
    fetchUser(): void;
}

interface State {
    password: string;
    token: string;
    tokenIsInvalid: boolean;
}

class NewPasswordForm extends React.Component<Props, State> {

    state: State = {
        password: "",
        token: "",
        tokenIsInvalid: false
    };

    componentDidMount() {
        const token = R.pathOr("", [1], this.props.location.search.match(/\?token=(.*)($|&)/) as string[]) as string;
        this.setState({ token }, this.validateToken);

        document.addEventListener("keyup", this.handleKeyup);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.handleKeyup);
    }

    validateToken = async () => {
        try {
            await CinioAPI.get(`validate-token/${this.state.token}`);
        } catch (e) {
            this.setState({ tokenIsInvalid: true });
            this.props.addAlert({
                type: "error",
                title: i18next.t("auth::Invalid token"),
                message: e.message,
                onClose: () => window.location.pathname = "/log-in"
            });
        }
    }

    handleKeyup = (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
            this.handleSubmit();
        }
    }

    handleChange = (updateState: State) => {
        this.setState(updateState);
    }

    handleSubmit = async () => {
        const { password, token } = this.state;

        if (!password) {
            return false;
        }

        try {
            await CinioAPI.post("/reset-password", { password, token });
            window.location.pathname = "/home";
            return true;
        } catch (e) {
            this.props.addAlert({
                type: "error",
                message: e.message
            });
            return false;
        }
    }

    render() {
        if (this.state.tokenIsInvalid) {
            return null;
        }

        return (
            <Form
                data={this.state}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}>
                <div className="password-recovery__title">
                    {i18next.t("auth::Password Reset")}
                </div>
                <h2 className="password-recovery__info">
                    {i18next.t("auth::Please enter your new password.")}
                </h2>
                <FormField
                    className="FormField__input"
                    path="password"
                    label="password-recovery"
                    type="password"
                    placeholder={i18next.t("users::New password")}
                />
                <SubmitButton
                    text="Submit"
                />
            </Form>
        );
    }
}

const actions = { addAlert, fetchUser };

export default withRouter(connect(null, actions)(NewPasswordForm));
