/**
 * Debounce function
 * @param func - function to be debounced
 * @param wait - time to wait in ms
 * @param immediate - whether to fire immediately or not
 */
export const debounce = (callback: (...args: any[]) => void, wait: number): any => {
    let timeoutId: number | undefined;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            timeoutId = undefined;
            callback(...args);
        }, wait);
    };
};
