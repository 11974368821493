import React from "react";
import { connect } from "react-redux";
import i18next from "i18next";

import { RouteComponentProps, withRouter } from "react-router";

import LoadingScreen from "./loading-screen/LoadingScreen";
import LoginScreen from "./login-screen/LoginScreen";
import Navigation from "./navigation/Navigation";
import Routes from "./Routes";
import Alert from "./alert/Alert";
import ToastMessages from "./toast-messages/ToastMessages";
import ConsentManager from "./consent-manager/ConsentManager";

import { fetchCinemas } from "@src/store/cinemas/cinema-actions";
import { fetchTags } from "@src/store/tags/tag-actions";
import { fetchCompany } from "@store/company/company-actions";
import { fetchUser } from "@store/user/user-actions";
import { fetchWishList } from "@store/wish-list/wish-list-actions";
import { fetchCart } from "@store/cart/cart-actions";
import { fetchNotifications } from "@store/notifications/notification-actions";
import { AppState } from "@store/reducers";
import { isMobile, hasTouch } from "@utils/device-detection";

import {
    toggleMobileMode,
    toggleTouch,
    toggleSideBarCollapse,
    toggleSidebarComponent,
    clearAlerts
} from "@store/app/app-actions";

import "normalize.css";
import "@styles/basis.scss";
import "./app.scss";

interface Props extends RouteComponentProps {
    user: AppState["user"];
    companyType: AppState["company"]["data"]["companyType"];
    darkMode: boolean;
    alerts: AppState["app"]["alerts"];
    activeSidebarComponent: string;
    sidebarCollapsed: boolean;
    isMobile: boolean;
    hasTouch: boolean;
    fetchUser(): Promise<void>;
    fetchCompany(): Promise<void>;
    fetchNotifications(): void;
    fetchCart(): void;
    fetchTags(): void;
    fetchCinemas(): void;
    fetchWishList(): void;
    toggleMobileMode(isMobile: boolean): void;
    toggleTouch(isMobile: boolean): void;
    toggleSideBarCollapse(isCollapsed: boolean): void;
    toggleSidebarComponent(id: string, props?: GenericObject): void;
    clearAlerts(): void;
}

interface State {
    darkMode: boolean;
}

/**
 * Main component for Cinio application
 */
class App extends React.Component<Props, State> {

    app = React.createRef<HTMLDivElement>();
    previousLocation = this.props.location;

    state = {
        darkMode: false
    };

    componentDidMount() {
        this.fetchStoreContent();
        this.handleResize();
        document.addEventListener("resize", this.handleResize);
    }

    componentWillUpdate(nextProps: Props) {
        const { location } = this.props;

        // set previousLocation if props.location is not modal
        if (nextProps.history.action !== "POP" && (!location.state || !location.state.modal)) {
            this.previousLocation = this.props.location;
        }
    }

    componentDidUpdate(prevProps: Props) {
        const wasModal = prevProps.location.state && prevProps.location.state.modal;
        const updatedLocation = prevProps.location.pathname !== this.props.location.pathname;

        if (updatedLocation) {
            this.props.clearAlerts();
        }

        if (updatedLocation && this.app.current && !this.isModal() && !wasModal) {
            // Scroll to top on page change
            this.app.current.scrollTo(0, 0);
        }
    }

    isModal = () => {
        const { location } = this.props.history;

        return !!(
            location.state &&
            location.state.modal &&
            this.previousLocation.pathname !== location.pathname
        );
    }

    fetchStoreContent = async () => {
        // If fetching the user fails, it means that the user is not logged in.
        try {
            await this.props.fetchUser();
            await i18next.changeLanguage(this.props.user.data.locale);
            window.__localeId__ = this.props.user.data.locale;
        } catch (e) {
            return;
        }

        await this.props.fetchCompany();
        this.props.fetchNotifications();
        this.props.fetchTags();

        if (this.props.companyType === "cinema" || this.props.companyType === "booker") {
            this.props.fetchWishList();
            this.props.fetchCart();
            this.props.fetchCinemas();
        }
    }

    handleResize = () => {
        this.props.toggleTouch(hasTouch());
        this.props.toggleMobileMode(isMobile());
    }

    expandSidebar = () => {
        if (this.props.sidebarCollapsed) {
            this.props.toggleSideBarCollapse(false);
        }
    }

    collapseSidebar = () => {
        if (!this.props.sidebarCollapsed) {
            this.props.toggleSideBarCollapse(true);
        }
    }

    toggleSidebarExpand = (clickedItem: string) => {
        this.props.toggleSidebarComponent(clickedItem);
    }

    toggleDarkMode = (darkMode: boolean) => {
        this.setState({ darkMode });
    }

    render() {
        const darkMode         = this.state.darkMode ? "App--theme-dark" : "";
        const sidebarExpanded  = this.props.activeSidebarComponent ? "sidebar-expanded" : "";
        const sidebarCollapsed = !sidebarExpanded && this.props.sidebarCollapsed ? "" : "sidebar-open";

        if (!this.props.user.isFetching && !this.props.user.data.id) {
            return <LoginScreen pathname={this.props.history.location.pathname} alerts={this.props.alerts} />;
        }

        if ((!this.props.user.data.id && this.props.user.isFetching) || !this.props.companyType) {
            return <LoadingScreen/>;
        }

        return (
            <div ref={this.app} className={`App ${sidebarCollapsed} ${darkMode} ${sidebarExpanded}`}>
                <Navigation
                    currentPath={this.props.location.pathname}
                    onExpand={this.expandSidebar}
                    onCollapse={this.collapseSidebar}
                    toggleSidebarExpand={this.toggleSidebarExpand}
                    activeComponent={this.props.activeSidebarComponent}
                    isMobile={this.props.isMobile}
                    hasTouch={this.props.hasTouch}
                    isCollapsed={this.props.sidebarCollapsed}
                />
                <Routes
                    key="routes"
                    companyType={this.props.companyType}
                    isModal={this.isModal()}
                    previousLocation={this.previousLocation}
                    toggleDarkMode={this.toggleDarkMode}
                />
                {!!this.props.alerts.length && <Alert />}
                <ToastMessages />
                <ConsentManager companyType={this.props.companyType} userType={this.props.user.data.type} />
            </div>
        );
    }
}

export const mapStateToProps = (state: AppState) => ({
    user                   : state.user,
    companyType            : state.company.data.companyType,
    alerts                 : state.app.alerts,
    sidebarCollapsed       : state.app.sidebarCollapsed,
    activeSidebarComponent : state.app.activeSidebarComponent,
    isMobile               : state.app.isMobile,
    hasTouch               : state.app.hasTouch,
});

export const actions = {
    fetchUser,
    fetchCompany,
    fetchWishList,
    fetchCart,
    fetchCinemas,
    fetchTags,
    fetchNotifications,
    toggleMobileMode,
    toggleTouch,
    toggleSideBarCollapse,
    toggleSidebarComponent,
    clearAlerts
};

export default withRouter(connect(mapStateToProps, actions)(App));
