import { AnyAction } from "redux";
import ACTIONS from "./user-action-types";
import { User } from "@definitions/user";
import { MEDIA_ITEM_DEFAULT } from "@utils/defaults";

export interface UserState {
    data: User;
    isFetching: boolean;
    error: string;
}

const defaultState: UserState = {
    data: {
        id: "",
        username: "",
        firstName: "",
        lastName: "",
        avatar: MEDIA_ITEM_DEFAULT,
        locale: "en",
        type: "user",
        companyId: "",
        studioOfficeId: "",
        companyName: "",
        deleted: false,
        regionalRestrictions: []
    },
    isFetching: true,
    error: ""
};

/**
 * Reducer for logged-in user
 * @param state
 * @param action
 */
const userReducer = (state: UserState = defaultState, action: AnyAction) => {
    switch (action.type) {

        case ACTIONS.LOG_IN_INIT: {
            return {
                ...state,
                isFetching: true
            };
        }

        case ACTIONS.LOG_IN_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                data: {
                    ...state.data,
                    ...action.payload
                }
            };
        }

        case ACTIONS.LOG_IN_ERROR: {
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        }

        case ACTIONS.FETCH_ERROR: {
            return {
                ...state,
                isFetching: false
            };
        }

        case ACTIONS.UPDATE_DATA: {
            return {
                ...state,
                data: action.payload
            };
        }

        default:
            return state;
    }
};

export default userReducer;
