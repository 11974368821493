import { round } from "./numbers";
import i18next from "i18next";

import { CurrencyCode, Price } from "@definitions/price";
import { BookingPeriodType } from "@definitions/cart";
import { IScreeningPriceType, IScreeningPrice } from "@definitions/film";

import { capitalize } from "./strings";

interface PriceFormatOptions {
    displayPriceType?: boolean;
    displayOriginalPrice?: boolean;
    hideBookingPeriodType?: boolean;
    getFullValue?: boolean;
    hideAsterix?: boolean;
}

/**
 * Get formatted price string
 * @param price - price object
 * @param value - number value
 */
export const getFormattedPriceString = (price: Price | IScreeningPrice | null, options: PriceFormatOptions = {}) => {
    if (!price) return "";

    const { currency, originalCurrency } = price;
    const priceType = "priceType" in price ? price.priceType : "";

    const {
        displayPriceType = false,
        displayOriginalPrice = false,
        getFullValue = false,
        hideAsterix = false,
        hideBookingPeriodType = false
    } = options;

    const value             = getFullValue ? price.fullValue : price.value;
    const originalValue     = getFullValue ? price.originalFullValue : price.originalValue;
    const targetPrice       = getCurrencySymbolAndValue(currency, value);
    const originalPrice     = getCurrencySymbolAndValue(originalCurrency, originalValue);
    const isConverted       = currency !== originalCurrency && value !== 0;
    const priceTypeName     = displayPriceType && priceType ? " " + getPriceTypeName(priceType, false, hideBookingPeriodType) : "";
    const asteriks          = isConverted && !hideAsterix ? "*" : "";

    if ("priceType" in price && priceType === "min-guarantee") {
        const value = getCurrencySymbolAndValue(currency, price.minGuarantee) + asteriks + " / " + price.boxOfficePercentage + "%";

        if (displayPriceType) {
            return i18next.t("films::Min. guarantee ") + value;
        }

        return value;
    }

    if (priceType === "to-be-determined") {
        return i18next.t("films::Price T.B.D.");
    }

    if (isConverted && displayOriginalPrice) {
        return `${targetPrice}* (${originalPrice})${priceTypeName}`;
    }

    return targetPrice + asteriks + priceTypeName;
};

/**
 * Get price type name
 * @param priceType - price type
 * @param getStandAloneVersion - get standalone version with complete name and capitalized first letter
 */
export const getPriceTypeName = (priceType: IScreeningPriceType, getStandAloneVersion = false, hideBookingPeriodType = false) => {
    let name = "";

    switch (priceType) {
        case "fixed":
            name = hideBookingPeriodType ? "" : i18next.t("cart::per day");
            break;
        case "to-be-determined":
        case "min-guarantee":
            name = getStandAloneVersion && !hideBookingPeriodType ? i18next.t("cart::per day") : "";
            break;
        case "per-seat":
            name = getStandAloneVersion && !hideBookingPeriodType ? i18next.t("cart::Per seat per day") : i18next.t("cart::per seat");
            break;
        case "fixed-weekly":
            name = hideBookingPeriodType ? "" : i18next.t("cart::per week");
            break;
        case "per-seat-weekly":
            name = hideBookingPeriodType ? "" : i18next.t("cart::per seat per week");
            break;
        case "fixed-monthly":
            name = hideBookingPeriodType ? "" : i18next.t("cart::per month");
            break;
        case "per-seat-monthly":
            name = hideBookingPeriodType ? "" : i18next.t("cart::per seat per month");
            break;
    }

    return getStandAloneVersion
        ? capitalize(name)
        : name;
};

/**
 * Get booking period type name
 * @param bookingPeriodType - price type
 * @param getStandAloneVersion - get standalone version with complete name and capitalized first letter
 */
export const getBookingPeriodTypeName = (bookingPeriodType: BookingPeriodType) => {
    switch (bookingPeriodType) {
        case "day":
            return i18next.t("cart::per day");
            break;
        case "week":
            return i18next.t("cart::per week");
            break;
        case "month":
            return i18next.t("cart::per month");
        default:
            return "";
    }
};

export const getCurrencySymbolAndValue = (currency: CurrencyCode, value: number) => {
    const currencySymbol = getCurrencySymbol(currency);
    const formattedValue = round(value, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Put EUR and GBP symbols before value
    if (currency === "EUR" || currency === "GBP") {
        return `${currencySymbol} ${formattedValue}`;
    }

    return `${formattedValue} ${currencySymbol}`;
};

/*
* Get currency symbol
* @param currency - currency code
*/
export const getCurrencySymbol = (currency: CurrencyCode) => {
    switch (currency) {
        case "EUR":
            return "€";
        case "SEK":
            return "kr";
        case "CHF":
            return "Fr.";
        case "GBP":
            return "£";
        case "PLN":
            return "zł";
        default:
            return "??";
    }
};

/**
 * Get price object from value and currency
 * @param value - value
 * @param currency - currency
 */
export const createPriceFromValueAndCurrency = (value: number, currency: CurrencyCode): Price => ({
    value,
    currency,
    fullValue: value,
    originalValue: value,
    originalFullValue: value,
    originalCurrency: currency,
    discount: ""
});
