import React, { useState } from "react";
import i18next from "i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";

import { AppState } from "@store/reducers";
import { toggleIsSeen, removeNotification } from "@store/notifications/notification-actions";
import { toggleSideBarCollapse } from "@store/app/app-actions";
import Scrollbars from "@components/scrollbars/Scrollbars";
import Button from "@components/button/Button";
import Select from "@components/form/input/Select";
import { User } from "@definitions/user";
import { IChat } from "@definitions/chat";
import { userPaginationAndFilters, useUserOptions } from "@utils/hooks";
import CinioAPI from "@utils/cinio-api";

import Chat from "./Chat";
import "./chat-list.scss";

interface Props extends RouteComponentProps {
    user: User;
    toggleSideBarCollapse: typeof toggleSideBarCollapse;
    toggleIsSeen(id: string): void;
    removeNotification(id: string): void;
}

const ChatList = (props: Props) => {
    const { user } = props;
    const [activeChat, setActiveChat] = useState<IChat | null>(null);

    const [userIds, setUserIds] = useState<string[]>([]);

    const { data } = userPaginationAndFilters<IChat>({
        data: [],
        limit: 20,
        fetch: CinioAPI.getChats,
        disableUrlQuery: true,
    });

    const { userOptions, isFetchingUsers, updateUserSearchTerm } = useUserOptions();

    const createChat = () => {
        if (!userIds.length) return;

        return CinioAPI.postChat({ userIds })
            .then(chat => {
                console.log(chat);
            })
            .catch(e => {
                console.error(e);
            });
    };

    console.log(data);

    if (!activeChat) {
        return (
            <Scrollbars>
                <div className="ChatList">
                    <h2 className="Chat__title">{i18next.t("glossary::Chat")}</h2>
                    <div className="Chat__create">
                        <Select
                            options={userOptions}
                            isLoading={isFetchingUsers}
                            onInputChange={updateUserSearchTerm}
                            isMulti={true}
                            onChange={setUserIds}
                        />
                        <Button text="Start chat" onClick={createChat} />
                    </div>
                    <div className="ChatList__items">
                        {data.map(chat => {
                            const hasUnreadCOunt = chat.unreadCount ? "has-unread-messages" : "";
                            const otherUsers = chat.users.filter(chatUser => chatUser.id !== user.id);

                            return (
                                <div className={`ChatItem ${hasUnreadCOunt}`} onClick={() => setActiveChat(chat)}>
                                    <div className="ChatItem__profile-picture">
                                        {otherUsers[0].firstName[0]}
                                        {otherUsers[0].lastName[0]}
                                    </div>
                                    <div className="ChatItem__content">
                                        <div className="ChatItem__users">
                                            {otherUsers.map(user => user.firstName).join(", ")}
                                            {/* {chat.requestName && <span className="ChatItem__request-name">{chat.requestName}</span>} */}
                                        </div>
                                        <div className="ChatItem__message">
                                            {chat.latestMessages.length ? chat.latestMessages.slice(-1)[0].content : "This chat has no messages yet"}
                                        </div>
                                        <div className="ChatItem__right">
                                            <span className="ChatItem__date">Yesterday</span>
                                            <span className="ChatItem__unread-count">{chat.unreadCount}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Scrollbars>
        );
    }

    return <Chat goBack={() => setActiveChat(null)} chat={activeChat} user={props.user} />;
};

const mapStateToProps = (state: AppState) => ({
    notifications: state.notifications.items,
    user: state.user.data,
});

const actions = { toggleIsSeen, removeNotification, toggleSideBarCollapse };

export default withRouter(connect(mapStateToProps, actions)(ChatList));
