/**
 * Action types for wish list reducer
 */
export default {
    FETCH_INIT        : "WISH_LIST/FETCH_INIT",
    FETCH_SUCCESS     : "WISH_LIST/FETCH_SUCCESS",
    ADD_ITEM          : "WISH_LIST/ADD_ITEM",
    EDIT_ITEM         : "WISH_LIST/EDIT_ITEM",
    UPDATE_ITEM       : "WISH_LIST/UPDATE_ITEM",
    REMOVE_ITEM       : "WISH_LIST/REMOVE_ITEM",
    STOP_EDITING_ITEM : "WISH_LIST/STOP_EDITING_ITEM",
    RESET_ITEMS       : "WISH_LIST/RESET_ITEMS"
};
