import React, { useState } from "react";

interface Props {
    postMessage(message: string): Promise<boolean>;
}

/**
 * Component for creating a new chat message
 */
const ChatMessageComposer = (props: Props) => {
    const [newMessage, setNewMessage] = useState("");

    const submitMessage = () => {
        if (newMessage) {
            setNewMessage("");
            props.postMessage(newMessage);
        }
    };

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                submitMessage();
            }}
        >
            <input value={newMessage} onChange={e => setNewMessage(e.target.value)} />
            <button disabled={!newMessage} onClick={submitMessage}>
                <i className="cinicon-send"></i>
            </button>
        </form>
    );
};

export default ChatMessageComposer;
