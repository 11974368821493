import { AnyAction  } from "redux";
import { ThunkDispatch } from "redux-thunk";

import apiRequest from "@utils/cinio-api";
import ACTIONS from "./company-action-types";

/**
 * Fetch the logged-in users own company from Cinio api
 */
export const fetchCompany = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction >) => {
        dispatch({ type: ACTIONS.FETCH_INIT });

        return apiRequest.get("/companies/own")
            .then((response: any) => {
                dispatch({ type: ACTIONS.FETCH_SUCCESS, payload: response.data });
            })
            .catch(() => {
                dispatch({ type: ACTIONS.FETCH_ERROR });
            });
    };
};
