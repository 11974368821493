import React       from "react";
import { connect } from "react-redux";
import i18next     from "i18next";
import CinioAPI    from "@src/utils/cinio-api";

import { addAlert, AddAlert } from "@store/app/app-actions";
import Form, { FormField, SubmitButton } from "@components/form/Form";

import "./password-recovery.scss";

interface Props {
    addAlert: AddAlert;
}

interface State {
    email: string;
    success: boolean;
}

class PasswordRecoveryForm extends React.Component<Props, State> {

    state: State = {
        email: "",
        success: false
    };

    componentDidMount() {
        document.addEventListener("keyup", this.handleKeyup);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.handleKeyup);
    }

    handleKeyup = (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
            this.handleSubmit();
        }
    }

    handleChange = (updateState: State) => {
        this.setState(updateState);
    }

    handleSubmit = async () => {
        const { email } = this.state;

        if (!email) {
            return false;
        }

        try {
            await CinioAPI.post("/request-password-reset", { email });
            this.setState({ success: true });
            return true;
        } catch (e) {
            this.props.addAlert({
                type: "error",
                message: e.message
            });
            return true;
        }
    }

    render() {
        const { success } = this.state;

        const message = success
            ? i18next.t("auth::If the email you entered exists in our database, you'll receive an email shortly")
            : i18next.t("auth::Please enter your email address. We'll send you an email with a link to reset your password.");

        return (
            <Form
                data={this.state}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}>
                <div className="password-recovery__title">
                    {i18next.t("auth::Password Reset")}
                </div>
                <h2 className="password-recovery__info">
                    {message}
                </h2>
                {!success &&
                    <FormField
                        className="FormField__input"
                        path="email"
                        label="password-recovery"
                        type="text"
                        placeholder={i18next.t("auth::Fill in your email")}
                    />
                }
                {!success &&
                    <SubmitButton />
                }
            </Form>
        );
    }
}

const actions = { addAlert };

export default connect(null, actions)(PasswordRecoveryForm);
