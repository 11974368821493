import React             from "react";
import { connect }       from "react-redux";
import { IToastMessage } from "@src/definitions/toast-message";
import { AppState }      from "@src/store/reducers";
import ToastMessage      from "./ToastMessage";
import * as actions      from "@store/toast-messages/toast-message-actions";

import "./toast-messages.scss";

interface Props {
    toasts: IToastMessage[];
    openToastMessage(id: string): void;
    toggleIsSeen(id: string): void;
    deleteNotification(id: string): void;
    removeToastMessage(id: string): void;
}

const ToastMessages = (props: Props) => {
    const { toasts, ...actions } = props;

    if (!toasts.length) {
        return null;
    }

    return (
        <div className="ToastMessages">
            {toasts.map((toast) =>
                <ToastMessage
                    toast={toast}
                    {...actions}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    toasts: state.toastMessages
});

export default connect(mapStateToProps, actions)(ToastMessages);
