import React from "react";
import { IToastMessage } from "@src/definitions/toast-message";
import { Link } from "react-router-dom";

// @ts-ignore
import Swipe from "react-easy-swipe";

interface Props {
    toast: IToastMessage;
    removeToastMessage(id: string): void;
    openToastMessage(id: string): void;
    toggleIsSeen(id: string): void;
    deleteNotification(id: string): void;
}

const ToastMessage = (props: Props) => {
    const { toast } = props;

    const removeToastMessage = () => props.removeToastMessage(toast.id);
    const openMessage        = () => props.openToastMessage(toast.id);
    const toggleIsSeen         = () => props.toggleIsSeen(toast.id);
    const deleteNotification = () => props.deleteNotification(toast.id);
    const isOpen             = toast.isOpen ? "is-open" : "";

    return (
        <Swipe className={`ToastMessage ${isOpen}`}
            onClick={openMessage}
            onSwipeRight={removeToastMessage}
            onSwipeUp={removeToastMessage}>
            <div className="ToastMessage__content">
                <div className="ToastMessage__thumbnail"
                    style={{ backgroundImage: `url("${toast.thumbnail}")` }}>
                </div>
                <div className="ToastMessage__right">
                    {toast.message}
                </div>
            </div>
            <div className="ToastMessage__actions">
                <Link to={toast.link} className="ToastMessage__action"
                    onClick={toggleIsSeen}>
                    View
                </Link>
                <div className="ToastMessage__action"
                    onClick={toggleIsSeen}>
                    Mark as seen
                </div>
                <div className="ToastMessage__action"
                    onClick={deleteNotification}>
                    Delete
                </div>
            </div>
        </Swipe>
    );
};

export default ToastMessage;
