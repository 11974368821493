import * as R from "ramda";
import React, { Ref } from "react";
import { connect }  from "react-redux";

import CinioAPI from "@utils/cinio-api";
import { IMediaItem, IMediaType, IMediaEntityType } from "@definitions/media";
import Portal from "@components/portal/Portal";
import { addAlert, AddAlert } from "@store/app/app-actions";

import { isYoutubeUrl } from "../video/video-utils";

import LinkUploader from "./MediaLinkUploader";
import FileUploader from "./MediaFileUploader";

import "./media-uploader.scss";

interface Props {
    isOpen: boolean;
    addAlert: AddAlert;
    mediaTypes: IMediaType[];
    entityType: IMediaEntityType;
    onCompletion(files: IMediaItem[]): void;
    onClose(): void;
}

interface State {
    link: string;
    isUploading: boolean;
}

export interface MediaUploaderItem extends IMediaItem {
    name: string;
    file: File;
}

/**
 * Media uploader for links and files
 */
class MediaUploader extends React.Component<Props, State> {

    reader = new FileReader();
    mediaUploader: Ref<HTMLDivElement> = React.createRef();

    state: State = {
        link: "",
        isUploading: false
    };

    handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const files = Array.from(e.target.files || []);
            const mediaUploaderItems: MediaUploaderItem[] = [];

            // Reset file input value to enable multiple uploads
            e.target.value = "";

            for (const file of files) {
                const progressEvent = await this.readFile(file);
                const mediaItem = this.getMediaItem(progressEvent, file);
                mediaUploaderItems.push(mediaItem);
            }

            this.uploadItems(mediaUploaderItems);
        } catch (e) {
            this.setState({ isUploading: false });
            this.props.addAlert({
                type: "error",
                title: "Could not upload media",
                message: e.message
            });
        }
    }

    readFile = (file: File): Promise<ProgressEvent> => {
        return new Promise((resolve) => {
            this.reader.readAsDataURL(file);

            this.reader.onload = function(e) {
                resolve(e);
            };
        });
    }

    getMediaItem = (e: ProgressEvent, file: File): MediaUploaderItem => {
        const src: string = R.pathOr("", ["target", "result"], e);

        if (!src) {
            throw new Error("Error uploading file.");
        }

        const mediaType  = this.getUploadedMediaType(file.type);
        return { src, mediaType, originalSrc: src, file, height: 0, width: 0, name: "", locale: "" };
    }

    uploadItems = async (mediaUploaderItems: MediaUploaderItem[]) => {
        try {
            this.setState({ isUploading: true });

            const mediaItems = await Promise.all(mediaUploaderItems.map(async (item) => {
                const formData = new FormData();
                formData.set("file", item.file);
                formData.set("entityType", this.props.entityType);

                const response = await CinioAPI.post("/media", formData);
                return {
                    ...response.data,
                    mediaType: this.getImageTypes()[0] || "image"
                } as IMediaItem;
            }));

            this.props.onCompletion(mediaItems);
        } catch (e) {
            this.props.addAlert({
                type: "error",
                title: "Could not upload media",
                message: e.message
            });
        }

        this.setState({ isUploading: false });
    }

    getUploadedMediaType = (type: string): IMediaType => {
        switch (type) {
            case "image/jpeg":
            case "image/png":
                return "image";
            case "video/mp4":
                return "video";
            default:
                throw new Error(`${type} is not a supported file format.`);
        }
    }

    getLinkMediaType = (src: string): IMediaType => {
        const firstType = this.getVideoTypes()[0];
        if (!isYoutubeUrl(src)) {
            throw new Error(`${src} is not a YouTube video.`);
        }
        if (!firstType) {
            throw new Error("No video media types provided.");
        }
        return firstType;
    }

    handleLinkChange = (link: string) => {
        this.setState({ link });
    }

    handleLinkSubmit = () => {
        try {
            const { link } = this.state;

            const mediaItem: IMediaItem = {
                src: link,
                originalSrc: link,
                width: 0,
                height: 0,
                mediaType: this.getLinkMediaType(link),
                locale: ""
            };

            this.props.onCompletion([mediaItem]);
        } catch (e) {
            this.props.addAlert({
                type: "error",
                title: "Could not add link",
                message: e.message
            });
        }
    }

    handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
        const mediaUploader = R.pathOr(null, ["mediaUploader", "current"], this) as HTMLDivElement | null;

        if (mediaUploader && mediaUploader.contains(e.target as Node)) {
            return;
        }

        this.setState({ link: "", isUploading: false });
        this.props.onClose();
    }

    getVideoTypes = () => {
        const videoTypes: IMediaType[] = ["video", "trailer"];
        return videoTypes.filter((videoType) => this.props.mediaTypes.includes(videoType));
    }

    getImageTypes = () => {
        const videoTypes = this.getVideoTypes();
        return this.props.mediaTypes.filter((mediaType) => !videoTypes.includes(mediaType));
    }

    render() {
        const { link } = this.state;

        const isOpen            = this.props.isOpen ? "is-open" : "";
        const isUploading       = this.state.isUploading ? "is-uploading" : "";
        const enableVideoUpload = !!this.getVideoTypes().length;

        return (
            <Portal>
                <div className={`MediaUploader__overlay ${isOpen}`}
                    onClick={this.handleClose}>
                    <div className={`MediaUploader ${isUploading}`}
                        ref={this.mediaUploader}>
                        <FileUploader
                            handleFileSelect={this.handleFileSelect}
                        />
                        {enableVideoUpload &&
                            <LinkUploader
                                link={link}
                                onChange={this.handleLinkChange}
                                onSubmit={this.handleLinkSubmit}
                            />
                        }
                        <i className="cinicon-cloud-upload"></i>
                        <div className="MediaUploader__loadingText">
                            Uploading...
                        </div>
                    </div>
                </div>
            </Portal>
        );
    }
}

const actions = { addAlert };

export default connect(null, actions)(MediaUploader);
