import * as R from "ramda";
import { useState } from "react";
import { Film, FilmType, FilmVersion, LiveDate } from "@definitions/film";
import { getDateAfterDays, getDateDaysBefore, getMinDate, getNextDay, getDateWithoutTime, getDayCount } from "@src/utils/dates";
import { NewCartItem, BookingPeriodType, CartItemVersion, ReducerCartItem } from "@definitions/cart";
import { WishListItem } from "@definitions/wish-list";
import { ICinema } from "@definitions/cinema";
import { liveEventIsInFuture, getVenueType, getCartItemVersion, cinemaIdIsValid, auditoriumIdIsValid } from "@src/app/films/film-utils";

interface DateOptions {
    startDate: Date;
    endDate: Date;
    bookingPeriodType: BookingPeriodType;
    fixedPeriodDuration: number;
    filmType: FilmType;
    liveDate: LiveDate | null;
}

/**
 * React hook for editing a cart item
 * @param cartItemDefault - default cart item
 * @param saveItem - provide this option in order to autosave item on change
 */
export const useCartItemEditor = (cartItemDefault: NewCartItem, cinemas: ICinema[], saveItem?: (updatedItem: NewCartItem) => any) => {
    const [cartItem, updateCartItem] = useState(cartItemDefault);
    const [fixedPeriodDuration, setFixedPeriodDuration] = useState<number>(getDefaultFixedPeriodDuration(cartItem));
    const { startDate, endDate, liveDate, includeFilmdepotMaterials } = cartItem;

    const cartItemIsValid = validateCartItem(cartItem);
    const version = getCartItemVersion(cartItem.versions, cartItem.versionId);

    const dateOptions = {
        ...R.pick(["bookingPeriodType", "startDate", "endDate", "filmType", "liveDate"], cartItem),
        fixedPeriodDuration
    };

    const updateAndSave = (updatedItem: NewCartItem, localSaveOnly = false) => {
        updateCartItem(updatedItem);
        setFixedPeriodDuration(getDefaultFixedPeriodDuration(updatedItem));
        if (saveItem && !localSaveOnly) saveItem(updatedItem);
    };

    const handleVersionIdChange = (versionId: string) => {
        const newVersion = getCartItemVersion(cartItem.versions, versionId);
        let bookingPeriodType = cartItem.bookingPeriodType;

        if (!newVersion) return;

        if (cartItem.bookingPeriodType === "day" && !newVersion.price) {
            bookingPeriodType = newVersion.weeklyPrice ? "week" : "month";
        }
        if (cartItem.bookingPeriodType === "week" && !newVersion.weeklyPrice) {
            bookingPeriodType = newVersion.price ? "day" : "month";
        }
        if (cartItem.bookingPeriodType === "month" && !newVersion.monthlyPrice) {
            bookingPeriodType = newVersion.price ? "day" : "week";
        }

        const cinemaIds = cartItem.cinemaIds.filter((id) => cinemaIdIsValid(newVersion, cinemas, id));
        const auditoriumIds = cartItem.auditoriumIds.filter((id) => auditoriumIdIsValid(newVersion, cinemas, id));

        updateAndSave({ ...cartItem, versionId, bookingPeriodType, cinemaIds, auditoriumIds });
    };

    const handleFilmTypeChange = (filmType: FilmType) => {
        const newStartDate = filmType === "encore" && liveDate
            ? getMinDate(liveDate)
            : startDate;

        const newEndDate = filmType === "encore" && endDate < startDate
            ? startDate
            : endDate;

        updateAndSave({ ...cartItem, filmType, startDate: newStartDate, endDate: newEndDate });
    };

    const handleStartDateChange = (startDate: Date) => {
        const newEndDate = getEndDate({ ...dateOptions, startDate });
        updateAndSave({ ...cartItem, startDate, endDate: newEndDate });
    };

    const handleEndDateChange = (endDate: Date) => {
        const newStartDate = getStartDate({ ...dateOptions, endDate });
        updateAndSave({ ...cartItem, endDate, startDate: newStartDate });
    };

    const handleBookingPeriodTypeChange = (bookingPeriodType: BookingPeriodType) => {
        const newEndDate = getEndDate({ ...dateOptions, bookingPeriodType });

        if (bookingPeriodType === "day" && !version?.price) return;
        if (bookingPeriodType === "week" && !version?.weeklyPrice) return;
        if (bookingPeriodType === "month" && !version?.monthlyPrice) return;

        updateAndSave({ ...cartItem, bookingPeriodType, endDate: newEndDate });
    };

    const handleFixedPeriodDurationChange = (fixedPeriodDurationStr: string) => {
        const fixedPeriodDuration = Number(fixedPeriodDurationStr);
        const newEndDate = getEndDate({ ...dateOptions, fixedPeriodDuration });

        setFixedPeriodDuration(fixedPeriodDuration);
        updateAndSave({ ...cartItem, endDate: newEndDate });
    };

    const handleSelectedCinemasChange = (cinemaIds: string[]) => updateAndSave({ ...cartItem, cinemaIds });
    const handleSelectedAuditoriumsChange = (auditoriumIds: string[]) => updateAndSave({ ...cartItem, auditoriumIds });
    const toggleFilmdepotMaterials = () => updateAndSave({ ...cartItem, includeFilmdepotMaterials: !includeFilmdepotMaterials });

    return {
        cartItem,
        cartItemIsValid,
        updateCartItem: updateAndSave,
        handleVersionIdChange,
        handleFilmTypeChange,
        handleStartDateChange,
        handleEndDateChange,
        handleBookingPeriodTypeChange,
        handleFixedPeriodDurationChange,
        handleSelectedCinemasChange,
        handleSelectedAuditoriumsChange,
        toggleFilmdepotMaterials,
        fixedPeriodDuration
    };
};

/**
 * Get defaults based on film type and version
 * @param film - film
 * @param dateOptions - date options
 */
export const getCartItemFromFilm = (film: Film): NewCartItem => {
    const firstVersion      = film.versions[0] || {};
    const versionId         = firstVersion.id || "";
    const bookingPeriodType = firstVersion.price ? "day" : firstVersion.weeklyPrice ? "week" : "month";
    const defaultStartDate  = getDateWithoutTime(getNextDay());
    const defaultEndDate    = getDateWithoutTime(getNextDay());
    const filmType          = getDefaultFilmType(film.filmType, film.liveDate);

    const dateOptions: DateOptions = {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        liveDate: film.liveDate,
        fixedPeriodDuration: 1,
        bookingPeriodType,
        filmType
    };

    const startDate = getStartDate(dateOptions);
    const endDate = getEndDate({ ...dateOptions, startDate });

    return {
        filmId: film.id,
        needsPermission: film.needsPermission,
        title: film.title,
        poster: film.poster,
        status: "pending",
        liveDate: film.liveDate,
        lowestPrice: film.lowestPrice,
        handle: film.handle,
        includeFilmdepotMaterials: false,
        versions: getCartItemVersions(film.versions),
        versionId,
        filmType,
        bookingPeriodType,
        startDate,
        endDate,
        cinemaIds: [], // @todo
        auditoriumIds: [], // @todo
    };
};

const getCartItemVersions = (filmVersions: FilmVersion[]): CartItemVersion[] => {
    return filmVersions.map((version) => ({
        id                      : version.id,
        name                    : version.name,
        price                   : version.price,
        weeklyPrice             : version.weeklyPrice,
        monthlyPrice            : version.monthlyPrice,
        filmdepotMaterialsPrice : version.filmdepotMaterialsPrice,
        hasFilmdepotMaterials   : version.hasFilmdepotMaterials,
        contentKind             : version.contentKind,
        countries               : version.countries,
        excludedCountries       : version.excludedCountries
    }));
};

/**
 * Get end date depending on film type and fixed period settings
 * @param dateOptions - date options
 */
export const getEndDate = (dateOptions: DateOptions) => {
    const { bookingPeriodType, filmType, startDate, endDate, liveDate } = dateOptions;
    const fixedPeriodActive = bookingPeriodType === "month" || bookingPeriodType === "week";

    if (filmType === "live-event") {
        return liveDate?.endDate || endDate;
    }

    if (!fixedPeriodActive && new Date(endDate) < new Date(startDate)) {
        return startDate;
    }

    return fixedPeriodActive
        ? getFixedPeriodEndDate(dateOptions)
        : endDate;
};

/**
 * Get start date depending on film type and fixed period settings
 * @param dateOptions - date options
 */
export const getStartDate = (dateOptions: DateOptions) => {
    const { bookingPeriodType, filmType, startDate, liveDate } = dateOptions;
    const fixedPeriodActive = bookingPeriodType === "month" || bookingPeriodType === "week";

    if (filmType === "live-event") {
        return liveDate?.startDate || startDate;
    }

    return fixedPeriodActive
        ? getFixedPeriodStartDate(dateOptions)
        : startDate;
};

/**
 * Find a film by id and film type in wish list
 * @param wishList - wish list
 * @param filmId - film id
 * @param filmType - film type
 */
export const findInWishList = (wishList: WishListItem[], filmId: string, filmType: FilmType) => {
    return wishList.find((item) =>
        item.filmId === filmId &&
        item.filmType === filmType
    );
};

/**
 * Get start date for a fixed period
 * @param dateOptions - date options
 */
const getFixedPeriodStartDate = (dateOptions: DateOptions) => {
    const { bookingPeriodType, fixedPeriodDuration, startDate, endDate } = dateOptions;

    if (bookingPeriodType === "week") {
        const daysBefore = 6 + ((fixedPeriodDuration - 1) * 7);
        return getDateDaysBefore(endDate, daysBefore);
    }

    if (bookingPeriodType === "month") {
        const daysBefore = 29 + ((fixedPeriodDuration - 1) * 30);
        return getDateDaysBefore(endDate, daysBefore);
    }

    return startDate;
};

/**
 * Get end date for a fixed period
 * @param dateOptions - date options
 */
const getFixedPeriodEndDate = (dateOptions: DateOptions) => {
    const { bookingPeriodType, fixedPeriodDuration, startDate, endDate } = dateOptions;

    if (bookingPeriodType === "week") {
        const daysAfter = 6 + ((fixedPeriodDuration - 1) * 7);
        return getDateAfterDays(startDate, daysAfter);
    }
    if (bookingPeriodType === "month") {
        const daysAfter = 29 + ((fixedPeriodDuration - 1) * 30);
        return getDateAfterDays(startDate, daysAfter);
    }

    return endDate;
};

/**
 * Get default film type
 * If film type is live event and the event has not yet taken place, "live-event" will be the default
 * @param liveDate - live date boject
 */
const getDefaultFilmType = (filmType: FilmType, liveDate: LiveDate | null) => {
    if (filmType === "film") {
        return filmType;
    }

    return liveEventIsInFuture(liveDate)
        ? "live-event"
        : "encore";
};

/**
 * Get default fixed period duration based on booking period type, start date and end date
 * @param cartItem - cart item
 */
const getDefaultFixedPeriodDuration = (cartItem: NewCartItem) => {
    const { bookingPeriodType, startDate, endDate } = cartItem;
    const dayCount = getDayCount(startDate, endDate);

    if (bookingPeriodType === "month") {
        return Math.floor(dayCount / 30);
    }
    if (bookingPeriodType === "week") {
        return Math.floor(dayCount / 7);
    }

    return 1;
};

/**
 * Check if cart items are valid
 * @param cartItems - cart items to validate
 */
export const validateCartItems = (cartItems: NewCartItem[]) => {
    return cartItems.reduce((areValid: boolean, cartItem) => {
        return areValid
            ? validateCartItem(cartItem)
            : false;
    } , true);
};

/**
 * Check if cart item are valid
 * @param cartItem - cart item to validate
 */
export const validateCartItem = (cartItem: NewCartItem) => {
    const { startDate, endDate, versionId, versions, cinemaIds, auditoriumIds, bookingPeriodType } = cartItem;

    const version   = getCartItemVersion(versions, versionId);
    const venueType = getVenueType(version, bookingPeriodType);
    const venues    = venueType === "cinema" ? cinemaIds : auditoriumIds;

    return Boolean(venues.length && startDate && endDate && versionId);
};

/**
 * Check if cart items are valid
 * @param cartItems - cart items to validate
 */
export const checkIfCartItemsAreSaving = (cartItems: ReducerCartItem[]) => {
    return cartItems.some((cartItem) => cartItem.isSaving);
};
