import React, { Suspense, lazy }                                 from "react";
import { Route, Switch, withRouter, RouteComponentProps }        from "react-router";
import { CompanyType }                                           from "@src/definitions/company";

const Home             = lazy(() => import("./home/Home"));
const VendorDashboard  = lazy(() => import("./vendor-dashboard/VendorDashboard"));
const BlogPosts        = lazy(() => import("./blog/BlogPosts"));
const BlogPost         = lazy(() => import("./blog/BlogPost"));
const FilmPage         = lazy(() => import("./films/page/FilmPage"));
const FilmCatalog      = lazy(() => import("./catalogs/FilmCatalog"));
const EventCatalog     = lazy(() => import("./catalogs/EventCatalog"));
const FilmForm         = lazy(() => import("./films/form/FilmForm"));
const FilmTable        = lazy(() => import("./films/table/FilmTable"));
const UserForm         = lazy(() => import("./users/UserForm"));
const Checkout         = lazy(() => import("./checkout/Checkout"));
const OrderTable       = lazy(() => import("./orders/OrderTable"));
const OrderPage        = lazy(() => import("./orders/OrderPage"));
const CompanyTable     = lazy(() => import("./companies/CompanyTable"));
const CompanyForm      = lazy(() => import("./companies/CompanyForm"));
const CinemaTable      = lazy(() => import("./cinemas/CinemaTable"));
const CinemaForm       = lazy(() => import("./cinemas/CinemaForm"));
const CinemaPage       = lazy(() => import("./cinemas/CinemaPage"));
const UserTable        = lazy(() => import("./users/UserTable"));
const Requests         = lazy(() => import("./requests/RequestTable"));
const Management       = lazy(() => import("./management/Management"));
const TagForm          = lazy(() => import("./tags/form/TagForm"));
const TagTable         = lazy(() => import("./tags/table/TagTable"));
const CategoryTable    = lazy(() => import("./categories/CategoryTable"));
const NotFound         = lazy(() => import("./not-found/NotFound"));
const BlogPostTable    = lazy(() => import("./blog/table/BlogPostTable"));
const BlogPostForm     = lazy(() => import("./blog/form/BlogPostForm"));
const DeliveryFeeTable = lazy(() => import("./delivery-fee-table/DeliveryFeeTable"));
const StudioOffices    = lazy(() => import("./studio-offices/StudioOfficesTable"));
const StudioOfficeForm = lazy(() => import("./studio-offices/StudioOfficeForm"));
const CrewMemberTable  = lazy(() => import("./crew-members/table/CrewMemberTable"));
const CrewMemberForm   = lazy(() => import("./crew-members/form/CrewMemberForm"));

interface Props extends RouteComponentProps {
    companyType: CompanyType;
    isModal: boolean;
    previousLocation: any; // @todo find correct type (Location<any>)
    toggleDarkMode(darkMode: boolean): void;
}

interface State {
    prevPath: string;
    routes: IRoute[];
}

interface IRoute {
    exact?: boolean;
    path?: string;
    component: React.ComponentType<any>;
    darkMode?: boolean;
    getId?: boolean;
}

class Routes extends React.Component<Props, State> {
    state: State = {
        prevPath: "",
        routes: []
    };

    constructor(props: Props) {
        super(props);

        this.state.routes = [
            { exact: true, path: "/", component: this.getHomeComponent(), darkMode: !this.isContentOwner() },
            { path: "/home", component: this.getHomeComponent(), darkMode: !this.isContentOwner() },

            { path: "/catalog", component: FilmCatalog, darkMode: true },
            { path: "/film-catalog", component: FilmCatalog, darkMode: true },
            { path: "/live-events", component: EventCatalog, darkMode: true },
            { path: "/event-catalog", component: EventCatalog, darkMode: true },
            { path: "/film/:id", component: FilmPage, darkMode: true, getId: true },
            { path: "/event/:id", component: FilmPage, darkMode: true, getId: true },

            { path: "/checkout/:id", component: Checkout, getId: true },
            { path: "/checkout", component: Checkout },
            { path: "/cinemas/:id", component: CinemaPage, getId: true },
            { path: "/orders/:id", component: OrderPage, getId: true },
            { path: "/orders", component: OrderTable },
            { path: "/blog-post/:id", component: BlogPost, getId: true },
            { path: "/blog", component: BlogPosts },
            { path: "/requests", component: Requests },

            { path: "/management/cinemas/:id", component: CinemaForm, getId: true },
            { path: "/management/cinemas", component: CinemaTable },
            { path: "/management/companies/:id", component: CompanyForm, getId: true },
            { path: "/management/companies", component: CompanyTable },
            { path: "/management/films/:id", component: FilmForm, getId: true },
            { path: "/management/films", component: FilmTable },
            { path: "/management/users/:id", component: UserForm, getId: true },
            { path: "/management/users", component: UserTable },
            { path: "/management/blog-posts/:id", component: BlogPostForm, getId: true },
            { path: "/management/blog-posts", component: BlogPostTable },
            { path: "/management/tags/:id", component: TagForm, getId: true },
            { path: "/management/tags", component: TagTable },
            { path: "/management/categories", component: CategoryTable },
            { path: "/management/delivery-fees", component: DeliveryFeeTable },
            { path: "/management/studio-offices/:id", component: StudioOfficeForm },
            { path: "/management/studio-offices", component: StudioOffices },
            { path: "/management/crew-members/:id", component: CrewMemberForm, getId: true },
            { path: "/management/crew-members", component: CrewMemberTable },
            { path: "/management", component: Management },

            { component: NotFound }
        ];

        this.toggleDarkMode();
    }

    shouldComponentUpdate(nextProps: Props) {
        const prevPath = this.props.location.pathname;
        const nextPath = nextProps.location.pathname;

        return prevPath !== nextPath;
    }

    componentDidUpdate() {
        this.toggleDarkMode();
    }

    toggleDarkMode = () => {
        const currentPath = this.props.location.pathname;

        const route = this.state.routes.find((route) => {
            if (route.exact) {
                return currentPath === route.path;
            }

            const path = route.path || "";
            const regex = route.getId ? path.replace(":id", "(.*)") : route.path || "";
            const matches = currentPath.match(regex);

            return matches;
        });

        const darkMode = route ? !!route.darkMode : false;

        this.props.toggleDarkMode(darkMode);
    }

    getIdFromPath = () => {
        const { pathname } = this.props.history.location;

        const path = pathname.split("/").filter((val: any) => val);
        return path[path.length - 1];
    }

    getComponentWithId = (Component: React.ComponentType<any>, isModal = false) => {
        const id = this.getIdFromPath();
        return (props: any) => <Component {...props} id={id} isModal={isModal} />;
    }

    isContentOwner = () => {
        return this.props.companyType === "vendor" || this.props.companyType === "studio";
    }

    getHomeComponent = () => {
        return this.isContentOwner() ? VendorDashboard : Home;
    }

    render() {
        const { isModal, previousLocation } = this.props;

        return (
            <Suspense fallback={null}>
                <div className="App__inner">
                    <Switch location={isModal ? previousLocation : this.props.location}>
                        {this.state.routes.map((route, i) => {
                            const Component = route.getId ? this.getComponentWithId(route.component) : route.component;
                            return (
                                <Route
                                    key={String(route.path) + i}
                                    exact={route.exact}
                                    path={route.path}
                                    render={(props) => <Component {...props} />}
                                />
                            );
                        })}
                        <Route render={(props) => <NotFound {...props} />} />
                    </Switch>
                </div>
                {isModal && (
                    <div className="App__modal">
                        <Switch location={this.props.location}>
                            <Route path="/film/:id" render={(props) => this.getComponentWithId(FilmPage, true)(props)} />
                            <Route path="/event/:id" render={(props) => this.getComponentWithId(FilmPage, true)(props)} />
                        </Switch>
                    </div>
                )}
            </Suspense>
        );
    }
}

export default withRouter(Routes);
