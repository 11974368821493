import React from "react";

export interface CheckboxProps {
  id?: string;
  readOnly?: boolean;
  value?: boolean;
  onChange?(value: boolean): void;
}

/**
 * Checkbox component
 */
const Checkbox = (props: CheckboxProps) => {
  const { id = "", readOnly = false, value = false, onChange = () => {} } = props;

  return (
    <div className="Checkbox">
        <input type="checkbox"
            id={id}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={(e) => !readOnly && onChange(e.target.checked)}
            checked={value}
        />
        <span>
            <i className="cinicon-check"></i>
        </span>
    </div>
  );
};

export default Checkbox;
