import React, { useContext } from "react";

interface IFormContext<Data extends GenericObject> {
    data: Data;
    isSaving: boolean;
    isLoading: boolean;
    didAttemptSubmit: boolean;
    isSaved: boolean;

    onChange(data: Data): void;
    onSubmit(): Promise<void>;
}

const FormContext= React.createContext<IFormContext<any> | null>(null);

/**
 * Helper function for accessing form context
 */
export const useFormContext = <Data extends GenericObject> (): IFormContext<Data> => {
    const context = useContext(FormContext);

    if (!context) {
        throw new Error("useFormContext must be used within FormContextProvider");
    }

    return context;
};

export default FormContext;
