import React from "react";
import Ripple from "../../../assets/images/ripple.svg";

import "./loading-screen.scss";

const LoadingScreen = () => {
    const location = window.location.href;
    const darkTheme = location.match("management") || location.match("orders") || location.match("blog")
        ? ""
        : "theme-dark";

    return (
        <div className={`LoadingScreen ${darkTheme}`}>
            <Ripple />
        </div>
    );
};

export default LoadingScreen;
