import React, { useState } from "react";
import FormContext from "./FormContext";

import "./form.scss";

interface Props {
    data: GenericObject;
    children: React.ReactNode;

    isSaving?: boolean;
    isLoading?: boolean;

    onChange(updatedData: GenericObject): void;
    onSubmit?(): Promise<boolean>;
}

/**
 * Form component
 */
const Form = (props: Props) => {
    const {
        data,
        children,
        isSaving = false,
        isLoading = false,
    } = props;

    const [didAttemptSubmit, setDidAttemptSubmit] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const onSubmit = async () => {
        if (!props.onSubmit) return;

        const success = await props.onSubmit();
        setDidAttemptSubmit(true);
        setIsSaved(success);
    };

    const onChange = async (updatedData: GenericObject) => {
        setIsSaved(false);
        props.onChange(updatedData);
    };

    return (
        <FormContext.Provider value={{ data, isSaving, isLoading, didAttemptSubmit, isSaved, onChange, onSubmit }}>
            <div className="Form">
                {children}
            </div>
        </FormContext.Provider>
    );
};

export { default as FormField }    from "./FormField";
export { default as FormGroup }    from "./FormGroup";
export { default as FormAddress }  from "./groups/FormAddress";
export { default as SubmitButton } from "./buttons/SubmitButton";
export { default as AddButton }    from "./buttons/AddButton";
export { default as DeleteButton } from "./buttons/DeleteButton";

export default Form;
