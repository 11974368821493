import React from "react";

import "./button.scss";

interface Props {
    text: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    theme?: "main" | "destructive" | "secondary-dark";
    size?: "small" | "large";
    onClick?(): any;
}

/**
 * Reusable button component
 */
const Button = (props: Props) => {
    const { theme = "main", size = "large" } = props;
    const isDisabled = props.isDisabled || props.isLoading ? "is-disabled" : "";

    return (
        <button className={`Button Button--${theme} Button--${size} ${isDisabled}`} onClick={props.onClick}>
            {!props.isLoading && props.text}
            {!!props.isLoading && "Loading..."}
        </button>
    );
};

export default Button;
