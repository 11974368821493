import { Address } from "@definitions/address";
import { Price } from "@definitions/price";
import { IMediaItem } from "@definitions/media";
import { FilmModelVersion, Film, FilmModel, IScreeningPrice } from "@definitions/film";
import { ICinema } from "@definitions/cinema";
import { ICompany } from "@definitions/company";
import { IBlogPostModel, IBlogPost } from "@definitions/blog-posts";
import { ITagModel } from "@src/definitions/tags";

import { UserWithPassword } from "@src/app/users/UserForm";
import { LOCALES } from "@src/config";
import { IStudioOffice } from "@src/definitions/studio-office";
import { ICrewMember } from "@src/definitions/crew-member";

 /**
  * Default address
  */
export const ADDRESS_DEFAULT: Address = {
    street1 : "",
    street2 : "",
    city    : "",
    region  : "",
    country : "",
    zip     : ""
};

/**
 * Default price
 */
export const PRICE_DEFAULT: Price = {
    value: 0,
    fullValue: 0,
    currency: "EUR",
    originalValue: 0,
    originalFullValue: 0,
    originalCurrency: "EUR",
    discount: ""
};

export const SCREENING_PRICE_DEFAULT: IScreeningPrice = {
    ...PRICE_DEFAULT,
    boxOfficePercentage: 0,
    minGuarantee: 0,
    priceType: "fixed"
};

/**
 * Default media item
 */
export const MEDIA_ITEM_DEFAULT: IMediaItem = {
    src: "",
    originalSrc: "",
    width: 0,
    height: 0,
    mediaType: "image",
    locale: ""
};

export const FILM_MODEL_VERSION_DEFAULT: FilmModelVersion = {
    _id: "",
    name: "",
    prices: [],
    gofilexIds: [],
    filmdepotId: "",
    hasFilmdepotMaterials: false,
    countries: [],
    excludedCountries: [],
    expirationDate: null,
    distributorId: "",
    CPL: "",
    subtitles: "",
    audio: "",
    aspectRatio: "",
    resolution: "",
    duration: "",
    dcpSize: "",
    endCredits: "",
    classifications: [],
    contentKind: "",
    packageType: ""
};

export const FILM_DEFAULT: Film =  {
    id: "",
    handle: "",
    filmType: "film",
    title: "",
    description: "",
    shortDescription: "",
    versions: [],
    categories: [],
    tags: [],
    links: [],
    testDates: [],
    poster: { ...MEDIA_ITEM_DEFAULT, mediaType: "poster" },
    trailer: { ...MEDIA_ITEM_DEFAULT, mediaType: "trailer" },
    filmPageHero: { ...MEDIA_ITEM_DEFAULT, mediaType: "film-page-hero" },
    homePageHero: { ...MEDIA_ITEM_DEFAULT, mediaType: "home-page-hero" },
    homePageSliderImage: { ...MEDIA_ITEM_DEFAULT, mediaType: "home-page-slider-image" },
    liveDate: null,
    needsPermission: false,
    directors: [],
    cast: [],
    lowestPrice: SCREENING_PRICE_DEFAULT,
    encoreEnabled: false,
    published: false,
    deleted: false,
    publishDate: null,
    creationDate: new Date(),
    crewMembers: []
};

export const USER_WITH_PW_DEFAULT: UserWithPassword = {
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    locale: "en",
    companyId: "",
    studioOfficeId: "",
    companyName: "",
    avatar: MEDIA_ITEM_DEFAULT,
    type: "user",
    password: "",
    confirmPassword: "",
    currentPassword: "",
    deleted: false,
    regionalRestrictions: []
};

const TRANSLATIONS_DEFAULT = LOCALES.map((locale) => ({ value: "", locale }));

export const FILM_MODEL_DEFAULT: FilmModel =  {
    _id: "",
    filmType: "film",
    handles: TRANSLATIONS_DEFAULT,
    titles: TRANSLATIONS_DEFAULT,
    descriptions: TRANSLATIONS_DEFAULT,
    shortDescriptions: TRANSLATIONS_DEFAULT,
    versions: [],
    media: [],
    links: [],
    liveDates: [],
    categories: [],
    tags: [],
    needsPermission: false,
    directors: [],
    cast: [],
    published: false,
    deleted: false,
    encoreEnabled: false,
    publishDate: null,
    releaseYear: new Date().getFullYear(),
    crewMembers: []
};

export const CINEMA_DEFAULT: ICinema = {
    _id: "",
    name: "",
    gofilexId: "",
    nffNumber: "",
    companyId: "",
    bookerIds: [],
    companyName: "",
    companyEmails: [],
    companyPhoneNumbers: [],
    deliveryFees: [],
    regions: [],
    shippingAddress: ADDRESS_DEFAULT,
    billingAddress: ADDRESS_DEFAULT,
    billingEqualsShippingAddress: false,
    auditoriums: [],
    hasFilmVault: true,
    deleted: false
};

export const COMPANY_DEFAULT: ICompany = {
    _id: "",
    name: "",
    logo: MEDIA_ITEM_DEFAULT,
    companyType: "",
    currency: "EUR",
    emails: [],
    payByInvoice: false,
    phoneNumbers: [],
    deleted: false,
    isDemo: false,
    isBAC: false,
    locale: "en",
    address: {
        street1: "",
        street2: "",
        city: "",
        region: "",
        country: "",
        zip: ""
    },
    requestSettings: {
        requireAdminConfirmationOnAccept: true,
        requireAdminConfirmationOnDecline: true,
    }
};

export const BLOG_POST_MODEL_DEFAULT: IBlogPostModel = {
        _id: "",
        locale: "",
        title: "",
        lead: "",
        slug: "",
        body: "",
        publishDate: new Date(),
        headerImage: MEDIA_ITEM_DEFAULT,
        published: false,
        authorIds: [],
        deleted: false
};

export const BLOG_POST_DEFAULT: IBlogPost = {
    ...BLOG_POST_MODEL_DEFAULT,
    authors: [],
    countries: []
};

export const STUDIO_OFFICE_DEFAULT: IStudioOffice = {
    id: "",
    name: "",
    companyId: "",
    address: ADDRESS_DEFAULT,
    countries: []
};

export const CREW_MEMBER_DEFAULT: ICrewMember = {
    _id: "",
    name: "",
    image: MEDIA_ITEM_DEFAULT,
    createdBy: ""
};

export const TAG_MODEL_DEFAULT: ITagModel = {
    _id: "",
    key: "",
    names: [],
    hidden: false
};
