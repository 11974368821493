import { ILocaleCode } from "./definitions/translation";

export const LOCALES: ILocaleCode[] = [
    "en",
    "fr",
    "fi",
    "nl",
    "de",
    "pl",
    "sv-SE",
    "sv-FI"
];
