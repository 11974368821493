import React    from "react";
import i18next  from "i18next";
import { Link } from "react-router-dom";

import "./wish-list-empty.scss";

/**
 * Placeholder for empty wish list
 */
const WishListEmpty = () => {
    return (
        <div className="WishListEmpty">
            <p>{i18next.t("wish-list::Your wish list is empty.")}</p>
            <Link to ="/catalog">{i18next.t("wish-list::Browse catalog")}</Link>
        </div>
    );
};

export default WishListEmpty;
