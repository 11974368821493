import { AnyAction } from "redux";

import ACTIONS from "./app-action-types";
import Alert   from "@definitions/alert";

/**
 * Update screen dimensions
 * @param clientHeight
 * @param scrollHeight
 */
export const updateDimensions = (clientHeight: number, scrollHeight: number) => ({
    type: ACTIONS.UPDATE_DIMENSIONS,
    payload: {
        clientHeight,
        scrollHeight
    }
});

/**
 * Update scroll top
 * @param scrollTop
 */
export const updateScrollTop = (scrollTop: number) => ({
    type: ACTIONS.UPDATE_SCROLL_TOP,
    payload: scrollTop
});

/**
 * Add a alert
 */
export type AddAlert = (alert: Alert) => AnyAction;
export const addAlert: AddAlert = (alert) => ({
    type: ACTIONS.ADD_ALERT_MESSAGE,
    payload: alert
});

/**
 * Remove a alert by id
 */
export const removeAlert = (id: string) => ({
    type: ACTIONS.REMOVE_ALERT_MESSAGE,
    payload: id
});

export const clearAlerts = () => ({
    type: ACTIONS.CLEAR_ALERTS,
});

/*
 * Toggle 'isMobile' property
 * @param isMobile - whether mobile mode is on or off
 */
export const toggleMobileMode = (isMobile: boolean) => ({
    type: ACTIONS.TOGGLE_MOBILE_MODE,
    payload: isMobile
});

/*
 * Toggle 'hasTouch' property
 * @param hasTouch - whether device has touch or not
 */
export const toggleTouch = (hasTouch: boolean) => ({
    type: ACTIONS.TOGGLE_TOUCH,
    payload: hasTouch
});

/**
 * Toggle sidebar component
 * @param id - component id
 * @param props - component props
 */
export const toggleSidebarComponent = (id: string, props = {}) => ({
    type: ACTIONS.TOGGLE_SIDEBAR_COMPONENT,
    payload: { id, props }
});

/**
 * Toggle sidebar collapse
 */
export const toggleSideBarCollapse = (isCollapsed: boolean) => ({
    type: ACTIONS.TOGGLE_SIDEBAR_COLLAPSE,
    payload: isCollapsed
});
