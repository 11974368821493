import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

// @ts-ignore
import Swipe               from "react-easy-swipe";
import NavigationItem      from "./item/NavigationItem";
import NavigationComponent from "./NavigationComponent";
import { AppState }        from "@store/reducers";
import { Link }            from "react-router-dom";
import logo                from "@assets/images/cinio.png";
import { INotification }   from "@src/definitions/notification";

import { getFooterItems, getMenuItems, getUserItems } from "./navigation-data";

import "./navigation.scss";

interface StateProps {
    user: AppState["user"];
    company: AppState["company"];
}

interface Props extends StateProps {
    currentPath: string;
    activeComponent: string;
    wishListCount: number;
    cartCount: number;
    notifications: INotification[];
    isMobile: boolean;
    hasTouch: boolean;
    isCollapsed: boolean;
    onExpand(): void;
    onCollapse(): void;
    toggleSidebarExpand(clickedItem: string): void;
}

const Navigation = (props: Props) => {
    const {
        currentPath,
        onExpand,
        onCollapse,
        toggleSidebarExpand,
        activeComponent,
        wishListCount,
        cartCount,
        hasTouch
    } = props;

    const navRef            = useRef<HTMLDivElement>(null);
    const user              = props.user.data;
    const menuItems         = getMenuItems(props.company.data.companyType);
    const userName          = `${user.firstName} ${user.lastName}`;
    const notificationCount = props.notifications.filter((n) => !n.isSeen).length;
    const openNotifications = () => toggleSidebarExpand("notifications");
    const closeComponent    = () => toggleSidebarExpand("");
    const handleLeftSwipe   = onCollapse;
    const handleRightSwipe  = onExpand;
    const handleMouseover   = () => !hasTouch && onExpand();
    const handleMouseLeave  = () => !hasTouch && !activeComponent && onCollapse();
    const avatarStyle       = user.avatar.src ? { backgroundImage: `url("${user.avatar.src}")` } : {};
    const footerItems       = getFooterItems();

    // Listen for click outside navbar to close navigation while a sidebar component is open
    useEffect(() => {
        const collapseIfOutsideClick = (e: { target: any }) => {
            const target = e.target as Node;
            // If click was outside the navigation, collapse navigation
            const wasOutside = !navRef.current?.contains(target)
                ? document.contains(target) // Do nothing if the element has been removed from the DOM
                : false;

            if (wasOutside) onCollapse();
        };

        if (!props.isCollapsed && props.activeComponent) {
            document.addEventListener("click", collapseIfOutsideClick);
        } else {
            document.removeEventListener("click", collapseIfOutsideClick);
        }
    }, [props.isCollapsed, props.activeComponent]);

    const navItemProps = {
        currentPath,
        toggleSidebarExpand,
        activeComponent,
        wishListCount,
        cartCount
    };

    return (
        <div className="Navigation__container">
            <i className="Navigation__burger cinicon-menu"
                onClick={onExpand}>
            </i>
            <div className="Navigation"
                ref={navRef}
                onMouseOver={handleMouseover}
                onMouseLeave={handleMouseLeave}>
                <Swipe className="Navigation__content"
                    onSwipeLeft={handleLeftSwipe}
                    onSwipeRight={handleRightSwipe}>
                    <Link to="/home/" className="Navigation__brand">
                        <div className="Navigation__brandLogo">
                            <img src={logo} />
                        </div>
                    </Link>
                    <div className="Navigation__user">
                        <div className="Navigation__userTop">
                            <Link to="/management/users/me" className="Navigation__userData">
                                <div className="Navigation__userAvatar"
                                    style={avatarStyle}>
                                </div>
                                <div className="Navigation__userName">
                                    {userName}
                                </div>
                            </Link>
                            <i className="Navigation__notificationIcon cinicon-bell"
                                onClick={openNotifications}>
                            </i>
                            {!!notificationCount &&
                                <div className="Navigation__notificationCount"
                                    onClick={openNotifications}>
                                    {notificationCount}
                                </div>
                            }
                        </div>
                        <div className="Navigation__userOptions">
                            {getUserItems().map((navItem) =>
                                <NavigationItem key={navItem.id}
                                    {...navItemProps}
                                    {...navItem}
                                />
                            )}
                        </div>
                    </div>
                        <div className="Navigation__navItems">
                            {menuItems.map((navItem) =>
                                <NavigationItem key={navItem.id}
                                    {...navItemProps}
                                    {...navItem}
                                />
                            )}
                            <div className="Navigation__footer">
                                {footerItems.map((footerItem) =>
                                    <NavigationItem key={footerItem.id}
                                        {...navItemProps}
                                        {...footerItem}
                                    />
                                )}
                            </div>
                    </div>
                </Swipe>
                <NavigationComponent
                    componentId={activeComponent}
                    closeComponent={closeComponent}
                    closeNavigation={handleLeftSwipe}
                />
            </div>
        </div>
    );
};

export const mapStateToProps = (state: AppState) => ({
    user          : state.user,
    company       : state.company,
    wishListCount : state.wishList.items.length,
    cartCount     : state.cart.items.length,
    notifications : state.notifications.items
});

export default connect(mapStateToProps)(Navigation);
