import * as R from "ramda";
import React, { useState } from "react";
import FormInput, { FormInputProps } from "./FormInput";
import { useFormContext } from "./FormContext";
import { updateObject } from "@src/utils/objects";

interface OwnProps {
    path: string;
    label?: string;
    className?: string;
    children?: JSX.Element[];
    readOnly?: boolean;
    isRequired?: boolean;
    isHidden?: boolean;
}

export type FormFieldProps = OwnProps & FormInputProps;

/**
 * Form field component
 */
const FormField = (props: FormFieldProps) => {
    const { type, path, label = "", className = "", isRequired = false } = props;
    const { data, onChange, didAttemptSubmit } = useFormContext();

    const [isTouched, setIsTouched] = useState(false);

    const value      = R.path(path.split("."), data);
    const labelTag   = label ? <label htmlFor={path}>{label}</label> : "";
    const inputProps = props as FormInputProps;

    const handleChange = (value: any) => {
        const updatedData = updateObject(data, path, value);
        setIsTouched(true);
        onChange(updatedData);
    };

    const getErrorMessage = () => {
        if (!isTouched && !didAttemptSubmit) {
            return "";
        }

        if (isRequired && !value) {
            return "This field is required";
        }
        if (type === "email" && value && typeof value === "string" && !value.match(/\S+@\S+\.\S+/)) {
            return "Please enter a valid email address";
        }

        return "";
    };

    const errorMessage = getErrorMessage();
    const hasError = errorMessage ? "has-error" : "";

    if (props.isHidden) {
        return null;
    }

    return (
        <div className={`FormField FormField--${type} ${hasError} ${className}`}>
            <div className="FormField__error">
                <span>
                    {errorMessage}
                </span>
            </div>
            <div className="FormField__content">
                <div className="FormField__left">
                    {labelTag}
                </div>
                <div className="FormField__right">
                    <FormInput {...inputProps} onChange={handleChange} value={value as any} />
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default FormField;
