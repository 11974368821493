import React, { useRef } from "react";

export interface TextareaProps {
  id?: string;
  value?: string;

  placeholder?: string;
  readOnly?: boolean;

  onChange?(value: string): void;
  handleBlur?(): void;
}

/**
 * Textarea component
 */
const Textarea = (props: TextareaProps) => {
    const ref = useRef<HTMLTextAreaElement>(null);

    const {
        id = "",
        value = "",
        onChange = () => {},
        readOnly = false,
        placeholder = "",
        handleBlur = () => {/**/}
    } = props;

    return (
        <textarea
            id={id}
            ref={ref}
            value={String(value)}
            onChange={(e) => {
                const selectionStart = e.target.selectionStart === null
                    ? e.target.value.length
                    : e.target.selectionStart;

                onChange(e.target.value);

                // Prevent cursor from jumping to end of input when typing - IMPORTANT!
                setTimeout(() => {
                    if (ref.current) ref.current.setSelectionRange(selectionStart, selectionStart);
                }, 0);
            }}
            readOnly={readOnly}
            placeholder={placeholder}
            onBlur={handleBlur}
        />
    );
};

export default Textarea;
