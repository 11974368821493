import { AnyAction }  from "redux";
import { ICinema }     from "@src/definitions/cinema";
import ACTIONS        from "./cinema-action-types";

export interface ICinemaState {
    data: ICinema[];
    isFetching: boolean;
}

const defaultState: ICinemaState = {
    data: [],
    isFetching: true,
};

/**
 * Reducer for logged-in user's cinemas
 */
const ICinemaReducer = (state: ICinemaState = defaultState, action: AnyAction) => {
    switch (action.type) {

        case ACTIONS.FETCH_INIT: {
            return {
                ...state,
                isFetching: true
            };
        }

        case ACTIONS.FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }

        default:
            return state;
    }
};

export default ICinemaReducer;
