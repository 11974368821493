import React from "react";
import { Link } from "react-router-dom";
import { checkIfIsActive, getCount } from "./navigation-item-utils";

import "./navigation-item.scss";

interface NavigationItemProps {
    id: string;
    title: string;
    path?: string;
    icon?: string;
    component?: string;
    currentPath: string;
    activeComponent: string;
    children?: NavigationItemProps[];
    wishListCount: number;
    cartCount: number;
    onClick?(e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
    toggleSidebarExpand(clickedItem: string): void;
}

/**
 * Component for navigation item
 */
const NavigationItem = (props: NavigationItemProps) => {
    const { icon, children, title, path, currentPath, activeComponent, component = "" } = props;
    const isActive = checkIfIsActive(path, component, currentPath, activeComponent);

    const count = getCount(component, {
        "wish-list": props.wishListCount,
        "cart": props.cartCount
    });

    const onClick = component
        ? () => props.toggleSidebarExpand(component)
        : props.onClick;

    const menuItemData = (
        <div className="NavigationItem__data">
            {icon && <i className={icon}></i>}
            {!!count && <span className="NavigationItem__count">{count}</span>}
            <div className="NavigationItem__title">
                {title}
            </div>
        </div>
    );

    const childrenElement = children && !!children.length && (
        <div className="NavigationItem__children">
            {children.map((child) =>
                <NavigationItem key={child.id}
                    {...child}
                />
            )}
        </div>
    );

    if (path) {
        return (
            <Link className={`NavigationItem ${isActive}`} to={path}>
                {menuItemData}
                {childrenElement}
            </Link>
        );
    }

    return (
        <a className={`NavigationItem ${isActive}`} onClick={onClick}>
            {menuItemData}
            {childrenElement}
        </a>
    );
};

export default NavigationItem;
