import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import i18next from "i18next";
import { Trans } from "react-i18next";
import Cookies from "js-cookie";

import { CompanyType } from "@src/definitions/company";
import { UserType } from "@src/definitions/user";
import Checkbox from "@src/components/form/input/Checkbox";

import "./consent-manager.scss";

interface Props {
    companyType: CompanyType;
    userType: UserType;
}

/**
 * Component for managing third party cookie consent
 */
const ConsentManager = (props: Props) => {
    const cookieName = "cinio_consent_cookie";
    const cookiePolicy = "https://cinio.document360.io/docs/cinio-cookie-policy";

    const [thirdPartyConsent, setThirdPartyConsent] = useState(false);
    const [didSave, setSaved] = useState(false);
    const [scriptsAreLoaded, toggleScriptsLoaded] = useState(false);
    const [gaDimensionsAreSet, toggleGaDimensionsSet] = useState(false);

    useEffect(() => {
        const savedPreference = Cookies.get(cookieName);

        if (savedPreference === "true") {
            loadScripts();
            setSaved(true);
        }
        if (savedPreference === "false") {
            setSaved(true);
        }
    }, []);

    // Update GA dimensions on company change
    useEffect(() => {
        if (props.companyType) {
            setGoogleAnalyticsDimensions();
        }
    }, [props.companyType]);

    // Store preferences in cookie and load scripts if user consents
    const save = (acceptAll = false) => {
        const consent      = thirdPartyConsent || acceptAll;
        const cookieValue  = consent ? "true" : "false";
        const THREE_MONTHS = 3 * 30 * 24 * 60 * 60 * 1000;

        // If user consents, set expiry date to 3 months.
        // If not, only store the cookie until the end of the browsing session
        const expires = consent ? new Date(Date.now() + THREE_MONTHS) : undefined;

        if (cookieValue === "true") {
            loadScripts();
        }

        Cookies.set(cookieName, cookieValue, { expires });
        setSaved(true);
    };

    // Load third party scripts
    const loadScripts = () => {
        toggleScriptsLoaded(true);

        loadClickyScript();

        ReactGA.initialize(GA_ID);
        setGoogleAnalyticsDimensions(true);
    };

    // Set GA dimensions
    const setGoogleAnalyticsDimensions = (scriptsJustLoaded = false) => {
        const isReady = scriptsAreLoaded || scriptsJustLoaded;

        if (gaDimensionsAreSet || !props.companyType || !isReady) {
            return;
        }

        toggleGaDimensionsSet(true);

        ReactGA.set({
            dimension1: props.companyType,
            dimension2: props.userType
        });

        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    // Load Clicky script
    const loadClickyScript = () => {
        // @ts-ignore - Set global site ids array
        window.clicky_site_ids = [CLICKY_SITE_ID];

        const script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", "//static.getclicky.com/js");
        script.async = true;

        document.getElementsByTagName("head")[0].appendChild(script);
    };

    if (didSave) {
        return null;
    }

    return (
        <div className="ConsentManager__container">
            <div className="ConsentManager">
                <p>
                    {i18next.t("cookies::We use cookies to offer you a better experience and analyze site traffic.")}
                </p>
                <p>
                    <Trans i18nKey="cookies::See cookie policy">
                        For more information, see our <a href={cookiePolicy} target="_blank">Cookie policy</a>.
                    </Trans>
                </p>
                <div className="ConsentManager__options">
                    <div className="ConsentManager__option">
                        <label htmlFor="essential">{i18next.t("cookies::Essential cookies (required)")}</label>
                        <Checkbox id="essential" value={true} />
                    </div>
                    <div className="ConsentManager__option">
                        <label htmlFor="third-party">{i18next.t("cookies::Other & Third Party Cookies")}</label>
                        <Checkbox id="third-party" value={thirdPartyConsent} onChange={setThirdPartyConsent} />
                    </div>
                </div>
                <div className="ConsentManager__buttons">
                    <button onClick={() => save()}>{i18next.t("glossary::Save")}</button>
                    <button className="is-primary" onClick={() => save(true)}>{i18next.t("cookies::Accept all")}</button>
                </div>
            </div>
        </div>
    );
};

export default ConsentManager;
