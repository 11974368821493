import React from "react";
import i18next from "i18next";
import { useFormContext } from "../FormContext";

import "./submit-button.scss";

interface Props {
    text?: string;
    className?: string;
}

const SubmitButton = (props: Props) => {
    const { onSubmit } = useFormContext();
    const { text, className = "" } = props;

    return (
        <button className={`SubmitButton ${className}`}
            onClick={onSubmit}>
            <i className="cinicon-save"></i>
            <span>{text || i18next.t("glossary::Submit")}</span>
        </button>
    );
};

SubmitButton.defaultProps = {
    isSubmitButton: true
};

export default SubmitButton;
