import React from "react";
import { Option } from "@definitions/form";

export interface RadioGroupProps {
    id?: string;
    value?: string;
    options?: Option[];
    onChange?(value: string): void;
}

/**
 * Radio group component
 */
const RadioGroup = (props: RadioGroupProps) => {
    const { id = "", value = "", options = [], onChange = () => {} } = props;

    return (
        <div className="RadioGroup">
            {options.map((option) => (
                <div className="RadioGroup__item"
                    onClick={() => onChange(option.value)}>
                    <input
                        id={option.value}
                        name={id}
                        type="radio"
                        checked={option.value === value}
                    />
                    <span/>
                    <label htmlFor={id}>{option.label}</label>
                </div>
            ))}
        </div>
    );
};

export default RadioGroup;
