/**
 * Action types for notification reducer
 */
export default {
    FETCH_INIT     : "NOTIFICATIONS/FETCH_INIT",
    FETCH_SUCCESS  : "NOTIFICATIONS/FETCH_SUCCESS",
    REMOVE_ONE     : "NOTIFICATIONS/REMOVE",
    TOGGLE_IS_SEEN : "NOTIFICATIONS/TOGGLE_IS_SEEN",
    ADD_ONE        : "NOTIFICATIONS/ADD_ONE"
};
