/**
 * Check if navigation item is active
 * @param path - navitem path
 * @param component - navitem component id
 * @param currentPath - active path
 * @param activeComponent - active component id
 */
export const checkIfIsActive = (path = "", component = "", currentPath = "", activeComponent = "") => {
    if (component && activeComponent) {
        return component === activeComponent ? "is-active" : "";
    }

    return path && currentPath.match(path) && !activeComponent ? "is-active" : "";
};

/**
 * Get count for a component
 * @param component - component id
 * @param counts - object with all counts
 */
export const getCount = (component: string, counts: GenericObject) => {
    return counts[component] || 0;
};
