import { AnyAction } from "redux";
import { ICompany } from "@definitions/company";
import ACTIONS from "./company-action-types";

import { ADDRESS_DEFAULT, MEDIA_ITEM_DEFAULT } from "@utils/defaults";

export interface CompanyState {
    data: ICompany;
    isFetching: boolean;
    error: string;
}

const defaultState: CompanyState = {
    data: {
        _id: "",
        name: "",
        logo: MEDIA_ITEM_DEFAULT,
        address: ADDRESS_DEFAULT,
        companyType: "",
        currency: "EUR",
        locale: "en",
        emails: [],
        payByInvoice: false,
        isBAC: false,
        phoneNumbers: [],
        deleted: false,
        isDemo: false,
        requestSettings: {
            requireAdminConfirmationOnAccept: true,
            requireAdminConfirmationOnDecline: true,
        }
    },
    isFetching: true,
    error: ""
};

/**
 * Reducer for logged-in user's company
 * @param state
 * @param action
 */
const companyReducer = (state: CompanyState = defaultState, action: AnyAction) => {
    switch (action.type) {

        case ACTIONS.FETCH_INIT: {
            return {
                ...state,
                isFetching: true,
                error: ""
            };
        }

        case ACTIONS.FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }

        case ACTIONS.FETCH_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }

        default:
            return state;
    }
};

export default companyReducer;
