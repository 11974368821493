import React from "react";
import { Editor } from "@tinymce/tinymce-react";

import "./rich-text.scss";

export interface RichTextEditorProps {
    id?: string;
    isFetching: boolean;
    value?: string;
    onChange?(value: string): void;
}

/**
 * Wrapper for rich text editor to make it re-render when done fetching
 */
const RichTextEditor = (props: RichTextEditorProps) => {
    return <RichTextEditorComponent key={props.isFetching ? "fetching" : "ready"} {...props} />;
};

/**
 * Rich text editor powered by TinyMCE
 */
const RichTextEditorComponent = (props: RichTextEditorProps) => {
    const onChange = (content: string) => {
        props.onChange && props.onChange(content);
    };

    return (
        <Editor
            id={props.id}
            disabled={props.isFetching}
            apiKey="ijpqnpni7vo3g3faac584t3jp1tmiel54m0k36c1jw22149j"
            initialValue={props.isFetching ? "Loading..." : props.value}
            init={{
                height: 500,
                menubar: true,
                content_css: "/main.css",
                body_class: "RichText",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount"
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help"
            }}
            onEditorChange={onChange}
        />
      );
};

export default RichTextEditor;
