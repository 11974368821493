import * as R from "ramda";
import qs from "qs";

import { isValidDate } from "./dates";

/**
 * Connect words with commas and "and"
 * @todo custom language support
 */
export const connectWords = (words: string[]): string => {
    const lastWords  = R.takeLast(2, words).join(" and ");
    const firstWords = words.slice(0, -2).join(", ");

    return firstWords
        ? firstWords + ", " + lastWords
        : lastWords;
};

/**
 * Capitalize first letter of string
 * @param str
 */
export const capitalize = (str: string) => {
    return str.substr(0, 1).toUpperCase() + str.substr(1);
};

/**
 * Get support link based on company type and locale
 * @param companyType - company type
 * @param locale - locale
 */
export const getSupportLink = () => {
    return `https://cinio.net`;
};

/**
 * Get contact link by company type and locale
 * @todo when all support pages are added, use different links
 */
export const getContactLink = () => {
    return "https://exhibitors.cinio.net/help/our-contant-information";
};

/**
 * Build query string from an object
 * @param obj - object to turn into
 * @param keyPrefix - key prefix (for nested objects)
 */
export const buildQueryString = (obj: GenericObject) => {
    return qs.stringify(obj, { encode: false, arrayFormat: "brackets" });
};

/**
 * Get object from query string
 * @param str - query string
 */
export const getObjectFromQueryString = (str: string): GenericObject => {
    const object = qs.parse(str, { ignoreQueryPrefix: true }) as GenericObject;

    // Convert stringified booleans to booleans
    return Object.keys(object).reduce((obj: GenericObject, key) => {
        const value = obj[key];

        if (value === "true" || value === "false") {
            return { ...obj, [key]: value === "true" };
        }
        // Filter out empty and NaN values
        if (!value || value === "NaN" || (typeof value === "number" && isNaN(value))) {
            delete obj[key];
            return obj;
        }
        // Convert string dates to date objects
        if (isNaN(Number(value)) && isValidDate(new Date(value))) {
            return { ...obj, [key]: new Date(value) };
        }

        return obj;
    }, object);
};
