import React from "react";
import i18next from "i18next";
import { useCountryOptions } from "@utils/hooks";
import FormField  from "../FormField";

interface Props {
    addressPath: string;
    readOnly?: boolean;
}

/**
 * Form fields for editing addresses
 */
const FormAddress = (props: Props) => {
    const { countryOptions } = useCountryOptions(true, { excludeParentRegions: true });
    const { addressPath, readOnly= false } = props;

    return (
        <div className="FormAddress">
            <FormField
                path={`${addressPath}.street1`}
                label={i18next.t("glossary::Street 1")}
                type="text"
                readOnly={readOnly}
            />
            <FormField
                path={`${addressPath}.street2`}
                label={i18next.t("glossary::Street 2")}
                type="text"
                readOnly={readOnly}
            />
            <FormField
                path={`${addressPath}.city`}
                label={i18next.t("glossary::City")}
                type="text"
                readOnly={readOnly}
            />
            <FormField
                path={`${addressPath}.region`}
                label={i18next.t("glossary::Region")}
                type="text"
                readOnly={readOnly}
            />
            <FormField
                path={`${addressPath}.country`}
                label={i18next.t("glossary::Country")}
                type="select"
                options={countryOptions}
                isSearchable={true}
                readOnly={readOnly}
            />
            <FormField
                path={`${addressPath}.zip`}
                label={i18next.t("glossary::ZIP code")}
                type="text"
                readOnly={readOnly}
            />
        </div>
    );
};

export default FormAddress;
