import * as R from "ramda";
import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { connect } from "react-redux";

import { AppState } from "@store/reducers";
import { updateCartItem, stopEditingCartItem } from "@store/cart/cart-actions";
import { addAlert } from "@src/store/app/app-actions";

import Select from "@components/form/input/Select";
import Datepicker from "@components/form/input/Datepicker";
import Checkbox from "@components/form/input/Checkbox";

import { CartItem, NewCartItem } from "@src/definitions/cart";
import { WishListItem } from "@src/definitions/wish-list";
import { getNextDay } from "@src/utils/dates";
import { useCartItemEditor } from "@src/utils/cart-utils";

import {
    getVenueOptions,
    getVersionOptions,
    getVenueType,
    getCartItemVersion,
    getBookingPeriodOptions,
    getFixedPeriodDurationOptions
} from "@src/app/films/film-utils";

import "./cart-item-editor.scss";

type Item = CartItem | WishListItem;

interface Props {
    cartItem: Item;
    cinemas: AppState["cinemas"]["data"];
    isInCart: boolean;
    autoUpdate?: boolean;
    updateItem: typeof updateCartItem;
    stopEditing?: typeof stopEditingCartItem;
    addAlert: typeof addAlert;
}

const CartItemEditor = (props: Props) => {
    const autoSave = (updatedItem: NewCartItem) => props.autoUpdate && saveChanges(updatedItem);
    const status = "status" in props.cartItem ? props.cartItem.status : "pending";
    const [lastSavedVersion, setLastSavedVersion] = useState<CartItem>({ ...props.cartItem, status });

    const {
        cartItem,
        cartItemIsValid,
        updateCartItem,
        fixedPeriodDuration,
        handleVersionIdChange,
        handleSelectedAuditoriumsChange,
        handleSelectedCinemasChange,
        handleStartDateChange,
        handleEndDateChange,
        handleFixedPeriodDurationChange,
        handleBookingPeriodTypeChange,
        toggleFilmdepotMaterials
    } = useCartItemEditor(lastSavedVersion, props.cinemas, autoSave);

    const { versionId, versions, bookingPeriodType, cinemaIds, auditoriumIds, filmType, liveDate } = cartItem;

    const _id                  = props.cartItem._id;
    const isFilm               = filmType === "film";
    const isLiveEvent          = filmType === "live-event";
    const liveStartDate        = liveDate?.startDate;
    const version              = getCartItemVersion(versions, versionId);
    const venueType            = getVenueType(version, bookingPeriodType);
    const venues               = venueType === "cinema" ? cinemaIds : auditoriumIds;
    const minDate              = filmType === "encore" && liveStartDate ? getNextDay(liveStartDate) : getNextDay();
    const filmdepotEnabled     = version?.hasFilmdepotMaterials || false;
    const fixedPeriodSelected  = cartItem.bookingPeriodType !== "day";
    const saveButtonText       = props.isInCart ? i18next.t("glossary::Save") : i18next.t("cart::Add to cart");
    const saveButtonDisabled   = cartItemIsValid ? "" : " is-disabled";
    const updatedVersion       = { _id: props.cartItem._id, ...cartItem };

    const versionOptions             = getVersionOptions(versions);
    const bookingPeriodOptions       = getBookingPeriodOptions(version);
    const fixedPeriodDurationOptions = getFixedPeriodDurationOptions(bookingPeriodType);
    const venueOptions               = getVenueOptions(props.cinemas, versions, versionId, bookingPeriodType);

    const handleVenueChange = venueType === "cinema" ? handleSelectedCinemasChange : handleSelectedAuditoriumsChange;
    const stopEditing       = () => props.stopEditing && "_id" in props.cartItem && props.stopEditing(props.cartItem._id);

    const saveChanges = async (updatedVersion: NewCartItem) => {
        try {
            await props.updateItem({ _id, ...updatedVersion });
            setLastSavedVersion({ _id, ...updatedVersion });
        } catch (e) {
            updateCartItem(lastSavedVersion, true);

            props.addAlert({
                type: "error",
                title: "Could not update cart item",
                message: e.message
            });
        }
    };

    // Listen to external changes to cart item, and overwrite this version if there are changes
    useEffect(() => {
        const localVersion = { ...props.cartItem, ...cartItem };
        const incomingVersion = { ...cartItem, ...props.cartItem };

        if (!R.equals(localVersion, incomingVersion)) {
            updateCartItem(incomingVersion, true);
            setLastSavedVersion(incomingVersion);
        }
    }, [props.cartItem]);

    return (
        <div className="CartItemEditor">
            {isFilm &&
                <Select
                    id="version"
                    onChange={handleVersionIdChange}
                    value={versionId}
                    options={versionOptions}
                />
            }
            {!!bookingPeriodOptions.length &&
                <Select
                    id="bookingPeriodType"
                    onChange={handleBookingPeriodTypeChange}
                    value={cartItem.bookingPeriodType}
                    options={bookingPeriodOptions}
                />
            }
            {fixedPeriodSelected &&
                <Select
                    id="fixedPeriodDuration"
                    onChange={handleFixedPeriodDurationChange}
                    value={String(fixedPeriodDuration)}
                    options={fixedPeriodDurationOptions}
                />
            }
            <Select
                id="venues"
                hasError={!venues.length}
                onChange={handleVenueChange}
                value={venues}
                options={venueOptions}
                isMulti={true}
            />
            {!isLiveEvent &&
                <Datepicker
                    id="startDate"
                    isRange={true}
                    startDate={cartItem.startDate}
                    endDate={cartItem.endDate}
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                    minDate={minDate}
                />
            }
            {filmdepotEnabled &&
                <div className="CartItemEditor__filmdepotOption">
                    <Checkbox
                        id="includeFilmdepotMaterials"
                        value={cartItem.includeFilmdepotMaterials}
                        onChange={toggleFilmdepotMaterials}
                    />
                    <label htmlFor="includeFilmdepotMaterials">
                        {i18next.t("films::Include Filmdepot promotion materials")}
                    </label>
                </div>
            }
            {!props.autoUpdate &&
                <div className="CartItemEditor__actions">
                    <button className={`CartItemEditor__save ${saveButtonDisabled}`}
                        onClick={() => saveChanges(updatedVersion)}>
                        {saveButtonText}
                    </button>
                    <button className="CartItemEditor__exit"
                        onClick={stopEditing}>
                        {i18next.t("glossary::Exit")}
                    </button>
                </div>
            }
        </div>
    );
};

export const mapStateToProps = (state: AppState) => ({
    cinemas: state.cinemas.data
});

export default connect(mapStateToProps, { addAlert })(CartItemEditor);
