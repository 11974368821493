import React from "react";
import LoginForm from "./LoginForm";
import PasswordRequestForm from "./PasswordRequestForm";
import logo from "@assets/images/cinio.png";
import IAlert from "@src/definitions/alert";

import "./login-screen.scss";
import Alert from "../alert/Alert";
import PasswordResetForm from "./PasswordResetForm";

interface Props {
    pathname: string;
    alerts: IAlert[];
}

/**
 * Login screen
 */
const LoginScreen = (props: Props) => {
    let Component: React.ComponentElement<any, any> = <LoginForm />;

    if (props.pathname.match("password-recovery")) {
        Component = <PasswordRequestForm />;
    }
    if (props.pathname.match("reset-password")) {
        Component = <PasswordResetForm />;
    }

    return (
        <div className="LoginScreen">
            <div className="LoginScreen__wrap-transparent" />
            <div className="LoginScreen__inner">
                <img src={logo} className="LoginScreen__logo" />
                {Component}
            </div>
            {!!props.alerts.length && <Alert />}
        </div>
    );
};

export default LoginScreen;
