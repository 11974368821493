import * as R from "ramda";
import { IMediaItem } from "@definitions/media";

/**
 * Check if a src belongs to a video
 * @todo update when other video types are supported
 * @param src 
 */
export const isVideoUrl = (src: string) => {
    return isYoutubeUrl(src);
}

/**
 * Get link to external webpage where video is hosted
 * @param src - video src
 */
export const getExternalLink = (src: string) => {
    if (isYoutubeUrl(src)) {
        const id = getYoutubeVideoId(src);
        return "https://youtube.com/watch?v=" + id;
    }

    return src || "";
};

/**
 * Get youtube video id
 * @param src - video src
 */
export const getYoutubeVideoId = (src: string) => {
    if (src.match("/embed/")) {
        const id = R.last(src.split("/embed/")) as string;
        return id.replace(/\?.*/g, "");
    }
    if (src.match("v=")) {
        const afterV = R.last(src.split("v=")) || "";
        return R.head(afterV.split("&")) || "" as string;
    }

    return "";
};

/**
 * Get embed url for YouTube video
 * @param src - video url
 */
export const getYoutubeEmbedUrl = (src: string) => {
    const id = getYoutubeVideoId(src);

    return id
        ? "https://www.youtube.com/embed/" + id
        : "";
}

/**
 * Get poster from media item
 * @todo vimeo videos
 * @param mediaItem - media item object
 */
export const getPoster = (mediaItem: IMediaItem) => {
    const { src } = mediaItem;

    if (isYoutubeUrl(src)) {
        const id = getYoutubeVideoId(src);
        return `https://img.youtube.com/vi/${id}/default.jpg`;
    }

    return "";
};

/**
 * Check if a video src is a youtube url
 * @param src 
 */
export const isYoutubeUrl = (src: string) => {
    return /(youtube.com|youtu.be)/.test(src);
};
